import { NgModule, Injector } from '@angular/core';
import { CommonModule } from '@angular/common';

@NgModule({
    declarations: [],
    imports: [CommonModule],
})
export class DecoratorInjectorModule {
    static injector: Injector;
    constructor(injector: Injector) {
        DecoratorInjectorModule.injector = injector;
    }
}
