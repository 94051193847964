import { User } from 'src/app/models/user.interface';
import { Tokens } from '../../../models/tokens.interface';

export interface AuthState {
    user: User | null;
    tokens: Tokens | null;
    pending: boolean;
    error: string | null;
    freshLogin: boolean;
}

export const initialAuthState: AuthState = {
    user: null,
    tokens: null,
    pending: false,
    error: null,
    freshLogin: false,
};
