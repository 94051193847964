import { createEntityAdapter, EntityState } from '@ngrx/entity';

//----------------------------------------------------------------------------------------------------------
export const mitreTacticsAdapter = createEntityAdapter<any>({ selectId: item => item.id, });
export interface MitreTacticsState extends EntityState<any> { isLoading: boolean; error: any; }
export const initialMitreTacticsState: MitreTacticsState = mitreTacticsAdapter.getInitialState({
    isLoading: false,
    error: null,
});

//----------------------------------------------------------------------------------------------------------
export const mitreTechniquesAdapter = createEntityAdapter<any>({ selectId: item => item.tactic_identifier });
export interface MitreTechniquesState extends EntityState<any> { isLoading: boolean; error: any; }
export const initialMitreTechniquesState: MitreTechniquesState = mitreTechniquesAdapter.getInitialState({
    isLoading: false,
    error: null,
});

//----------------------------------------------------------------------------------------------------------
export const mitreMitigationsAdapter = createEntityAdapter<any>({ selectId: item => item.technique_identifier });
export interface MitreMitigationsState extends EntityState<any> { isLoading: boolean; error: any; }
export const initialMitreMitigationsState: MitreMitigationsState = mitreMitigationsAdapter.getInitialState({
    isLoading: false,
    error: null,
});

//----------------------------------------------------------------------------------------------------------
export const mitreDatasourcesAdapter = createEntityAdapter<any>({ selectId: item => item.technique_identifier })
export interface MitreDatasourcesState extends EntityState<any> { isLoading: boolean; error: any; }
export const initialMitreDatasourcesState: MitreDatasourcesState = mitreDatasourcesAdapter.getInitialState({
    isLoading: false,
    error: null,
});

//----------------------------------------------------------------------------------------------------------
export const mitreGroupsAdapter = createEntityAdapter<any>({ selectId: item => item.technique_identifier })
export interface MitreGroupsState extends EntityState<any> { isLoading: boolean; error: any; }
export const initialMitreGroupsState: MitreGroupsState = mitreGroupsAdapter.getInitialState({
    isLoading: false,
    error: null,
});

export interface MitreState {
    tactics: MitreTacticsState;
    techniques: MitreTechniquesState;
    mitigations: MitreMitigationsState;
    datasources: MitreDatasourcesState;
    groups: MitreGroupsState;
}

export const initialMitreState: MitreState = {
    tactics: initialMitreTacticsState,
    techniques: initialMitreTechniquesState,
    mitigations: initialMitreMitigationsState,
    datasources: initialMitreDatasourcesState,
    groups: initialMitreGroupsState,
};
