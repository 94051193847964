import { AfterViewInit, Directive, ElementRef } from '@angular/core';

// Source: https://netbasal.com/autofocus-that-works-anytime-in-angular-apps-68cb89a3f057
// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: '[autofocus]' })
export class AutoFocusDirective implements AfterViewInit {
    constructor(private host: ElementRef) {}

    ngAfterViewInit() {
        this.host.nativeElement.focus();
    }
}
