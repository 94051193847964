import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, tap } from 'rxjs/operators';
import { AnalyzerService } from 'src/app/services/analyzer.service';
import { AnalyzerTypes, loadAnalyzerFailure, loadAnalyzerRequest, loadAnalyzerSuccess } from '../actions/analyzer.action';
import { AuthActionTypes } from '../actions/auth.actions';
import { StatusState } from '../state/status.state';

@Injectable()
export class AnalyzerEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly store: Store<StatusState>,
        private readonly analyzerService: AnalyzerService
    ) {}

    init$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AuthActionTypes.USER_AUTO_LOGGED_IN, AuthActionTypes.LOGIN_SUCCESS),
                tap(() => {
                    this.store.dispatch(loadAnalyzerRequest());
                })
            ),
        { dispatch: false }
    );

    analyzerRequest$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AnalyzerTypes.LOAD_ANALYZER_REQUEST),
            exhaustMap(() =>
                this.analyzerService.getAnalyzerList().pipe(
                    map(analyzers => loadAnalyzerSuccess({ analyzers })),
                    catchError(error => of(loadAnalyzerFailure({ error })))
                )
            )
        )
    );
}
