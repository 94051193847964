import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, tap } from 'rxjs/operators';
import { MitreAttackService } from 'src/app/services/mitre-attack.service';
import { AuthActionTypes } from '../actions/auth.actions';
import {
    MitreTypes,
    loadMitreDatasourcesFailure,
    loadMitreDatasourcesRequest,
    loadMitreDatasourcesSuccess,
    loadMitreGroupsFailure,
    loadMitreGroupsRequest,
    loadMitreGroupsSuccess,
    loadMitreMitigationsFailure,
    loadMitreMitigationsRequest,
    loadMitreMitigationsSuccess,
    loadMitreTacticsFailure,
    loadMitreTacticsRequest,
    loadMitreTacticsSuccess,
    loadMitreTechniquesFailure,
    loadMitreTechniquesRequest,
    loadMitreTechniquesSuccess
} from '../actions/mitre.action';
import { AppState } from '../state/app.state';

@Injectable()
export class MitreEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly store: Store<AppState>,
        private readonly mitreService: MitreAttackService
    ) { }

    init$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AuthActionTypes.USER_AUTO_LOGGED_IN, AuthActionTypes.LOGIN_SUCCESS),
                tap(() => {
                    this.store.dispatch(loadMitreTacticsRequest());
                    this.store.dispatch(loadMitreTechniquesRequest());
                    this.store.dispatch(loadMitreMitigationsRequest());
                    this.store.dispatch(loadMitreDatasourcesRequest());
                    this.store.dispatch(loadMitreGroupsRequest());
                })
            ),
        { dispatch: false }
    );

    mitreTacticsRequest$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MitreTypes.LOAD_MITRE_TACTICS_REQUEST),
            exhaustMap(() =>
                this.mitreService.getMitreTactics().pipe(
                    map(mitres => loadMitreTacticsSuccess({ mitres })),
                    catchError(error => of(loadMitreTacticsFailure({ error })))
                )
            )
        )
    );

    mitreTechniquesRequest$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MitreTypes.LOAD_MITRE_TECHNIQUES_REQUEST),
            exhaustMap(() =>
                this.mitreService.getMitreTechniques().pipe(
                    map(mitres => loadMitreTechniquesSuccess({ mitres })),
                    catchError(error => of(loadMitreTechniquesFailure({ error })))
                )
            )
        )
    );

    mitreMitigationsRequest$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MitreTypes.LOAD_MITRE_MITIGATIONS_REQUEST),
            exhaustMap(() =>
                this.mitreService.getMitigations().pipe(
                    map(mitigations => loadMitreMitigationsSuccess({ mitigations })),
                    catchError(error => of(loadMitreMitigationsFailure({ error })))
                )
            )
        )
    );

    mitreDatasourcesRequest$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MitreTypes.LOAD_MITRE_DATASOURCES_REQUEST),
            exhaustMap(() =>
                this.mitreService.getDatasources().pipe(
                    map(datasources => loadMitreDatasourcesSuccess({ datasources })),
                    catchError(error => of(loadMitreDatasourcesFailure({ error })))
                )
            )
        )
    );

    mitreGroupsRequest$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MitreTypes.LOAD_MITRE_GROUPS_REQUEST),
            exhaustMap(() =>
                this.mitreService.getGroups().pipe(
                    map(groups => loadMitreGroupsSuccess({ groups })),
                    catchError(error => of(loadMitreGroupsFailure({ error })))
                )
            )
        )
    );
}
