import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FilterScope } from 'src/app/models/filter.interface';
import { FilterType } from 'src/app/models/filter-type.enum';
import { activeFiltersAdapter, availableFiltersAdapter, FiltersState } from '../state/filters.state';

export const selectFiltersState = createFeatureSelector<FiltersState>('filters');

//#region Active Filter

export const selectActiveFiltersState = createSelector(selectFiltersState, state => state.activeFilters);

export const {
    selectIds: selectActiveFiltersIds,
    selectEntities: selectActiveFiltersEntities,
    selectAll: selectActiveFiltersAll,
    selectTotal: selectActiveFiltersTotal,
} = activeFiltersAdapter.getSelectors(selectActiveFiltersState);

export const selectFilterById = (id: string) => createSelector(selectActiveFiltersEntities, entities => entities[id]);

export const selectFilterByType = (type: FilterType) =>
    createSelector(selectActiveFiltersAll, entities => entities.filter(e => e.type === type));

export const selectFilterByScope = (scope: FilterScope) =>
    createSelector(selectActiveFiltersAll, entities => entities.filter(e => e.scope === scope));

export const selectFilterByField = (field: string) =>
    createSelector(selectActiveFiltersAll, entities => entities.filter(e => e.field === field));

export const selectFilterEditionId = createSelector(selectActiveFiltersState, state => state.editionId);

export const selectFilterInEdition = createSelector(selectActiveFiltersEntities, selectFilterEditionId, (entities, editionId) =>
    editionId ? entities[editionId] : undefined
);
//#endregion

//#region Available Filter

export const selectAvailableFiltersState = createSelector(selectFiltersState, state => state.availableFilters);

export const {
    selectIds: selectAvailableFiltersIds,
    selectEntities: selectAvailableFiltersEntities,
    selectAll: selectAvailableFiltersAll,
    selectTotal: selectAvailableFiltersTotal,
} = availableFiltersAdapter.getSelectors(selectAvailableFiltersState);

//#endregion
