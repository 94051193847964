import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { AssetType } from 'src/app/models/asset-type.interface';
import { CmdbSite } from 'src/app/models/cmdb.interface';
import { TreeGridCol } from 'src/app/models/tree-grid-col.interface';


export const cmdbSiteAdapter = createEntityAdapter<CmdbSite>({
    selectId: cmdbSite => cmdbSite.id,
});
export interface CmdbSiteState extends EntityState<CmdbSite> {
    isLoading?: boolean;
    error?: any;
}
export const initialCmdbSiteState: CmdbSiteState = cmdbSiteAdapter.getInitialState({
    isLoading: false,
    error: null,
});

export const assetsTypeAdapter = createEntityAdapter<AssetType>({
    selectId: assetType => assetType.id,
});
export interface AssetsTypeState extends EntityState<AssetType> {
    isLoading?: boolean;
    error?: any;
}
export const initialAssetsTypeState: AssetsTypeState = assetsTypeAdapter.getInitialState({
    isLoading: false,
    error: null,
});

export const assetsColAdapter: EntityAdapter<TreeGridCol> = createEntityAdapter<TreeGridCol>({
    selectId: item => item.field,
});
export const initialAssetsColState = assetsColAdapter.getInitialState();

export interface AssetsStoreState {
    sites: CmdbSiteState;
    types: AssetsTypeState;
    cols: EntityState<TreeGridCol>;
}

export const initialAssetsStoreState: AssetsStoreState = {
    sites: initialCmdbSiteState,
    types: initialAssetsTypeState,
    cols: initialAssetsColState,
};
