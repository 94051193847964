<div class="flex" *ngIf="users | async as usersList">
    <table class="w-full text-sm text-left table-auto">
        <thead class="text-xs uppercase bg-primary-500 text-onprimary">
            <th class="p-2"><h4 class="font-bold" i18n="@@user_list-name">Name</h4></th>
            <th class="p-2"><h4 class="font-bold" i18n="@@user_list-roles">Roles</h4></th>
            <th class="p-2"><h4 class="font-bold" i18n="@@user_list-actions">Actions</h4></th>
        </thead>
        <tbody>
            <tr *ngFor="let user of usersList">
                <td class="py-1">
                    {{ user.name }}
                </td>
                <td class="py-1">
                    <div [ngClass]="utils.getTagAspectFromRole(role)" *ngFor="let role of user.roles">
                        <h4>{{ role }}</h4>
                    </div>
                </td>
                <td *ngIf="!!currentUser" class="py-1 w-20">
                    <button class="btn no-border p-1 m-0" (click)="updateUser(user)" *ngIf="canUpdate(user)">
                        <div class="icon bg-highlight-500 pen small mr-1"></div>
                    </button>
                    <button class="btn btn-danger no-border p-1 m-0" (click)="deleteUser(user)" *ngIf="canDelete(user)">
                        <div class="icon delete bg-red-500 small"></div>
                    </button>
                </td>
            </tr>
        </tbody>
    </table>
</div>
