import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { BreadcrumbItem } from 'src/app/models/breadcrumb-item.interface';

export type BreadcrumbState = EntityState<BreadcrumbItem>;

export const breadcrumbAdapter = createEntityAdapter<BreadcrumbItem>({
    selectId: item => (item.id ? item.id : ''),
});

export const initialBreadcrumbState = breadcrumbAdapter.getInitialState();
