import { Component, Input, OnInit } from '@angular/core';
import { Dictionary } from '@ngrx/entity';
import { Analyzer } from 'src/app/models/analyzer.interface';
import { UtilsService } from 'src/app/shared/utils/utils.service';

@Component({
    selector: 'hypervision-multiple-analyzers-display',
    templateUrl: './multiple-analyzers-display.component.html',
    styleUrls: ['./multiple-analyzers-display.component.scss']
})
export class MultipleAnalyzersDisplayComponent {

    @Input() analyzersTree: Dictionary<Analyzer>;
    @Input() analyzers: number[];

    constructor(readonly utils: UtilsService) { }

}
