import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { User } from 'src/app/models/user.interface';

export const userAdapter = createEntityAdapter<User>({
    selectId: item => item.id,
});

export interface UserState extends EntityState<User> {
    isLoading: boolean;
    error: any;
}

export const initialUserState: UserState = userAdapter.getInitialState({
    isLoading: false,
    error: null,
});
