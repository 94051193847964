import {
    AfterViewChecked,
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    ViewChild,
} from '@angular/core';
import { AttributesMap } from 'ng-dynamic-component';
import { KnubeCell } from 'src/app/models/knube-cell.interface';

@Component({
    selector: 'hypervision-knube',
    templateUrl: './knube.component.html',
    styleUrls: ['./knube.component.scss'],
})
export class KnubeComponent implements AfterViewInit, AfterViewChecked {
    @ViewChild('carousel') carousel: ElementRef<HTMLDivElement>;
    @ViewChild('scene') scene: ElementRef<HTMLDivElement>;

    @Output() cellsChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() askForClose: EventEmitter<boolean> = new EventEmitter<boolean>();

    private cellsInternal: KnubeCell[] = [];
    @Input() set cells(v: KnubeCell[]) {
        this.cellsInternal = v;
        this.cellsChange.emit(true);
        this.needDisplayUpdate = true;
    }
    get cells() {
        return this.cellsInternal;
    }

    private isHorizontalInternal = true;
    @Input() set isHorizontal(v) {
        this.isHorizontalInternal = v;
        this.changeDirection();
    }
    get isHorizontal() {
        return this.isHorizontalInternal;
    }

    private heightInternal = '90%';
    @Input() set height(v) {
        this.heightInternal = v;
    }
    get height() {
        return this.heightInternal;
    }

    private widthInternal = '90%';
    @Input() set width(v) {
        this.widthInternal = v;
    }
    get width() {
        return this.widthInternal;
    }

    @Input() showTitle = false;

    attributes: AttributesMap = { class: 'flex-grow w-full flex relative overflow-hidden' };

    private selectedIndexInternal = 0;
    set selectedIndex(v: number) {
        if (!isNaN(v) && isFinite(v)) {
            let recalcV = v;
            if (recalcV < 0) {
                recalcV = this.nbCells - Math.abs(recalcV);
            }
            this.selectedIndexInternal = recalcV % this.nbCells;
        }
    }
    get selectedIndex() {
        return this.selectedIndexInternal;
    }
    private cellSize: number;
    private radius: number;
    private theta: number;
    private nbCells = 0;
    private needDisplayUpdate = true;
    private rotateFn: string;

    private sceneWidth = 0;
    private sceneHeight = 0;

    @Input() showCellsAfterInit = -1;

    constructor(private readonly cdRef: ChangeDetectorRef) { }

    previous() {
        this.selectedIndex -= 1;
        this.rotateCarousel();
    }

    next() {
        this.selectedIndex += 1;
        this.rotateCarousel();
    }

    showCell(idx: number) {
        this.selectedIndex = idx;
        this.rotateCarousel();
    }

    pushCell(cell: KnubeCell) {
        this.cells.push(cell);
        this.showCell(this.cells.length - 1);
        this.needDisplayUpdate = true;
    }

    removeCell(idx: number) {
        this.cells.splice(idx, 1);
        this.selectedIndex -= 1;
        this.needDisplayUpdate = true;
    }

    ngAfterViewInit(): void {
        this.sceneWidth = this.scene.nativeElement.offsetWidth;
        this.sceneHeight = this.scene.nativeElement.offsetHeight;

        this.changeDirection();
        this.changeCarousel();
    }

    ngAfterViewChecked() {
        if (this.scene.nativeElement.offsetWidth !== this.sceneWidth || this.scene.nativeElement.offsetHeight !== this.sceneHeight) {
            this.sceneHeight = this.scene.nativeElement.offsetHeight;
            this.sceneWidth = this.scene.nativeElement.offsetWidth;
            this.needDisplayUpdate = true;
        }
        if (this.needDisplayUpdate) {
            this.needDisplayUpdate = false;
            setTimeout(() => this.changeCarousel(), 50);
        }
    }

    getCurrentCell() {
        return this.selectedIndex % this.nbCells;
    }

    deleteCurrent() {
        if (this.nbCells > 3) {
            this.removeCell(this.getCurrentCell());
        }
    }

    private updateCellSize() {
        this.cellSize = this.isHorizontal ? this.carousel.nativeElement.offsetWidth : this.carousel.nativeElement.offsetHeight;
    }

    private changeCarousel() {
        const carouselCells = this.carousel.nativeElement.querySelectorAll<HTMLDivElement>('.carousel__cell');
        this.updateCellSize();
        this.nbCells = carouselCells.length > 0 ? carouselCells.length : 1;
        this.theta = 360 / this.nbCells;

        // Calcul du RADIUS (rayon en Z à partir du centre de ratation du kNube)
        this.radius = 0;
        if (this.nbCells === 1) {
            // Cas d'une seule face afin d'éviter une division par zéro dans le calcul du RADIUS
            this.radius = 0;
        } else if (this.nbCells === 2) {
            // Cas de deux faces afin d'éviter qu'elles soient collées
            this.radius = 1;
        } else {
            // Cas général
            this.radius = Math.round(this.cellSize / 2 / Math.tan(Math.PI / this.nbCells));
        }

        for (let i = 0; i < this.nbCells; i++) {
            const cell = carouselCells[i];
            const cellAngle = this.theta * i;
            cell.style.transform = this.rotateFn + '(' + cellAngle + 'deg) translateZ(' + this.radius + 'px)';
        }

        this.rotateCarousel();

        if (this.showCellsAfterInit !== -1) {
            this.showCell(this.showCellsAfterInit);
            this.showCellsAfterInit = -1;
            this.cdRef.detectChanges();
        }
    }

    private changeDirection() {
        this.rotateFn = this.isHorizontal ? 'rotateY' : 'rotateX';
        this.needDisplayUpdate = true;
    }

    private rotateCarousel() {
        const angle = this.theta * this.selectedIndex * -1;
        this.carousel.nativeElement.style.transform = 'translateZ(' + -this.radius + 'px) ' + this.rotateFn + '(' + angle + 'deg)';
    }

    close() {
        this.askForClose.emit(true);
    }

    getDynamicInputs(cell: KnubeCell) {
        return cell?.inputs || {};
    }

    getDynamicOutputs(cell: KnubeCell) {
        return cell?.outputs || {};
    }
}
