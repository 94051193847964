import { combineReducers, createReducer, on } from '@ngrx/store';
import {
    loadClassificationTreeFailure,
    loadClassificationTreeRequest,
    loadClassificationTreeSuccess,
    setMaxBoostAlert,
    setMaxBoostThreat,
    setMaxInitAlert,
    setMaxInitThreat,
} from '../actions/scoring.actions';
import {
    classificationTreeAdapter,
    initialClassificationTreeState,
    initialScoringMaxBoostAndInitState,
    initialScoringState,
} from '../state/scoring.state';


//#region Classification Tree
export const classificationTreeReducers = createReducer(
    initialClassificationTreeState,
    on(loadClassificationTreeRequest, state => ({ ...state, isLoading: true, error: null })),
    on(loadClassificationTreeSuccess, (state, { scoreClassTree }) =>
        classificationTreeAdapter.setAll(scoreClassTree, { ...state, isLoading: false, error: null })
    ),
    on(loadClassificationTreeFailure, (state, { error }) => ({ ...state, isLoading: false, error }))
);
//#endregion

//#region Max Boost and Init
export const maxBoostAndInitReducers = createReducer(
    initialScoringMaxBoostAndInitState,
    on(setMaxBoostThreat, (state, { newValue }) => ({ ...state, maxBoostThreat: newValue })),
    on(setMaxBoostAlert, (state, { newValue }) => ({ ...state, maxBoostAlert: newValue })),
    on(setMaxInitThreat, (state, { newValue }) => ({ ...state, maxInitThreat: newValue })),
    on(setMaxInitAlert, (state, { newValue }) => ({ ...state, maxInitAlert: newValue }))
);
//#endregion

export const scoringReducer = combineReducers(
    { maxBoostAndInit: maxBoostAndInitReducers, classificationTree: classificationTreeReducers },
    initialScoringState
);
