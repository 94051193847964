import { createReducer, on } from '@ngrx/store';
import { loadStatusFailure, loadStatusRequest, loadStatusSuccess } from '../actions/status.actions';
import { initialStatusState, statusAdapter } from '../state/status.state';

export const statusReducers = createReducer(
    initialStatusState,
    on(loadStatusRequest, state => ({ ...state, isLoading: true, error: null })),
    on(loadStatusSuccess, (state, { status }) => statusAdapter.setAll(status, { ...state, isLoading: false, error: null })),
    on(loadStatusFailure, (state, { error }) => ({ ...state, isLoading: false, error }))
);
