import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ClassificationTreeNode } from 'src/app/models/score-class-node.interface';
import { TreeNode } from 'src/app/models/treenode.interface';
import { classificationTreeAdapter, ScoringState } from '../state/scoring.state';

export const selectScoringState = createFeatureSelector<ScoringState>('scoring');

//#region Max Boost and Init
export const selectMaxBoostAndInit = createSelector(selectScoringState, state => state.maxBoostAndInit);

export const selectMaxBoostThreat = createSelector(selectMaxBoostAndInit, state => state.maxBoostThreat);
export const selectMaxBoostAlert = createSelector(selectMaxBoostAndInit, state => state.maxBoostAlert);
export const selectMaxInitThreat = createSelector(selectMaxBoostAndInit, state => state.maxInitThreat);
export const selectMaxInitAlert = createSelector(selectMaxBoostAndInit, state => state.maxInitAlert);
//#endregion

//#region Classification Tree
// Sélectionne le state de classificationTree
export const selectClassificationTreeState = createSelector(selectScoringState, state => state.classificationTree);

// Sélecteurs de l'adapteur classificationTree
export const {
    selectIds: selectClassificationTreeIds,
    selectEntities: selectClassificationTreeEntities,
    selectAll: selectClassificationTreeAll,
    selectTotal: selectClassificationTreeTotal,
} = classificationTreeAdapter.getSelectors(selectClassificationTreeState);

// Sélectionne un node de l'arbre via son ID => ClassificationTreeNode
export const selectClassificationTreeNodeById = (id: number) => createSelector(selectClassificationTreeEntities, entities => entities[id]);

//
export const selectClassificationTreeNodeForAttackScore = () =>
    createSelector(selectClassificationTreeAll, entities => {
        return entities.filter(e => e.level_type === 1 && e.threat_weight > 0).sort((a, b) => a.threat_weight - b.threat_weight);
    });

// Sélectionne un node de l'arbre via son name => ClassificationTreeNode
export const selectClassificationTreeNodeByNameAndType = (name: string, classType: number) =>
    createSelector(selectClassificationTreeEntities, entities => {
        let scoreClass: ClassificationTreeNode | undefined;

        Object.values(entities).forEach(e => {
            if (e?.level_name === name && e.level_type === classType) {
                scoreClass = e;
            }
        });
        return scoreClass;
    });

// Sélectionne un node de l'arbre via son name => ClassificationTreeNode
export const selectClassificationTreeNodeByType = (classType: number) =>
    createSelector(selectClassificationTreeEntities, entities => {
        let scoreClass: ClassificationTreeNode[] = [];

        Object.values(entities).forEach(e => {
            if (e?.level_type === classType) {
                scoreClass.push(e);
            }
        });
        return scoreClass;
    });

// Sélectionne le parent le plus haut en fonction du nom et du level_type
export const selectClassificationTreeTopParentByNameAndType = (name: string, classType: number) =>
    createSelector(selectClassificationTreeEntities, selectClassificationTreeNodeByNameAndType(name, classType), (entities, node) => {
        if (!node) return;
        while (node && node.parent_id) {
            node = entities[node.parent_id];
        }
        return node;
    });

// Sélectionne les enfants du node passé en paramètres => ClassificationTreeNode[]
export const selectClassificationTreeNodeChildrens = (node: ClassificationTreeNode) =>
    createSelector(selectClassificationTreeEntities, entities => (node.children ? node.children.map(id => entities[id]) : undefined));

// Sélectionne une structure d'arbre contenant tous les enfants du node passé en paramètre => TreeNode<ClassificationTreeNode>
export const selectClassificationTreeNodeTree = (node: ClassificationTreeNode) =>
    createSelector(selectClassificationTreeEntities, entities => {
        // On crée la racine du TreeNode
        const rootTree: TreeNode<ClassificationTreeNode> = {
            data: node,
            id: node.id,
            label: node.level_name,
            children: [],
        };
        // Si il n'y a pas d'enfants on renvoie la racine
        if (!node.children) return rootTree;

        // Helper permettant de "transformer" un id en TreeNode<ClassificationTreeNode>
        const idToTreeNode = (id: number): TreeNode<ClassificationTreeNode> | undefined => {
            const entity = entities[id];
            if (!entity) return undefined;
            const newNode: TreeNode<ClassificationTreeNode> = {
                data: entity,
                id: entity.id,
                label: entity.level_name,
            };
            return newNode;
        };

        const recurse = (pNode: TreeNode<ClassificationTreeNode>) => {
            // Si la node a des children
            if (pNode.data?.children) {
                // On transforme les children en TreeNode<ClassificationTreeNode> puis on met tout en array
                const nodeChildrens: Array<TreeNode<ClassificationTreeNode>> = pNode.data.children
                    .map(c => idToTreeNode(c))
                    .filter((v): v is TreeNode<ClassificationTreeNode> => v != undefined);
                // On remplit les children du node parent
                pNode.children = nodeChildrens;
                // On fait de la récursive sur tous les enfants
                pNode.children.forEach(c => recurse(c));
            }
        };
        recurse(rootTree);

        return rootTree;
    });

// Sélectionne les Ids des nodes root => number[]
export const selectClassificationTreeTopLevelNodesIds = createSelector(selectClassificationTreeState, state => state.topLevelNodesIds);

// Sélectionne les nodes root => ClassificationTreeNode[]
export const selectClassificationTreeTopLevelNodes = createSelector(
    selectClassificationTreeEntities,
    selectClassificationTreeTopLevelNodesIds,
    (entities, topLevelNodesIds) =>
        topLevelNodesIds ? topLevelNodesIds.map(id => entities[id]).filter((v): v is ClassificationTreeNode => v != undefined) : null
);

export const selectClassificationTreeIsLoading = createSelector(selectClassificationTreeState, state => state.isLoading);
export const selectClassificationTreeError = createSelector(selectClassificationTreeState, state => state.error);
//#endregion
