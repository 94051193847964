export enum TimeType {
    period = 'period',
    customPeriod = 'customPeriod',
    customRange = 'customRange',
}

export enum PeriodUnit {
    seconds = 'seconds',
    minutes = 'minutes',
    hours = 'hours',
    days = 'days',
    weeks = 'weeks',
    months = 'months',
    years = 'years',
}

export interface TimeSelection {
    timeType: TimeType;
    periodValue: number;
    periodUnit: PeriodUnit;
    minTime: string;
    maxTime: string;
}
