import { Component, Input } from '@angular/core';

@Component({
    selector: 'hypervision-status-display',
    templateUrl: './status-display.component.html',
    styleUrls: ['./status-display.component.scss']
})
export class StatusDisplayComponent {
    @Input() status: number;
}
