import { createReducer, on } from '@ngrx/store';
import { loadUserFailure, loadUserRequest, loadUserSuccess } from '../actions/user.actions';
import { userAdapter, initialUserState } from '../state/user.state';

export const userReducers = createReducer(
    initialUserState,
    on(loadUserRequest, state => ({ ...state, isLoading: true, error: null })),
    on(loadUserSuccess, (state, { user }) => userAdapter.setAll(user, { ...state, isLoading: false, error: null })),
    on(loadUserFailure, (state, { error }) => ({ ...state, isLoading: false, error }))
);
