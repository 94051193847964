import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AssetsStoreState, assetsColAdapter, assetsTypeAdapter, cmdbSiteAdapter } from '../state/assets.state';

export const selectAssetsStoreState = createFeatureSelector<AssetsStoreState>('assets');

export const selectCmdbSiteState = createSelector(selectAssetsStoreState, state => state.sites);
export const {
    selectIds: selectCmdbSiteIds,
    selectEntities: selectCmdbSiteEntities,
    selectAll: selectCmdbSiteAll,
} = cmdbSiteAdapter.getSelectors(selectCmdbSiteState);
export const selectCmdbSiteIsLoading = createSelector(selectCmdbSiteState, state => state.isLoading);
export const selectCmdbSiteError = createSelector(selectCmdbSiteState, state => state.error);

export const selectAssetsTypeState = createSelector(selectAssetsStoreState, state => state.types);
export const {
    selectIds: selectAssetsTypeIds,
    selectEntities: selectAssetsTypeEntities,
    selectAll: selectAssetsTypeAll,
} = assetsTypeAdapter.getSelectors(selectAssetsTypeState);
export const selectAssetsTypeById = (id: number) => createSelector(selectAssetsTypeEntities, entities => entities[id]);
export const selectAssetsTypeIsLoading = createSelector(selectAssetsTypeState, state => state.isLoading);
export const selectAssetsTypeError = createSelector(selectAssetsTypeState, state => state.error);

export const selectAssetsColState = createSelector(selectAssetsStoreState, state => state?.cols);
export const { selectAll: selectAllAssetsCols, selectEntities: selectEntitiesAssetsCol } =
    assetsColAdapter.getSelectors(selectAssetsColState);
