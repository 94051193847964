import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import { map, mergeMap, tap } from 'rxjs/operators';
import { APP_CONFIGURATION_DONE } from '../../../services/env-configuration.service';
import { THEMES, THEME_LUMINOSITY } from '../../utils/theme-list';
import { SettingsActionTypes } from '../actions/settings.actions';
import { selectTheme, selectThemeLuminosity } from '../selectors/settings.selectors';
import { AppState } from '../state/app.state';

@Injectable()
export class SettingsEffects {
    constructor(private readonly actions$: Actions, private readonly store: Store<AppState>) {}

    init$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(APP_CONFIGURATION_DONE),
                mergeMap(() => combineLatest([this.store.select(selectTheme), this.store.select(selectThemeLuminosity)])),
                tap(([theme, themeLuminosity]) => {
                    this.setGlobalVarsTheme(theme);
                    this.setGlobalVarsThemeLuminosity(themeLuminosity);
                })
            ),
        { dispatch: false }
    );

    setTheme$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(SettingsActionTypes.SET_THEME),
                map((action: Action & { newTheme: string }) => action.newTheme),
                tap(payload => {
                    this.setGlobalVarsTheme(payload);
                })
            ),
        { dispatch: false }
    );

    setThemeLuminosity$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(SettingsActionTypes.SET_THEME_LUMINOSITY),
                map((action: Action & { themeLuminosity: string }) => action.themeLuminosity),
                tap(payload => {
                    this.setGlobalVarsThemeLuminosity(payload);
                })
            ),
        { dispatch: false }
    );

    private setGlobalVar(key: string, value: string) {
        document.documentElement.style.setProperty(`--${key}`, value);
    }

    private setGlobalVarsTheme(themeName: string) {
        for (const key of Object.keys(THEMES[themeName])) {
            // eslint-disable-next-line 
            this.setGlobalVar(key, THEMES[themeName][key].replace(/,/g, ' '));
        }
    }

    private setGlobalVarsThemeLuminosity(themeLuminosity: string) {
        for (const key of Object.keys(THEME_LUMINOSITY[themeLuminosity])) {
            // eslint-disable-next-line
            this.setGlobalVar(key, THEME_LUMINOSITY[themeLuminosity][key].replace(/,/g, ' '));
        }

        this.setGlobalVar('none-criticity', THEME_LUMINOSITY[themeLuminosity].none.replace(/,/g, ' '));
        this.setGlobalVar('low-criticity', THEME_LUMINOSITY[themeLuminosity].low.replace(/,/g, ' '));
        this.setGlobalVar('medium-criticity', THEME_LUMINOSITY[themeLuminosity].medium.replace(/,/g, ' '));
        this.setGlobalVar('high-criticity', THEME_LUMINOSITY[themeLuminosity].high.replace(/,/g, ' '));
        this.setGlobalVar('critical-criticity', THEME_LUMINOSITY[themeLuminosity].critical.replace(/,/g, ' '));
    }
}
