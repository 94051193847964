<div class="flex-auto flex flex-col p-1 bg-surface text-onsurface">
    <form class="flex-auto flex flex-col justify-center" autocomplete="off">
        <div class="flex flex-col pb-3">
            <h6 class="font-bold">Username</h6>
            <input
                class="block p-1 w-full text-sm text-onsurface bg-surface rounded-lg border border-primary-500"
                type="text"
                autocomplete="false"
                name="username"
                [(ngModel)]="userEditUsername"
            />
        </div>
        <div class="flex flex-col pb-3">
            <h6 class="font-bold">Password</h6>
            <input
                class="block p-1 w-full text-sm text-onsurface bg-surface rounded-lg border border-primary-500"
                type="password"
                autocomplete="new-password"
                name="newpassword"
                [(ngModel)]="userEditPassword"
            />
        </div>
        <div class="flex flex-col">
            <h6 class="font-bold">Role</h6>
            <div class="flex flex-col br-2 p-1 b-title">
                <div *ngFor="let role of availableRoles" class="d-inline">
                    <label class="flex items-center">
                        <input
                            type="checkbox"
                            class="w-4 h-4 appearance-none grid place-content-center bg-background rounded checked:bg-highlight-500 mr-2"
                            [disabled]="disableItem(role)"
                            [checked]="userEditRoles.includes(role)"
                            (change)="updateUserEditRole(role)"
                        />
                        <h6>{{ role }}</h6>
                    </label>
                </div>
            </div>
        </div>
        <div class="flex justify-end">
            <button
                class="p-1 rounded bg-secondary-500 hover:bg-secondary-600 text-onsecondary disabled:opacity-80 disabled:cursor-not-allowed mx-1"
                (click)="updateUser()"
                type="submit"
            >
                Validate
            </button>
            <button
                class="p-1 text-white bg-red-500 hover:bg-red-600 disabled:opacity-80 disabled:cursor-not-allowed rounded mx-1"
                (click)="closePopup()"
                type="reset"
            >
                Cancel
            </button>
        </div>
    </form>
</div>
