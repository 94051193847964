import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, tap } from 'rxjs/operators';
import { DictionaryService } from 'src/app/services/dictionary.service';
import { AuthActionTypes } from '../actions/auth.actions';
import {
    DictionaryActionsType,
    loadFieldsNameDescriptionI18nFailure,
    loadFieldsNameDescriptionI18nRequest,
    loadFieldsNameDescriptionI18nSuccess,
    loadNdpiL7DictionaryFailure,
    loadNdpiL7DictionaryRequest,
    loadNdpiL7DictionarySuccess,
} from '../actions/dictionary.actions';
import { DictionaryState } from '../state/dictionary.state';

@Injectable()
export class DictionaryEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly store: Store<DictionaryState>,
        private readonly dicoService: DictionaryService
    ) { }

    init$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AuthActionTypes.USER_AUTO_LOGGED_IN, AuthActionTypes.LOGIN_SUCCESS),
                tap(() => {
                    this.store.dispatch(loadFieldsNameDescriptionI18nRequest());
                    this.store.dispatch(loadNdpiL7DictionaryRequest());
                })
            ),
        { dispatch: false }
    );

    fieldsNameDictionaryRequest$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DictionaryActionsType.LOAD_FIELDS_DICO_REQUEST),
            exhaustMap(() =>
                this.dicoService.getFeatureImportanceDico().pipe(
                    map(fields_name => loadFieldsNameDescriptionI18nSuccess({ fields_name })),
                    catchError(error => of(loadFieldsNameDescriptionI18nFailure({ error })))
                )
            )
        )
    );

    ndpiL7DictionaryRequest$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DictionaryActionsType.LOAD_NDPIL7_DICO_REQUEST),
            exhaustMap(() =>
                this.dicoService.getNdpiL7Dico().pipe(
                    map(ndpi_l7 => loadNdpiL7DictionarySuccess({ ndpi_l7 })),
                    catchError(error => of(loadNdpiL7DictionaryFailure({ error })))
                )
            )
        )
    );
}
