import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, ViewChild } from '@angular/core';
import {
    AxisModel,
    ChartComponent,
    LegendService,
    LegendSettingsModel,
    LineSeriesService,
    LogarithmicService,
    MarkerSettingsModel,
    PolarSeriesService,
    RadarSeriesService,
    SplineSeriesService,
    TooltipService,
    ZoomService,
    ZoomSettingsModel,
} from '@syncfusion/ej2-angular-charts';

@Component({
    selector: 'hypervision-radar-chart',
    templateUrl: './radar-chart.component.html',
    styleUrls: ['./radar-chart.component.scss'],
    providers: [
        PolarSeriesService,
        RadarSeriesService,
        LineSeriesService,
        SplineSeriesService,
        TooltipService,
        LogarithmicService,
        ZoomService,
        LegendService,
    ],
})
export class RadarChartComponent implements AfterViewInit {
    @ViewChild('radarChart') chart: ChartComponent;
    @ViewChild('radarContainer') container: ElementRef;

    @Input() id: string;
    @Input() title: string = 'Test';
    @Input() minY: number;
    @Input() maxY: number;
    zoomSettings: ZoomSettingsModel = {
        mode: 'Y',
        enableMouseWheelZooming: true,
        enablePinchZooming: true,
        enableSelectionZooming: true,
        enableScrollbar: true,
    };
    legendSettings: LegendSettingsModel = {
        visible: true,
    };
    primaryYAxis: AxisModel = {};
    data: any[] = [];
    marker: MarkerSettingsModel = {
        visible: true,
        height: 10,
        width: 10,
        shape: 'Circle',
        fill: 'transparent',
        border: {
            width: 2,
            color: 'rgba(255, 0, 0, 1)',
        },
    };
    primaryXAxis: AxisModel = {};
    chartHeight: string = '200px';
    chartWidth: string = '200px';
    serieColor: string = 'rgba(var(--primary500))';
    tooltipSettings = { enable: false, header: '<b>${point.x} : ${point.y}</b>', format: '${series.name} ${point.x} : ${point.y}' };

    private valuesInternal: { series: any; dico: any };
    @Input() set values(v: { series: any; dico: any }) {
        this.valuesInternal = v;
        if (!!v && !!v.series && v.series.length > 0) {
            this.loadData();
        }
    }
    get values(): { series: any; dico: any } {
        return this.valuesInternal;
    }

    constructor(private readonly ref: ChangeDetectorRef) {}

    onResized(event: ResizeObserverEntry) {
        if (!!this.chart) {
            this.chartHeight = event.contentRect.height * 0.8 + 'px';
            this.chartWidth = event.contentRect.width + 'px';
        }
    }
    ngAfterViewInit() {
        this.loadData();

        this.chartHeight = this.container?.nativeElement.offsetHeight * 0.8 + 'px';
        this.chartWidth = this.container?.nativeElement.offsetWidth + 'px';
        this.ref.detectChanges();
    }

    private async loadData() {
        if (!this.values) return;

        const computedSeries: any[] = [];
        this.values.series.forEach(serie => {
            const computedValues: any[] = [];
            serie.data.forEach(c => computedValues.push({ x: c.name, y: c.value, label: this.values.dico[c.name]?.label }));
            computedSeries.push({
                data: computedValues,
                serieColor: serie.serieColor,
                marker: {
                    visible: true,
                    height: 10,
                    width: 10,
                    shape: serie.markerShape,
                    fill: 'transparent',
                    border: {
                        width: 2,
                        color: serie.markerColor,
                    },
                },
            });
        });

        this.primaryYAxis = {
            title: 'Value',
            valueType: 'Logarithmic',
            labelFormat: 'n2',
            labelStyle: {
                color: 'rgb(var(--onbackground))',
                size: '0px',
            },
            lineStyle: {
                color: 'rgb(var(--none-criticity))',
            },
            majorGridLines: {
                color: 'rgba(var(--onbackground) / 0.25)',
                width: 1,
            },
            minorGridLines: {
                color: 'red',
                width: 0,
            },
        };

        this.primaryXAxis = {
            title: 'Features',
            valueType: 'Category',
            labelPlacement: 'OnTicks',
            labelStyle: {
                color: 'rgb(var(--onbackground))',
            },
            lineStyle: {
                color: 'rgb(var(--none-criticity))',
            },
            majorGridLines: {
                color: 'rgba(var(--onbackground) / 0.25)',
                width: 1,
            },
            minorGridLines: {
                color: 'red',
                width: 0,
            },
            interval: 1,
        };

        this.marker = {
            visible: true,
            height: 10,
            width: 10,
            shape: 'Circle',
            fill: 'transparent',
            border: {
                width: 2,
                color: 'rgba(var(--secondary500))',
            },
        };
        this.data = computedSeries;
        this.ref.detectChanges();
    }
}
