import { createSelector, createFeatureSelector } from '@ngrx/store';
import { objectEventAnalyzerDescriptionAdapter, ObjectEventAnalyzerDescriptionState } from '../state/object-event-analyzer-description.state';

export const ObjectEventAnalyzerDescriptionStateState = createFeatureSelector<ObjectEventAnalyzerDescriptionState>('objectEventAnalyzerDescription');

export const {
    selectIds: selectObjectEventAnalyzerDescriptionStateIds,
    selectEntities: selectObjectEventAnalyzerDescriptionStateEntities,
    selectAll: selectObjectEventAnalyzerDescriptionStateAll,
} = objectEventAnalyzerDescriptionAdapter.getSelectors(ObjectEventAnalyzerDescriptionStateState);

export const selectObjectEventAnalyzerDescriptionStateById = (id: number) => createSelector(selectObjectEventAnalyzerDescriptionStateEntities, entities => entities[id]);

export const ObjectEventAnalyzerDescriptionStateIsLoading = createSelector(ObjectEventAnalyzerDescriptionStateState, state => state.isLoading);
export const ObjectEventAnalyzerDescriptionStateError = createSelector(ObjectEventAnalyzerDescriptionStateState, state => state.error);
