import { createAction, props } from '@ngrx/store';
import { Criticity } from '../../../models/criticity.interface';
import { TimeSelection } from '../../../models/time-selection.interface';

export enum SettingsActionTypes {
    SET_CRITICITY = '[Settings] Set Criticity',
    SET_THEME = '[Settings] Set Theme',
    TOGGLE_DYNAMIC_THEME = '[Settings] Toggle Dynamic Theme',
    SET_DYNAMIC_THEME = '[Settings] Set Dynamic Theme',
    SET_THEME_LUMINOSITY = '[Settings] Set Theme Luminosity',
    SET_DEFAULT_TIME_SELECTION = '[Settings] Set Default Time Selection',
    SET_NAVBAR_EXTENDED = '[Settings] Set Navbar Extended',
    SET_DEFAULT_EMAIL = '[Settings] Set Default Email',
}

export const setCriticity = createAction(SettingsActionTypes.SET_CRITICITY, props<{ newCriticity: Criticity }>());

export const setTheme = createAction(SettingsActionTypes.SET_THEME, props<{ newTheme: string }>());

export const toggleDynamicTheme = createAction(SettingsActionTypes.TOGGLE_DYNAMIC_THEME);

export const setDynamicTheme = createAction(SettingsActionTypes.SET_DYNAMIC_THEME, props<{ dynamicTheme: boolean }>());

export const setThemeLuminosity = createAction(SettingsActionTypes.SET_THEME_LUMINOSITY, props<{ themeLuminosity: string }>());

export const setDefaultTimeSelection = createAction(
    SettingsActionTypes.SET_DEFAULT_TIME_SELECTION,
    props<{ timeSelection: TimeSelection }>()
);

export const setNavbarExtended = createAction(SettingsActionTypes.SET_NAVBAR_EXTENDED, props<{ newExtendedState: boolean }>());

export const setDefaultEmail = createAction(SettingsActionTypes.SET_DEFAULT_EMAIL, props<{ newDefaultEmail: string }>());
