import { Component, Input } from '@angular/core';
import { UtilsService } from 'src/app/shared/utils/utils.service';

@Component({
    selector: 'hypervision-score-display',
    templateUrl: './score-display.component.html',
    styleUrls: ['./score-display.component.scss']
})
export class ScoreDisplayComponent {
    @Input() score: number;
    @Input() customTitle: string;
    constructor(readonly utils: UtilsService) { }
}
