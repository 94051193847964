import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AttackReason } from 'src/app/models/attack-reason.interface';
import { AttackReasonState, attackReasonAdapter } from '../state/attack-reason.state';

export const selectAttackReasonState = createFeatureSelector<AttackReasonState>('attackReason');

export const {
    selectIds: selectAttackReasonIds,
    selectEntities: selectAttackReasonEntities,
    selectAll: selectAttackReasonAll,
    selectTotal: selectAttackReasonTotal,
} = attackReasonAdapter.getSelectors(selectAttackReasonState);

export const selectAttackReasonById = (id: number) =>
    createSelector(selectAttackReasonAll, (allAttackReason: AttackReason[]) => {
        if (allAttackReason) {
            return allAttackReason.find(c => c.id === id);
        } else {
            return undefined;
        }
    });
