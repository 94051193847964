import { combineReducers, createReducer, on } from '@ngrx/store';
import {
    loadFieldsNameDescriptionI18nFailure,
    loadFieldsNameDescriptionI18nRequest,
    loadFieldsNameDescriptionI18nSuccess,
    loadNdpiL7DictionaryFailure,
    loadNdpiL7DictionaryRequest,
    loadNdpiL7DictionarySuccess,
} from '../actions/dictionary.actions';
import {
    fieldsNameDescriptionI18nAdapter,
    initialDictionaryState,
    initialFieldsNameDescriptionI18nState,
    initialNdpiL7DictionaryState,
    ndpiL7DictionaryAdapter,
} from '../state/dictionary.state';


//#region Feature Importance
export const fieldsNameDescriptionI18nReducers = createReducer(
    initialFieldsNameDescriptionI18nState,
    on(loadFieldsNameDescriptionI18nRequest, state => ({ ...state, isLoading: true, error: null })),
    on(loadFieldsNameDescriptionI18nSuccess, (state, { fields_name }) =>
        fieldsNameDescriptionI18nAdapter.setAll(fields_name, { ...state, isLoading: false, error: null })
    ),
    on(loadFieldsNameDescriptionI18nFailure, (state, { error }) => ({ ...state, isLoading: false, error }))
);
//#endregion

//#region Feature Importance
export const ndpiL7Reducers = createReducer(
    initialNdpiL7DictionaryState,
    on(loadNdpiL7DictionaryRequest, state => ({ ...state, isLoading: true, error: null })),
    on(loadNdpiL7DictionarySuccess, (state, { ndpi_l7 }) =>
        ndpiL7DictionaryAdapter.setAll(ndpi_l7, { ...state, isLoading: false, error: null })
    ),
    on(loadNdpiL7DictionaryFailure, (state, { error }) => ({ ...state, isLoading: false, error }))
);
//#endregion

export const dictionaryReducer = combineReducers(
    {
        fields_name: fieldsNameDescriptionI18nReducers,
        ndpi_l7: ndpiL7Reducers,
    },
    initialDictionaryState
);
