import { Injectable, LOCALE_ID, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment.prod';
import { map } from 'rxjs/operators';
import { ApiResponse } from '../models/api-response.interface';
import { Status } from '../models/status.interface';

@Injectable({
    providedIn: 'root',
})
export class StatusService {
    constructor(@Inject(LOCALE_ID) protected localeId: string, private readonly http: HttpClient) {}

    loadStatusList() {
        return this.http
            .get<ApiResponse<Status[]>>(environment.bckAPI.threatAPIs.statusUrl + '?lang=' + this.localeId)
            .pipe(map(v => v.result));
    }
}
