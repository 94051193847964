import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { cloneDeep } from 'lodash';
import { tap, withLatestFrom } from 'rxjs/operators';
import { selectTotalHistoryList } from 'src/app/shared/store/selectors/history-list.selectors';
import { deleteLastHistoryEntry, editCurrentHistoryEntry, HistoryListActionType } from '../actions/history-list.action';
import { AppState } from '../state/app.state';

// Il faut penser qu'il y a l'entrée courante qui est entrain d'être écrite dans le compte
// Exemple: Pour avoir une list de 10 éléménts = 10 éléments max affiché + 1 pour l'entrée courante => 11
const MAX_HISTORY_ENTRIES = 11;

@Injectable()
export class HistoryListEffects {
    constructor(private readonly actions$: Actions, private readonly store: Store<AppState>) {}

    saveStateInCurrentEntry$ = createEffect(
        () =>
            this.actions$.pipe(
                withLatestFrom(this.store.select(state => state)),
                tap(([action, state]) => {
                    if (!action.type.startsWith('[History]')) {
                        const stateCopy = cloneDeep(state);
                        delete stateCopy.historyList;
                        delete stateCopy.router;
                        delete stateCopy.auth;
                        this.store.dispatch(editCurrentHistoryEntry({ editedEntry: { state: JSON.stringify(stateCopy) } }));
                    }
                })
            ),
        { dispatch: false }
    );

    historyLimitSize = createEffect(
        () =>
            this.actions$.pipe(
                ofType(HistoryListActionType.ADD_HISTORY_ENTRY),
                withLatestFrom(this.store.select(selectTotalHistoryList)),
                tap(([action, nbEntries]) => {
                    if (nbEntries > MAX_HISTORY_ENTRIES) {
                        this.store.dispatch(deleteLastHistoryEntry());
                    }
                })
            ),
        { dispatch: false }
    );
}
