import { Component, Input } from '@angular/core';
import { Dictionary } from '@ngrx/entity';
import { AssetsIps } from 'src/app/models/cmdb.interface';

@Component({
    selector: 'hypervision-ip-table-display',
    templateUrl: './ip-table-display.component.html',
    styleUrls: ['./ip-table-display.component.scss']
})
export class IpTableDisplayComponent {
    @Input() ip: string;
    @Input() assetsIps: Dictionary<AssetsIps>;
    @Input() isBlocked: boolean;
}
