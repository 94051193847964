import { Component, Input } from '@angular/core';
import { take } from 'rxjs';
import { CmdbService } from 'src/app/services/cmdb.service';
import { ToastMessageService } from 'src/app/services/toast-message.service';
import { UtilsService } from '../../../utils/utils.service';

@Component({
    selector: 'hypervision-is-private-ip-table-display',
    templateUrl: './is-private-ip-table-display.component.html',
    styleUrls: ['./is-private-ip-table-display.component.scss']
})
export class IsPrivateIpTableDisplayComponent {
    @Input() ip: string;
    @Input() ipIsPrivate: boolean;

    constructor(
        private readonly cmdbService: CmdbService,
        private readonly utils: UtilsService,
        private readonly toastMessageService: ToastMessageService) {

    }

    goToAssetDashboard(ip) {
        this.cmdbService.getCmdbInfoFromIP(ip).pipe(take(1)).subscribe(asset => {
            if (!!asset && !!asset.id) {
                this.utils.navigateWithBack(['/assets/' + asset.id]);
            } else {
                this.toastMessageService.showErrorMessage("Asset error", "No asset with this IP");
            }
        })
    }
}
