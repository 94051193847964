import { createFeatureSelector } from '@ngrx/store';
import { CountryState, countryAdapter } from '../state/country.state';

export const selectCountryState = createFeatureSelector<CountryState>('country');

export const {
    selectIds: selectCountryIds,
    selectEntities: selectCountryEntities,
    selectAll: selectCountryAll,
    selectTotal: selectCountryTotal,
} = countryAdapter.getSelectors(selectCountryState);
