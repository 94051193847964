import { createAction, props } from '@ngrx/store';

export enum CountryTypes {
    LOAD_COUNTRY_REQUEST = '[Country Api] Load Request',
    LOAD_COUNTRY_SUCCESS = '[Country Api] Load Success',
    LOAD_COUNTRY_FAILURE = '[Country Api] Load Failure',
}

export const loadCountryRequest = createAction(CountryTypes.LOAD_COUNTRY_REQUEST);
export const loadCountrySuccess = createAction(CountryTypes.LOAD_COUNTRY_SUCCESS, props<{ countries: any[] }>());
export const loadCountryFailure = createAction(CountryTypes.LOAD_COUNTRY_FAILURE, props<{ error: any }>());
