import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, ViewChild } from '@angular/core';
import {
    CategoryService,
    BarSeriesService,
    ColumnSeriesService,
    ChartAnnotationService,
    StripLineService,
    ChartComponent,
    AxisModel,
    ChartAnnotationSettingsModel,
    ZoomService,
    TooltipSettingsModel,
    ITooltipRenderEventArgs,
} from '@syncfusion/ej2-angular-charts';

@Component({
    selector: 'hypervision-bar-chart',
    templateUrl: './bar-chart.component.html',
    styleUrls: ['./bar-chart.component.scss'],
    providers: [CategoryService, BarSeriesService, ColumnSeriesService, ChartAnnotationService, StripLineService, ZoomService],
})
export class BarChartComponent implements AfterViewInit {
    @ViewChild('barChart') chartObj: ChartComponent;
    @ViewChild('barChartContainer') container: ElementRef;

    @Input() id: string;

    data: object[] = [];

    //Initializing Primary X Axis
    primaryXAxis: AxisModel = {
        valueType: 'DateTime',
        intervalType: 'Seconds',
        majorGridLines: { width: 0 },
        visible: false,
    };
    //Initializing Primary Y Axis
    primaryYAxis: Object = {
        majorGridLines: { width: 0 },
        majorTickLines: { width: 0 },
        lineStyle: { width: 0 },
        labelStyle: { color: 'transparent' },
        stripLines: [
            {
                start: 0,
                size: 1,
                sizeType: 'Pixel',
            },
        ],
    };
    title: string = '';
    tooltip: TooltipSettingsModel = {
        enable: true,
        template: '<div id="tooltipContainer"></div>',
    };
    // custom code end
    chartArea: Object = {
        border: {
            width: 0,
        },
    };
    zoomSettings: Object = {
        mode: 'X',
        enableMouseWheelZooming: true,
        enablePinchZooming: true,
        enableSelectionZooming: true,
        enableScrollbar: true,
    };

    chartHeight: string = '200px';
    chartWidth: string = '200px';

    @Input() set chartData(v: any[]) {
        this.data = v;
    }

    @Input() annotations: ChartAnnotationSettingsModel[] = [];

    constructor(private readonly ref: ChangeDetectorRef) {}

    ngAfterViewInit(): void {
        this.chartHeight = this.container?.nativeElement.offsetHeight * 0.8 + 'px';
        this.chartWidth = this.container?.nativeElement.offsetWidth * 0.9 + 'px';
        this.ref.detectChanges();
    }

    tooltipRender(args: ITooltipRenderEventArgs): void {
        if (!!args && !!args.data) {
            const point = args.series.dataSource[args.data.pointIndex];
            if (!!point) {
                let label = '';
                let value = 0;
                let bgColor = '';
                let bgColor25 = '';
                let borderColor = '';
                let textColor = '';
                let icon = '';
                let subtext = '';
                if (point.high > 0) {
                    label = 'Forward packets';
                    bgColor = 'bg-low';
                    bgColor25 = 'bg-low/10';
                    textColor = 'text-low';
                    borderColor = 'border-low';
                    value = point.high;
                    icon = 'fast-forward';
                    subtext = ' send';
                }
                if (point.low < 0) {
                    label = 'Backward packets';
                    bgColor = 'bg-critical';
                    bgColor25 = 'bg-critical/10';
                    textColor = 'text-critical';
                    borderColor = 'border-critical';
                    value = Math.abs(point.low);
                    icon = 'fast-backward';
                    subtext = ' received';
                }
                args.template = `
                <div class="flex flex-col ${bgColor25} rounded border-2 ${borderColor}">
                    <div class="px-2 uppercase text-sm ${textColor}">
                        ${label}
                    </div>
                    <div class="flex items-center font-bold">
                        <div class="icon ${icon} ${bgColor} medium mx-2"></div>
                        <span class="px-1">Packet size ${value}</span>
                    </div>
                </div>`;
            }
        }
    }
}
