import { Component, Input, OnInit } from '@angular/core';
import { Dictionary } from '@ngrx/entity';
import { MitreObject } from 'src/app/models/mitre.object.interface';

@Component({
    selector: 'hypervision-mitre-tactic-display',
    templateUrl: './mitre-tactic-display.component.html',
    styleUrls: ['./mitre-tactic-display.component.scss']
})
export class MitreTacticDisplayComponent {

    @Input() tacticId: string;
    @Input() tacticsTree: Dictionary<MitreObject>;

}
