import { ThemeLuminosity } from 'src/app/models/theme-luminosity.interface';
import { Theme } from '../../models/theme.interface';

export const THEME_LUMINOSITY: { [key: string]: ThemeLuminosity } = {
    dark: {
        //#region Surface
        surface: '35, 35, 35',
        onsurface: '255, 255, 255',
        //#endregion

        //#region Background
        background: '15, 15, 15',
        onbackground: '255, 255, 255',
        //#endregion

        //#region Criticty
        none: '102, 102, 102',
        low: '0, 204, 68',
        medium: '229, 229, 0',
        high: '255, 127, 0',
        critical: '255, 0, 0',
        //#endregion

        information: '199, 114, 255',
    },
    light: {
        //#region Surface
        surface: '255, 255, 255',
        onsurface: '0, 0, 0',
        //#endregion

        //#region Background
        background: '240, 240, 240',
        onbackground: '0, 0, 0',
        //#endregion

        //#region Criticty
        none: '191, 191, 191',
        low: '18, 178, 71',
        medium: '255, 204, 0',
        high: '255, 127, 0',
        critical: '255, 0, 0',
        //#endregion

        information: '103, 58, 183',
    },
};

export const THEMES: { [key: string]: Theme } = {
    slate: {
        //#region Primary
        primary50: '240, 243, 245',
        primary100: '209, 218, 225',
        primary200: '178, 193, 205',
        primary300: '147, 169, 185',
        onprimarylight: '0, 0, 0',

        primary400: '116, 144, 165',
        primary500: '70, 92, 108', // #465C6C
        primary600: '53, 70, 82',
        onprimary: '255, 255, 255',

        primary700: '41, 54, 64',
        primary800: '33, 44, 52',
        primary900: '24, 31, 37',
        onprimarydark: '255, 255, 255',
        //#endregion

        //#region Secondary
        secondary50: '227, 242, 253',
        secondary100: '187, 222, 251',
        secondary200: '144, 202, 249',
        secondary300: '100, 181, 246',
        onsecondarylight: '0, 0, 0',

        secondary400: '66, 165, 245',
        secondary500: '33, 150, 243', // #2196F3
        secondary600: '30, 136, 229',
        onsecondary: '255, 255, 255',

        secondary700: '25, 118, 210',
        secondary800: '21, 101, 192',
        secondary900: '13, 71, 161',
        onsecondarydark: '255, 255, 255',
        //#endregion

        //#region Highlight
        highlight50: '255, 248 225',
        highlight100: '255, 236, 179',
        highlight200: '255, 224, 130',
        highlight300: '255, 213, 79',
        onhighlightlight: '0, 0, 0',

        highlight400: '255, 202, 40',
        highlight500: '255, 193, 7', // #FFC107
        highlight600: '255, 179, 0',
        onhighlight: '255, 255, 255',

        highlight700: '255, 160, 0',
        highlight800: '255, 143, 0',
        highlight900: '255, 111, 0',
        onhighlightdark: '255, 255, 255',
        //#endregion
    },
    raspberry: {
        //#region Primary
        primary50: '247, 228, 232',
        primary100: '236, 193, 201',
        primary200: '225, 158, 170',
        primary300: '214, 122, 139',
        onprimarylight: '0, 0, 0',

        primary400: '203, 87, 108',
        primary500: '186, 58, 81', // #BA3A51
        primary600: '150, 47, 65',
        onprimary: '255, 255, 255',

        primary700: '115, 36, 50',
        primary800: '79, 25, 35',
        primary900: '44, 14, 19',
        onprimarydark: '255, 255, 255',
        //#endregion

        //#region Secondary
        secondary50: '227, 242, 253',
        secondary100: '187, 222, 251',
        secondary200: '144, 202, 249',
        secondary300: '100, 181, 246',
        onsecondarylight: '0, 0, 0',

        secondary400: '66, 165, 245',
        secondary500: '33, 150, 243', // #2196F3
        secondary600: '30, 136, 229',
        onsecondary: '255, 255, 255',

        secondary700: '25, 118, 210',
        secondary800: '21, 101, 192',
        secondary900: '13, 71, 161',
        onsecondarydark: '255, 255, 255',
        //#endregion

        //#region Highlight
        highlight50: '255, 248 225',
        highlight100: '255, 236, 179',
        highlight200: '255, 224, 130',
        highlight300: '255, 213, 79',
        onhighlightlight: '0, 0, 0',

        highlight400: '255, 202, 40',
        highlight500: '255, 193, 7', // #FFC107
        highlight600: '255, 179, 0',
        onhighlight: '255, 255, 255',

        highlight700: '255, 160, 0',
        highlight800: '255, 143, 0',
        highlight900: '255, 111, 0',
        onhighlightdark: '255, 255, 255',
        //#endregion
    },
    mustard: {
        //#region Primary
        primary50: '253, 240, 219',
        primary100: '249, 220, 172',
        primary200: '246, 201, 124',
        primary300: '243, 181, 76',
        onprimarylight: '0, 0, 0',

        primary400: '239, 161, 28',
        primary500: '203, 133, 14', // #CB850E
        primary600: '179, 117, 12',
        onprimary: '255, 255, 255',

        primary700: '155, 102, 11',
        primary800: '107, 70, 7',
        primary900: '60, 39, 4',
        onprimarydark: '255, 255, 255',
        //#endregion

        //#region Secondary
        secondary50: '227, 242, 253',
        secondary100: '187, 222, 251',
        secondary200: '144, 202, 249',
        secondary300: '100, 181, 246',
        onsecondarylight: '0, 0, 0',

        secondary400: '66, 165, 245',
        secondary500: '33, 150, 243', // #2196F3
        secondary600: '30, 136, 229',
        onsecondary: '255, 255, 255',

        secondary700: '25, 118, 210',
        secondary800: '21, 101, 192',
        secondary900: '13, 71, 161',
        onsecondarydark: '255, 255, 255',
        //#endregion

        //#region Highlight
        highlight50: '255, 248 225',
        highlight100: '255, 236, 179',
        highlight200: '255, 224, 130',
        highlight300: '255, 213, 79',
        onhighlightlight: '0, 0, 0',

        highlight400: '255, 202, 40',
        highlight500: '255, 193, 7', // #FFC107
        highlight600: '255, 179, 0',
        onhighlight: '255, 255, 255',

        highlight700: '255, 160, 0',
        highlight800: '255, 143, 0',
        highlight900: '255, 111, 0',
        onhighlightdark: '255, 255, 255',
        //#endregion
    },
    cyan: {
        //#region Primary
        primary50: '233, 244, 246',
        primary100: '212, 232, 237',
        primary200: '168, 209, 219',
        primary300: '125, 187, 201',
        onprimarylight: '0, 0, 0',

        primary400: '81, 164, 183',
        primary500: '38, 141, 165', // #268DA5
        primary600: '30, 113, 132',
        onprimary: '255, 255, 255',

        primary700: '23, 85, 99',
        primary800: '15, 56, 66',
        primary900: '8, 28, 33',
        onprimarydark: '255, 255, 255',
        //#endregion

        //#region Secondary
        secondary50: '227, 242, 253',
        secondary100: '187, 222, 251',
        secondary200: '144, 202, 249',
        secondary300: '100, 181, 246',
        onsecondarylight: '0, 0, 0',

        secondary400: '66, 165, 245',
        secondary500: '33, 150, 243', // #2196F3
        secondary600: '30, 136, 229',
        onsecondary: '255, 255, 255',

        secondary700: '25, 118, 210',
        secondary800: '21, 101, 192',
        secondary900: '13, 71, 161',
        onsecondarydark: '255, 255, 255',
        //#endregion

        //#region Highlight
        highlight50: '255, 248 225',
        highlight100: '255, 236, 179',
        highlight200: '255, 224, 130',
        highlight300: '255, 213, 79',
        onhighlightlight: '0, 0, 0',

        highlight400: '255, 202, 40',
        highlight500: '255, 193, 7', // #FFC107
        highlight600: '255, 179, 0',
        onhighlight: '255, 255, 255',

        highlight700: '255, 160, 0',
        highlight800: '255, 143, 0',
        highlight900: '255, 111, 0',
        onhighlightdark: '255, 255, 255',
        //#endregion
    },
    purple: {
        //#region Primary
        primary50: '240, 235, 248',
        primary100: '225, 216, 241',
        primary200: '194, 176, 226',
        primary300: '164, 137, 212',
        onprimarylight: '0, 0, 0',

        primary400: '133, 97, 197',
        primary500: '103, 58, 183', // #673AB7
        primary600: '82, 46, 146',
        onprimary: '255, 255, 255',

        primary700: '62, 35, 110',
        primary800: '41, 23, 73',
        primary900: '21, 12, 37',
        onprimarydark: '255, 255, 255',
        //#endregion

        //#region Secondary
        secondary50: '227, 242, 253',
        secondary100: '187, 222, 251',
        secondary200: '144, 202, 249',
        secondary300: '100, 181, 246',
        onsecondarylight: '0, 0, 0',

        secondary400: '66, 165, 245',
        secondary500: '33, 150, 243', // #2196F3
        secondary600: '30, 136, 229',
        onsecondary: '255, 255, 255',

        secondary700: '25, 118, 210',
        secondary800: '21, 101, 192',
        secondary900: '13, 71, 161',
        onsecondarydark: '255, 255, 255',
        //#endregion

        //#region Highlight
        highlight50: '255, 248 225',
        highlight100: '255, 236, 179',
        highlight200: '255, 224, 130',
        highlight300: '255, 213, 79',
        onhighlightlight: '0, 0, 0',

        highlight400: '255, 202, 40',
        highlight500: '255, 193, 7', // #FFC107
        highlight600: '255, 179, 0',
        onhighlight: '255, 255, 255',

        highlight700: '255, 160, 0',
        highlight800: '255, 143, 0',
        highlight900: '255, 111, 0',
        onhighlightdark: '255, 255, 255',
        //#endregion
    },
    deepblue: {
        //#region Primary
        primary50: '228, 232, 237',
        primary100: '188, 198, 211',
        primary200: '144, 160, 182',
        primary300: '99, 122, 152',
        onprimarylight: '0, 0, 0',

        primary400: '65, 94, 130',
        primary500: '32, 65, 108', // #3A4AB6
        primary600: '28, 59, 100',
        onprimary: '255, 255, 255',

        primary700: '24, 50, 89',
        primary800: '19, 42, 79',
        primary900: '11, 28, 61',
        onprimarydark: '255, 255, 255',
        //#endregion

        //#region Secondary
        secondary50: '227, 242, 253',
        secondary100: '187, 222, 251',
        secondary200: '144, 202, 249',
        secondary300: '100, 181, 246',
        onsecondarylight: '0, 0, 0',

        secondary400: '66, 165, 245',
        secondary500: '33, 150, 243', // #2196F3
        secondary600: '30, 136, 229',
        onsecondary: '255, 255, 255',

        secondary700: '25, 118, 210',
        secondary800: '21, 101, 192',
        secondary900: '13, 71, 161',
        onsecondarydark: '255, 255, 255',
        //#endregion

        //#region Highlight
        highlight50: '255, 248 225',
        highlight100: '255, 236, 179',
        highlight200: '255, 224, 130',
        highlight300: '255, 213, 79',
        onhighlightlight: '0, 0, 0',

        highlight400: '255, 202, 40',
        highlight500: '255, 193, 7', // #FFC107
        highlight600: '255, 179, 0',
        onhighlight: '255, 255, 255',

        highlight700: '255, 160, 0',
        highlight800: '255, 143, 0',
        highlight900: '255, 111, 0',
        onhighlightdark: '255, 255, 255',
        //#endregion
    },
    zimablue: {
        //#region Primary
        primary50: '239, 249, 253',
        primary100: '222, 243, 250',
        primary200: '189, 231, 245',
        primary300: '157, 218, 241',
        onprimarylight: '0, 0, 0',

        primary400: '124, 206, 236',
        primary500: '91, 194, 231', // #5BC2E7
        primary600: '73, 155, 185',
        onprimary: '255, 255, 255',

        primary700: '55, 116, 139',
        primary800: '36, 78, 92',
        primary900: '18, 39, 46',
        onprimarydark: '255, 255, 255',
        //#endregion

        //#region Secondary
        secondary50: '227, 242, 253',
        secondary100: '187, 222, 251',
        secondary200: '144, 202, 249',
        secondary300: '100, 181, 246',
        onsecondarylight: '0, 0, 0',

        secondary400: '66, 165, 245',
        secondary500: '33, 150, 243', // #2196F3
        secondary600: '30, 136, 229',
        onsecondary: '255, 255, 255',

        secondary700: '25, 118, 210',
        secondary800: '21, 101, 192',
        secondary900: '13, 71, 161',
        onsecondarydark: '255, 255, 255',
        //#endregion

        //#region Highlight
        highlight50: '255, 248 225',
        highlight100: '255, 236, 179',
        highlight200: '255, 224, 130',
        highlight300: '255, 213, 79',
        onhighlightlight: '0, 0, 0',

        highlight400: '255, 202, 40',
        highlight500: '255, 193, 7', // #FFC107
        highlight600: '255, 179, 0',
        onhighlight: '255, 255, 255',

        highlight700: '255, 160, 0',
        highlight800: '255, 143, 0',
        highlight900: '255, 111, 0',
        onhighlightdark: '255, 255, 255',
        //#endregion
    },
    backinblack: {
        //#region Primary
        primary50: '220, 220, 220',
        primary100: '180, 180, 180',
        primary200: '130, 130, 130',
        primary300: '80, 80, 80',
        onprimarylight: '0, 0, 0',

        primary400: '41, 41, 41',
        primary500: '10, 10, 10', // #030409
        primary600: '5, 5, 5',
        onprimary: '255, 255, 255',

        primary700: '3, 3, 3',
        primary800: '2, 2, 2',
        primary900: '0, 0, 0',
        onprimarydark: '255, 255, 255',
        //#endregion

        //#region Secondary
        secondary50: '227, 242, 253',
        secondary100: '187, 222, 251',
        secondary200: '144, 202, 249',
        secondary300: '100, 181, 246',
        onsecondarylight: '0, 0, 0',

        secondary400: '66, 165, 245',
        secondary500: '33, 150, 243', // #2196F3
        secondary600: '30, 136, 229',
        onsecondary: '255, 255, 255',

        secondary700: '25, 118, 210',
        secondary800: '21, 101, 192',
        secondary900: '13, 71, 161',
        onsecondarydark: '255, 255, 255',
        //#endregion

        //#region Highlight
        highlight50: '255, 248 225',
        highlight100: '255, 236, 179',
        highlight200: '255, 224, 130',
        highlight300: '255, 213, 79',
        onhighlightlight: '0, 0, 0',

        highlight400: '255, 202, 40',
        highlight500: '255, 193, 7', // #FFC107
        highlight600: '255, 179, 0',
        onhighlight: '255, 255, 255',

        highlight700: '255, 160, 0',
        highlight800: '255, 143, 0',
        highlight900: '255, 111, 0',
        onhighlightdark: '255, 255, 255',
        //#endregion
    },
};
