import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { AuthState } from '../shared/store/state/auth.state';
import { Store } from '@ngrx/store';
import { environment } from '../../environments/environment';
import { selectActiveFiltersAll } from 'src/app/shared/store/selectors/filters.selectors';
import { map, first, mergeMap } from 'rxjs/operators';
import { UtilsService } from '../shared/utils/utils.service';
import { selectAuthIsLoggedIn } from '../shared/store/selectors/auth.selectors';
import { FilterType } from '../models/filter-type.enum';

@Injectable()
export class FilterInterceptor implements HttpInterceptor {
    private readonly EXCLUDED_FILTER_ENDPOINTS = [
        environment.bckAPI.exportAPIs.exportAssets,
    ];
    private readonly EXCLUDED_FILTER_ROUTES = [
        ...Object.values(environment.bckAPI.authAPIs),
        ...Object.values(environment.bckAPI.adminAPIs),
        ...Object.values(environment.bckAPI.userAPIs),
        ...Object.values(environment.bckAPI.cmdbInterfaceAPIs),
        ...Object.values(environment.bckAPI.responseAPIs),
        ...Object.values(environment.bckAPI.whitelist_ruleAPIs),
    ];

    constructor(private readonly store: Store<AuthState>, private readonly utils: UtilsService) { }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        if (request.url.startsWith(environment.bckAPI.baseUrl) && this.requestNeedsFilter(request.url)) {
            return this.store.select(selectAuthIsLoggedIn).pipe(
                first(),
                mergeMap(isAuth => {
                    if (isAuth) {
                        return this.addFiltersToRequest(request).pipe(
                            first(),
                            mergeMap(req => next.handle(req))
                        );
                    } else {
                        return next.handle(request);
                    }
                })
            );
        } else {
            return next.handle(request);
        }
    }

    private requestNeedsFilter(url: string) {
        if (this.EXCLUDED_FILTER_ENDPOINTS.includes(url)) return false;
        if (this.EXCLUDED_FILTER_ROUTES.some(invalidUrl => url.includes(invalidUrl))) return false;
        return true;
    }

    private addFiltersToRequest(request: HttpRequest<unknown>): Observable<HttpRequest<unknown>> {
        return this.store.select(selectActiveFiltersAll).pipe(
            map(filters => {
                const convertFilters = this.utils.convertEventFilters(filters.filter(f => f.type !== FilterType.DATE_RANGE));
                if (request.method.toLowerCase() === 'post') {
                    if (request.body instanceof FormData) {
                        if (!request.body.has('filters')) {
                            request = request.clone({
                                body: request.body.append('filters', JSON.stringify(convertFilters)),
                            });
                        }
                    } else if (request.body instanceof Object) {
                        request = request.clone({
                            body: { ...request.body, filters: convertFilters },
                        });
                    }
                }
                return request;
            })
        );
    }
}
