import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { AvailableFilter } from 'src/app/models/available-filter.interface';
import { Filter } from '../../../models/filter.interface';

//#region filters

export const activeFiltersAdapter = createEntityAdapter<Filter>({
    selectId: item => item.id,
});

export interface ActiveFiltersState extends EntityState<Filter> {
    editionId: string | null;
}

export const initialActiveFiltersState: ActiveFiltersState = activeFiltersAdapter.getInitialState({
    editionId: null,
});

export const availableFiltersAdapter = createEntityAdapter<AvailableFilter>({
    selectId: item => item.field,
});

export const initialAvailableFiltersState = availableFiltersAdapter.getInitialState();

export interface FiltersState {
    activeFilters: ActiveFiltersState;
    availableFilters: EntityState<AvailableFilter>;
}

export const initialFiltersState: FiltersState = {
    activeFilters: initialActiveFiltersState,
    availableFilters: initialAvailableFiltersState,
};

//#endregion
