import { createFeatureSelector, createSelector } from '@ngrx/store';
import { historyListAdapter, HistoryListState } from '../state/history-list.state';
import { HistoryViewData } from '../../../models/history-view-data.interface';
import { AppState } from '../state/app.state';
import { Filter } from '../../../models/filter.interface';

export const selectHistoryListState = createFeatureSelector<HistoryListState>('historyList');

export const {
    selectAll: selectAllHistoryList,
    selectEntities: selectHistoryListEntities,
    selectTotal: selectTotalHistoryList,
} = historyListAdapter.getSelectors(selectHistoryListState);

export const selectHistoryEntryById = (id: string) => createSelector(selectHistoryListEntities, historyList => historyList[id]);

export const selectCurrentHistoryEntryId = createSelector(selectHistoryListState, state => state.currentEntry);

export const selectHistoryViewData = createSelector(selectAllHistoryList, entries => {
    const viewData: HistoryViewData[] = [];

    entries.forEach(entry => {
        const entryToPush = { ...entry } as HistoryViewData;
        if (entry.state) {
            const entryState = JSON.parse(entry.state) as AppState;
            if (entryState.filters) {
                const filters = Object.values(entryState.filters.activeFilters.entities) as Filter[];
                entryToPush.filters = filters;
            }
        }
        viewData.push(entryToPush);
    });
    const sortedViewData = viewData.sort((a, b) => b.date - a.date);
    return sortedViewData;
});

export const selectCurrentHistoryEntry = createSelector(
    selectHistoryListEntities,
    selectCurrentHistoryEntryId,
    (entities, currentEntryId) => (currentEntryId ? entities[currentEntryId] : undefined)
);

export const selectCurrentBackHistory = createSelector(
    selectHistoryListEntities,
    selectCurrentHistoryEntry,
    (entities, currentHistoryEntry) =>
        currentHistoryEntry && currentHistoryEntry.backId ? entities[currentHistoryEntry.backId] : undefined
);
