import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, tap } from 'rxjs/operators';
import { CountryService } from 'src/app/services/country.service';
import { AuthActionTypes } from '../actions/auth.actions';
import { CountryTypes, loadCountryFailure, loadCountryRequest, loadCountrySuccess } from '../actions/country.action';
import { StatusState } from '../state/status.state';

@Injectable()
export class CountryEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly store: Store<StatusState>,
        private readonly countryService: CountryService
    ) {}

    init$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AuthActionTypes.USER_AUTO_LOGGED_IN, AuthActionTypes.LOGIN_SUCCESS),
                tap(() => {
                    this.store.dispatch(loadCountryRequest());
                })
            ),
        { dispatch: false }
    );

    countryRequest$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CountryTypes.LOAD_COUNTRY_REQUEST),
            exhaustMap(() =>
                this.countryService.getCountryList().pipe(
                    map(countries => loadCountrySuccess({ countries })),
                    catchError(error => of(loadCountryFailure({ error })))
                )
            )
        )
    );
}
