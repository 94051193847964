import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ApiResponse } from 'src/app/models/api-response.interface';
import { User } from 'src/app/models/user.interface';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class AdminService {
    constructor(private readonly http: HttpClient) {}

    getUsersList() {
        return this.http.get<ApiResponse<User[]>>(environment.bckAPI.adminAPIs.usersUrl).pipe(
            map(response => {
                // ! MOCK à retirer
                this.mapResult(response.result);
                return response.result;
            })
        );
    }

    updateUser(userId: number | undefined, newUsername: string, newPassword: string | undefined, newRoles: string[]) {
        const newUserData = {
            ...(newRoles.length > 0 && { roles: newRoles }),
            ...(newPassword && { password: newPassword }),
            ...(newUsername && { name: newUsername }),
        };
        let url = environment.bckAPI.adminAPIs.userUrl;
        if (!!userId) {
            url += userId;
        }

        return this.http.post(url, newUserData);
    }

    deleteUser(userId: number) {
        return this.http.delete(environment.bckAPI.adminAPIs.userUrl + userId);
    }

    mapResult(response) {
        response.forEach(u => {
            const roles: string[] = JSON.parse(u.roles);
            u.roles = [];

            if (roles.includes('SUPERADMIN')) {
                u.roles.push('SUPERADMIN');
            }
            if (roles.includes('ADMIN')) {
                u.roles.push('ADMIN');
            }
            if (roles.includes('USER')) {
                u.roles.push('USER');
            }
        });
    }
}
