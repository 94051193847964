import { createReducer, on } from '@ngrx/store';
import {
    setCriticity,
    setDynamicTheme,
    setTheme,
    setThemeLuminosity,
    toggleDynamicTheme,
    setDefaultTimeSelection,
    setNavbarExtended,
    setDefaultEmail,
} from '../actions/settings.actions';
import { initialSettingsState } from '../state/settings.state';

export const settingsReducer = createReducer(
    initialSettingsState,
    on(setCriticity, (state, { newCriticity }) => ({ ...state, criticity: newCriticity })),
    on(setTheme, (state, { newTheme }) => ({ ...state, theme: newTheme })),
    on(toggleDynamicTheme, state => ({ ...state, dynamicTheme: !state.dynamicTheme })),
    on(setDynamicTheme, (state, { dynamicTheme }) => ({ ...state, dynamicTheme })),
    on(setThemeLuminosity, (state, { themeLuminosity }) => ({ ...state, themeLuminosity })),
    on(setDefaultTimeSelection, (state, { timeSelection }) => ({ ...state, defaultTimeSelection: timeSelection })),
    on(setNavbarExtended, (state, { newExtendedState }) => ({ ...state, navbarExtended: newExtendedState })),
    on(setDefaultEmail, (state, { newDefaultEmail }) => ({ ...state, defaultEmail: newDefaultEmail }))
);
