import { createAction, props } from '@ngrx/store';
import { AssetsIps } from 'src/app/models/cmdb.interface';

export enum AssetsIpsTypes {
    LOAD_ASSETS_IPS_REQUEST = '[Assets IPs Api] Load Request',
    LOAD_ASSETS_IPS_SUCCESS = '[Assets IPs Api] Load Success',
    LOAD_ASSETS_IPS_FAILURE = '[Assets IPs Api] Load Failure',
}

export const loadAssetsIpsRequest = createAction(AssetsIpsTypes.LOAD_ASSETS_IPS_REQUEST);
export const loadAssetsIpsSuccess = createAction(AssetsIpsTypes.LOAD_ASSETS_IPS_SUCCESS, props<{ assetsIps: AssetsIps[] }>());
export const loadAssetsIpsFailure = createAction(AssetsIpsTypes.LOAD_ASSETS_IPS_FAILURE, props<{ error: any }>());
