import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
    DictionaryState,
    fieldsNameDescriptionI18nAdapter,
    ndpiL7DictionaryAdapter,
} from '../state/dictionary.state';

export const selectDictionaryState = createFeatureSelector<DictionaryState>('dictionary');

//#region Feature importance

export const selectFieldsNameDescriptionI18nState = createSelector(selectDictionaryState, state => state.fields_name);

export const {
    selectIds: selectFieldsNameDescriptionI18nIds,
    selectEntities: selectFieldsNameDescriptionI18nEntities,
    selectAll: selectFieldsNameDescriptionI18nAll,
} = fieldsNameDescriptionI18nAdapter.getSelectors(selectFieldsNameDescriptionI18nState);

export const selectFieldsNameDescriptionI18nIsLoading = createSelector(selectFieldsNameDescriptionI18nState, state => state.isLoading);
export const selectFieldsNameDescriptionI18nError = createSelector(selectFieldsNameDescriptionI18nState, state => state.error);

//#endregion

//#region NdpiL7

export const selectNdpiL7DictionaryState = createSelector(selectDictionaryState, state => state.ndpi_l7);

export const {
    selectIds: selectNdpiL7DictionaryIds,
    selectEntities: selectNdpiL7DictionaryEntities,
    selectAll: selectNdpiL7DictionaryAll,
} = ndpiL7DictionaryAdapter.getSelectors(selectNdpiL7DictionaryState);

export const selectNdpiL7DictionaryIsLoading = createSelector(selectNdpiL7DictionaryState, state => state.isLoading);
export const selectNdpiL7DictionaryError = createSelector(selectNdpiL7DictionaryState, state => state.error);

//#endregion
