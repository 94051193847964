<div class="scene relative w-full h-full mx-auto my-0 [perspective:1000px]" #scene *ngIf="cells">
    <div class="absolute w-full h-full z-0 bg-transparent" (click)="close()"></div>
    <div class="carousel w-full h-full absolute [transform:translateZ(-288px)] [transform-style:preserve-3d] duration-1000 transition-transform pointer-events-none"
        #carousel>
        <div class="rounded overflow-hidden pointer-events-auto z-10 carousel__cell absolute flex flex-col justify-center items-center w-11/12 h-11/12 m-auto top-0 right-0 left-0 bottom-0 transition-transform duration-1000"
            [ngStyle]="{ height: height, width: width }" *ngFor="let cell of cells; let i = index">
            <div class="w-full bg-primary-500 text-onprimary flex items-center justify-center p-1">
                <button
                    class="flex-grow flex items-center justify-center uppercase border rounded border-surface-500 mx-1 p-1 hover:bg-secondary-500/50"
                    *ngFor="let c of cells; let idx = index" [ngClass]="selectedIndex === idx ? 'bg-surface-500' : ''"
                    (click)="showCell(idx)">
                    <h6 class="pr-2" [ngClass]="selectedIndex === idx ? 'text-header' : 'text-transparent'">&#10004;
                    </h6>
                    <h6 [ngClass]="selectedIndex === idx ? 'text-header' : 'text-title'">{{ c.title }}</h6>
                </button>
            </div>

            <ndc-dynamic [ndcDynamicComponent]="cell.component" [ndcDynamicInputs]="getDynamicInputs(cell)"
                [ndcDynamicOutputs]="getDynamicOutputs(cell)" [ndcDynamicAttributes]="attributes"></ndc-dynamic>
        </div>
    </div>
</div>