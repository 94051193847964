import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { AdminRoutingModule } from './admin-routing.module';
import { UserEditComponent } from './components/user-edit/user-edit.component';
import { UsersListComponent } from './components/users-list/users-list.component';
import { ViewComponent } from './view/view.component';

@NgModule({
    declarations: [ViewComponent, UsersListComponent, UserEditComponent],
    imports: [SharedModule, AdminRoutingModule],
})
export class AdminModule {}
