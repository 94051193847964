import { createReducer, on } from '@ngrx/store';
import { initialObjectEventAnalyzerDescriptionState, objectEventAnalyzerDescriptionAdapter } from '../state/object-event-analyzer-description.state';
import { loadObjectEventAnalyzerDescriptionFailure, loadObjectEventAnalyzerDescriptionRequest, loadObjectEventAnalyzerDescriptionSuccess } from '../actions/object-event-analyzer-description.actions';

export const objectEventAnalyzerDescriptionReducers = createReducer(
    initialObjectEventAnalyzerDescriptionState,
    on(loadObjectEventAnalyzerDescriptionRequest, state => ({ ...state, isLoading: true, error: null })),
    on(loadObjectEventAnalyzerDescriptionSuccess, (state, { objectEventAnalyzerDescription }) => objectEventAnalyzerDescriptionAdapter.setAll(objectEventAnalyzerDescription, { ...state, isLoading: false, error: null })),
    on(loadObjectEventAnalyzerDescriptionFailure, (state, { error }) => ({ ...state, isLoading: false, error }))
);
