import { Pipe, PipeTransform } from '@angular/core';
import { cloneDeep } from 'lodash';

@Pipe({
    name: 'clone',
})
export class ClonePipe implements PipeTransform {
    transform<T>(value: T, ...args: unknown[]): T {
        return cloneDeep<T>(value);
    }
}
