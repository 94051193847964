import { Component, OnInit } from '@angular/core';
import { Observable, take } from 'rxjs';
import { KnubeCell } from 'src/app/models/knube-cell.interface';
import { User } from 'src/app/models/user.interface';
import { OverlayManagerService } from 'src/app/services/overlay-manager.service';
import { KnubeComponent } from 'src/app/shared/modules/knube/knube.component';
import { UserEditComponent } from '../components/user-edit/user-edit.component';
import { AdminService } from '../services/admin.service';

@Component({
    selector: 'hypervision-view',
    templateUrl: './view.component.html',
    styleUrls: ['./view.component.scss'],
})
export class ViewComponent implements OnInit {
    users: Observable<User[]>;
    cells: KnubeCell[] = [];
    knube: KnubeComponent | null;

    constructor(private readonly adminService: AdminService, private readonly overlayManager: OverlayManagerService) {}

    ngOnInit(): void {
        this.loadUsers();
        this.bindEvents();
    }

    createUser() {
        this.initCells();
        this.showKnube();
    }

    loadUsers() {
        this.users = this.adminService.getUsersList();
    }

    private showKnube() {
        this.knube = this.overlayManager.openOverlay<KnubeComponent>(KnubeComponent, 'center', {
            cells: this.cells,
            height: '330px',
            width: '300px',
            showTitle: true,
        });
        this.knube.askForClose.pipe(take(1)).subscribe(r => {
            this.overlayManager.closeOverlay();
            this.knube = null;
        });
    }

    private initCells() {
        this.cells = [
            {
                component: UserEditComponent,
                title: 'User creation',
                inputs: {},
                outputs: {
                    askForClose: () => {
                        this.overlayManager.closeOverlay();
                        this.loadUsers();
                        this.knube = null;
                    },
                },
            },
        ];
    }

    private bindEvents() {
        document.addEventListener(
            'keydown',
            event => {
                const keyCode = event.key;
                switch (keyCode) {
                    case 'ArrowRight':
                        this.knube?.previous();
                        break;
                    case 'ArrowLeft':
                        this.knube?.next();
                        break;
                    case 'Escape':
                        this.overlayManager.closeOverlay();
                        this.knube = null;
                        break;
                    default:
                        break;
                }
            },
            { passive: true }
        );
    }
}
