import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { Store } from '@ngrx/store';
import moment from 'moment';
import { Observable, lastValueFrom } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { ApiResponse } from 'src/app/models/api-response.interface';
import { AvailableFilter } from 'src/app/models/available-filter.interface';
import { ObjectThreat } from 'src/app/models/object-threat.interface';
import { SuricataRule } from 'src/app/models/suricata-rules.interface';
import { setAvailableFilters } from 'src/app/shared/store/actions/filters.actions';
import { selectAnalyzerAll } from 'src/app/shared/store/selectors/analyzer.selectors';
import {
    selectClassificationTreeAll
} from 'src/app/shared/store/selectors/scoring.selectors';
import { AppState } from 'src/app/shared/store/state/app.state';
import { UtilsService } from 'src/app/shared/utils/utils.service';
import { environment } from 'src/environments/environment';
import { ObjectEvent, ObjectEventAnalyzerDescription } from '../../models/object-event.interface';

@Injectable({
    providedIn: 'root',
})
export class EventsDataService {
    constructor(
        @Inject(LOCALE_ID) protected localeId: string,
        private readonly http: HttpClient,
        private readonly store: Store<AppState>,
        private readonly utils: UtilsService
    ) { }

    getEvents(fromDate: string, toDate: string, limit: number, offset: number): Observable<ObjectEvent[]> {
        return this.http.post<ApiResponse<any[]>>(environment.bckAPI.eventAPIs.eventUrl, { from_date: fromDate, to_date: toDate, limit: limit, offset: offset })
            .pipe(map(r => r.result));
    }

    getEvent(event_id: string, date: string) {
        return this.http
            .get<ApiResponse<any>>(
                environment.bckAPI.eventAPIs.eventUrl +
                event_id +
                '?lang=' +
                this.localeId +
                '&date=' +
                moment(date).toISOString()
            )
            .pipe(map(r => r?.result));
    }

    getSuricataRuleDetails(id: number): Observable<SuricataRule> {
        return this.http.get<ApiResponse<SuricataRule>>(environment.bckAPI.suricataAPIs.suricataRuleUrl + id).pipe(map(r => r.result));
    }

    getEventColumns() {
        return this.http.get<ApiResponse<any>>(environment.bckAPI.eventAPIs.filterFieldsUrl).pipe(map(r => r.result));
    }

    getScoreByIps(fromDate: string, toDate: string): Observable<any> {
        const queryParams: string[] = [];
        if (!!fromDate) queryParams.push('from_date=' + fromDate.replace('Z', ''));
        if (!!toDate) queryParams.push('to_date=' + toDate.replace('Z', ''));
        const queryParamsFormat = '?' + queryParams.join('&');

        return this.http.get<any>(environment.bckAPI.eventAPIs.scoreByIpsUrl + queryParamsFormat).pipe(map(response => response.result));
    }

    getThreatFromEvent(event_id: string, date: string): Observable<ObjectThreat> {
        return this.http.get<ApiResponse<ObjectThreat>>(environment.bckAPI.eventAPIs.getThreatFromEventUrl +
            event_id +
            '?date=' +
            moment(date).toISOString()).pipe(map(response => response.result));
    }

    getObjectEventAnalyzerDescription(): Observable<ObjectEventAnalyzerDescription[]> {
        return this.http
            .get<ApiResponse<ObjectEventAnalyzerDescription[]>>(environment.bckAPI.eventAPIs.objectEventAnalyzerDescriptionUrl + '?lang=' + this.localeId)
            .pipe(map(r => r?.result));
    }

    async getEventsFilterFieldsForQueryBuilder() {
        this.getEventColumns().pipe(take(1)).subscribe(c => {
            const availableFilters: AvailableFilter[] = this.utils.getEventsFilterFields(c);
            availableFilters.sort((a, b) => (a.label > b.label ? 1 : -1));
            this.store.dispatch(setAvailableFilters({ availableFilters }));
        });
    }
}
