import { createAction, props } from '@ngrx/store';
import { AssetView } from 'src/app/models/asset-view.interface';
import { TreeGridCol } from 'src/app/models/tree-grid-col.interface';
import { AssetType } from '../../../models/asset-type.interface';
import { CmdbSite } from 'src/app/models/cmdb.interface';

export enum AssetsActionTypes {
    ASSETS_TYPE_LOAD_REQUEST = '[Assets Type] Load Request',
    ASSETS_TYPE_LOAD_FAILURE = '[Assets Type] Load Failure',
    ASSETS_TYPE_LOAD_SUCCESS = '[Assets Type] Load Success',

    CMDB_SITE_LOAD_REQUEST = '[CMDB Site] Load Request',
    CMDB_SITE_LOAD_FAILURE = '[CMDB Site] Load Failure',
    CMDB_SITE_LOAD_SUCCESS = '[CMDB Site] Load Success',

    ASSETS_COL_UPSERT_MANY = '[Assets Col] Upsert Many Col',
    ASSETS_COL_ADD_ONE = '[Assets Col] Add One Col',
    ASSETS_COL_ADD_MANY = '[Assets Col] Add Many Col',
    ASSETS_COL_UPDATE_ONE = '[Assets Col] Update One Col',
    ASSETS_COL_UPDATE_MANY = '[Assets Col] Update Many Col',
}

export const loadAssetsTypeRequestAction = createAction(AssetsActionTypes.ASSETS_TYPE_LOAD_REQUEST);
export const loadAssetsTypeFailureAction = createAction(AssetsActionTypes.ASSETS_TYPE_LOAD_FAILURE, props<{ error: string }>());
export const loadAssetsTypeSuccessAction = createAction(AssetsActionTypes.ASSETS_TYPE_LOAD_SUCCESS, props<{ types: AssetType[] }>());

export const loadCmdbSiteRequestAction = createAction(AssetsActionTypes.CMDB_SITE_LOAD_REQUEST);
export const loadCmdbSiteFailureAction = createAction(AssetsActionTypes.CMDB_SITE_LOAD_FAILURE, props<{ error: string }>());
export const loadCmdbSiteSuccessAction = createAction(AssetsActionTypes.CMDB_SITE_LOAD_SUCCESS, props<{ sites: CmdbSite[] }>());

export const assetsColUpsertMany = createAction(AssetsActionTypes.ASSETS_COL_UPSERT_MANY, props<{ newCols: TreeGridCol[] }>());
export const assetsColAddOne = createAction(AssetsActionTypes.ASSETS_COL_ADD_ONE, props<{ newCol: TreeGridCol }>());
export const assetsColAddMany = createAction(AssetsActionTypes.ASSETS_COL_ADD_MANY, props<{ newCols: TreeGridCol[] }>());
export const assetsColUpdateOne = createAction(AssetsActionTypes.ASSETS_COL_UPDATE_ONE, props<{ updatedCol: TreeGridCol }>());
export const assetsColUpdateMany = createAction(AssetsActionTypes.ASSETS_COL_UPDATE_MANY, props<{ updatedCols: TreeGridCol[] }>());
