<div class="h-full w-full flex items-center">
    <div class="flex items-center w-full" [ngClass]="isBlocked ? 'e-info' : ''"
        [attr.title]="isBlocked ? 'IP already blocked' : null">
        <div class="flex items-center"
            *ngIf="!!assetsIps && !!assetsIps[ip] && !!assetsIps[ip] else noAssetLinkedBlock">
            <div class="px-1 flex flex-col">
                <span [ngClass]="isBlocked ? 'text-critical font-medium' : 'text-onsurface'">{{
                    assetsIps[ip]?.asset_name}}</span>
                <span [ngClass]="isBlocked ? 'text-critical font-medium' : 'text-onsurface/50'" class="italic text-xs"
                    *ngIf="assetsIps[ip]?.asset_name !== ip">{{ ip }}</span>
            </div>
        </div>
        <ng-template #noAssetLinkedBlock>
            <span class="px-1" [ngClass]="isBlocked ? 'text-critical font-medium' : ''">{{ ip }}</span>
        </ng-template>
    </div>
</div>
