import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { AttackReason } from 'src/app/models/attack-reason.interface';

export const attackReasonAdapter = createEntityAdapter<AttackReason>({
    selectId: item => item.id,
});

export interface AttackReasonState extends EntityState<AttackReason> {
    isLoading: boolean;
    error: any;
}

export const initialAttackReasonState: AttackReasonState = attackReasonAdapter.getInitialState({
    isLoading: false,
    error: null,
});
