import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, forkJoin } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { selectAuthIsLoggedIn, selectAuthUser } from '../shared/store/selectors/auth.selectors';
import { AuthState } from '../shared/store/state/auth.state';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanActivateChild {
    constructor(private readonly store: Store<AuthState>, private readonly router: Router) {}

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        return this.checkAuth(next, state);
    }

    canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        return this.checkAuth(next, state);
    }

    private userHasRole(roles: string[], userRoles: string[]) {
        for (const role of roles) {
            if (userRoles.includes(role)) {
                return true;
            }
        }
        return false;
    }

    private checkAuth(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        return forkJoin([this.store.select(selectAuthIsLoggedIn).pipe(take(1)), this.store.select(selectAuthUser).pipe(take(1))]).pipe(
            map(([isLoggedIn, user]) => {
                if (!isLoggedIn) {
                    this.router.navigate(['/login']);
                    return false;
                }
                if (user != undefined && next?.data?.roles && !this.userHasRole(next?.data?.roles as string[], user?.roles)) {
                    this.router.navigate(['/']);
                    return false;
                }
                return true;
            })
        );
    }
}
