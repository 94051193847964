import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ViewSetting } from '../../../models/view-setting.interface';
import { viewSettingsAdapter, ViewSettingsState } from '../state/view-settings.state';

export const selectViewSettingsState = createFeatureSelector<ViewSettingsState>('viewSettings');

export const {
    selectIds: selectViewSettingsIds,
    selectEntities: selectViewSettingsEntities,
    selectAll: selectViewSettingsAll,
    selectTotal: selectViewSettingsTotal,
} = viewSettingsAdapter.getSelectors(selectViewSettingsState);

export const selectViewSettingsById = (id: string) =>
    createSelector(selectViewSettingsAll, (allViewSettings: ViewSetting[]) => {
        if (allViewSettings) {
            return allViewSettings.find(c => c.id === id);
        } else {
            return undefined;
        }
    });
