import { createAction, props } from '@ngrx/store';
import { ViewSetting } from '../../../models/view-setting.interface';

export enum ViewSettingsTypes {
    LOAD_VIEW_SETTINGS_REQUEST = '[View Settings Api] Load Request',
    LOAD_VIEW_SETTINGS_SUCCESS = '[View Settings Api] Load Success',
    LOAD_VIEW_SETTINGS_FAILURE = '[View Settings Api] Load Failure',
    UPDATE_VIEW_SETTINGS = '[View Settings Api] Update Settings',
}

export const loadViewSettingsRequest = createAction(ViewSettingsTypes.LOAD_VIEW_SETTINGS_REQUEST);
export const loadViewSettingsSuccess = createAction(ViewSettingsTypes.LOAD_VIEW_SETTINGS_SUCCESS, props<{ viewSettings: ViewSetting[] }>());
export const loadViewSettingsFailure = createAction(ViewSettingsTypes.LOAD_VIEW_SETTINGS_FAILURE, props<{ error: any }>());
export const updateViewSettings = createAction(ViewSettingsTypes.UPDATE_VIEW_SETTINGS, props<{ viewSetting: ViewSetting }>());
