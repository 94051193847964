export enum WhitelistRuleIpType_FromDB {
    IP = 0,
    NETWORK = 1,
    INTERNAL = 2,
    EXTERNAL = 3,
}

export enum WhitelistRuleIpType {
    IP = 'IP',
    NETWORK = 'NETWORK',
    INTERNAL = 'INTERNAL',
    EXTERNAL = 'EXTERNAL',
}



