import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { ClassificationTreeNode } from '../../../models/score-class-node.interface';

//#region Classification Tree

export const classificationTreeAdapter = createEntityAdapter<ClassificationTreeNode>({
    selectId: item => item.id,
});

export interface ClassificationTreeState extends EntityState<ClassificationTreeNode> {
    isLoading: boolean;
    error: any;
    topLevelNodesIds: number[] | null;
}

export const initialClassificationTreeState: ClassificationTreeState = classificationTreeAdapter.getInitialState({
    isLoading: false,
    error: null,
    topLevelNodesIds: null,
});

//#endregion

//#region Max Boost and Init
export interface ScoringMaxBoostAndInitState {
    maxBoostThreat: number;
    maxInitThreat: number;
    maxBoostAlert: number;
    maxInitAlert: number;
}

export const initialScoringMaxBoostAndInitState: ScoringMaxBoostAndInitState = {
    maxBoostThreat: 1.0,
    maxInitThreat: 1.0 / 1.05,
    maxBoostAlert: 1.0 / 1.05,
    maxInitAlert: 1.0 / 1.05 / 1.05,
};
//#endregion

export interface ScoringState {
    classificationTree: ClassificationTreeState;
    maxBoostAndInit: ScoringMaxBoostAndInitState;
}

export const initialScoringState: ScoringState = {
    classificationTree: initialClassificationTreeState,
    maxBoostAndInit: initialScoringMaxBoostAndInitState,
};
