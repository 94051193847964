import { createFeatureSelector } from '@ngrx/store';
import { analyzerAdapter, AnalyzerState } from '../state/analyzer.state';

export const selectAnalyzerState = createFeatureSelector<AnalyzerState>('analyzer');

export const {
    selectIds: selectAnalyzerIds,
    selectEntities: selectAnalyzerEntities,
    selectAll: selectAnalyzerAll,
    selectTotal: selectAnalyzerTotal,
} = analyzerAdapter.getSelectors(selectAnalyzerState);
