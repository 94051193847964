import { Criticity } from '../../../models/criticity.interface';
import { TimeSelection, TimeType, PeriodUnit } from '../../../models/time-selection.interface';

export interface SettingsState {
    criticity: Criticity;
    dynamicTheme: boolean;
    theme: string;
    themeLuminosity: string;
    defaultTimeSelection: TimeSelection;
    navbarExtended: boolean;
    defaultEmail: string;
}

export const initialSettingsState: SettingsState = {
    criticity: {
        low: 1,
        medium: 20,
        high: 50,
        critical: 90,
        attack_low: 0,
        attack_medium: 25,
        attack_high: 50,
        attack_critical: 75,
    },
    dynamicTheme: false,
    theme: 'deepblue',
    themeLuminosity: 'light',
    defaultTimeSelection: {
        periodUnit: PeriodUnit.days,
        periodValue: 2,
        timeType: TimeType.customRange,
        minTime: '2022-09-09T14:30:00.000',
        maxTime: '2022-09-09T17:00:00.000',
    },
    navbarExtended: false,
    defaultEmail: '',
};
