import { createFeatureSelector, createSelector } from '@ngrx/store';
import { mitreDatasourcesAdapter, mitreGroupsAdapter, mitreTechniquesAdapter, mitreMitigationsAdapter, MitreState, mitreTacticsAdapter } from '../state/mitre.state';

export const selectMitreState = createFeatureSelector<MitreState>('mitre');

//----------------------------------------------------------------------------------------------------------
export const selectMitreTacticsState = createSelector(selectMitreState, state => state.tactics);
export const {
    selectIds: selectMitreTacticsIds,
    selectEntities: selectMitreTacticsEntities,
    selectAll: selectMitreTacticsAll,
    selectTotal: selectMitreTacticsTotal,
} = mitreTacticsAdapter.getSelectors(selectMitreTacticsState);

//----------------------------------------------------------------------------------------------------------
export const selectMitreTechniquesState = createSelector(selectMitreState, state => state.techniques);
export const {
    selectIds: selectMitreTechniquesIds,
    selectEntities: selectMitreTechniquesEntities,
    selectAll: selectMitreTechniquesAll,
    selectTotal: selectMitreTechniquesTotal,
} = mitreTechniquesAdapter.getSelectors(selectMitreTechniquesState);

//----------------------------------------------------------------------------------------------------------
export const selectMitreMitigationsState = createSelector(selectMitreState, state => state.mitigations);
export const {
    selectIds: selectMitreMitigationsIds,
    selectEntities: selectMitreMitigationsEntities,
    selectAll: selectMitreMitigationsAll,
    selectTotal: selectMitreMitigationsTotal,
} = mitreMitigationsAdapter.getSelectors(selectMitreMitigationsState);

//----------------------------------------------------------------------------------------------------------
export const selectMitreDatasourcesState = createSelector(selectMitreState, state => state.datasources);
export const {
    selectIds: selectMitreDatasourcesIds,
    selectEntities: selectMitreDatasourcesEntities,
    selectAll: selectMitreDatasourcesAll,
    selectTotal: selectMitreDatasourcesTotal,
} = mitreDatasourcesAdapter.getSelectors(selectMitreDatasourcesState);

//----------------------------------------------------------------------------------------------------------
export const selectMitreGroupsState = createSelector(selectMitreState, state => state.groups);
export const {
    selectIds: selectMitreGroupsIds,
    selectEntities: selectMitreGroupsEntities,
    selectAll: selectMitreGroupsAll,
    selectTotal: selectMitreGroupsTotal,
} = mitreGroupsAdapter.getSelectors(selectMitreGroupsState);
