import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { ViewSetting } from 'src/app/models/view-setting.interface';

export const viewSettingsAdapter = createEntityAdapter<ViewSetting>({
    selectId: item => item.id,
});

export interface ViewSettingsState extends EntityState<ViewSetting> {
    isLoading: boolean;
    error: any;
}

export const initialViewSettingsState: ViewSettingsState = viewSettingsAdapter.getInitialState({
    isLoading: false,
    error: null,
});
