import { createAction, props } from '@ngrx/store';
import { HistoryData } from '../../../models/history-data.interface';
import { AppState } from '../state/app.state';

export enum HistoryListActionType {
    ADD_HISTORY_ENTRY = '[History] Add entry',
    EDIT_HISTORY_ENTRY = '[History] Edit entry',
    EDIT_CURRENT_HISTORY_ENTRY = '[History] Edit current entry',
    EDIT_CURRENT_HISTORY_ENTRY_DATA = '[History] Edit current entry data',
    LOCK_CURRENT_HISTORY_ENTRY = '[History] Lock current entry data',
    UNLOCK_CURRENT_HISTORY_ENTRY = '[History] Unlock current entry data',
    SET_CURRENT_HISTORY_ENTRY = '[History] Set current entry',
    LOAD_CURRENT_HISTORY_STATE = '[History] Load current entry state',
    DELETE_LAST_HISTORY_ENTRY = '[History] Delete last entry',
}

export const addHistoryEntry = createAction(HistoryListActionType.ADD_HISTORY_ENTRY, props<{ newEntry: HistoryData }>());
export const editHistoryEntry = createAction(HistoryListActionType.EDIT_HISTORY_ENTRY, props<{ editedEntry: HistoryData }>());
export const editCurrentHistoryEntry = createAction(
    HistoryListActionType.EDIT_CURRENT_HISTORY_ENTRY,
    props<{ editedEntry: Partial<HistoryData> }>()
);
export const editCurrentHistoryEntryData = createAction(
    HistoryListActionType.EDIT_CURRENT_HISTORY_ENTRY_DATA,
    props<{ newVal: any; keyName: string; dataName: string }>()
);
export const lockCurrentHistoryEntry = createAction(HistoryListActionType.LOCK_CURRENT_HISTORY_ENTRY);
export const unlockCurrentHistoryEntry = createAction(HistoryListActionType.UNLOCK_CURRENT_HISTORY_ENTRY);
export const setCurrentHistoryEntry = createAction(HistoryListActionType.SET_CURRENT_HISTORY_ENTRY, props<{ entryId: string }>());
export const loadCurrentHistoryState = createAction(HistoryListActionType.LOAD_CURRENT_HISTORY_STATE, props<{ state: AppState }>());
export const deleteLastHistoryEntry = createAction(HistoryListActionType.DELETE_LAST_HISTORY_ENTRY);
