import { createReducer, on } from '@ngrx/store';
import {
    loadViewSettingsFailure,
    loadViewSettingsRequest,
    loadViewSettingsSuccess,
    updateViewSettings,
} from '../actions/view-settings.actions';
import { initialViewSettingsState, viewSettingsAdapter } from '../state/view-settings.state';

export const viewSettingsReducer = createReducer(
    initialViewSettingsState,
    on(loadViewSettingsRequest, state => ({ ...state, isLoading: true, error: null })),
    on(loadViewSettingsSuccess, (state, { viewSettings }) => {
        if (!!viewSettings) return viewSettingsAdapter.setAll(viewSettings, { ...state, isLoading: false, error: null });
        return { ...state, isLoading: false, error: null };
    }),
    on(loadViewSettingsFailure, (state, { error }) => ({ ...state, isLoading: false, error })),
    on(updateViewSettings, (state, { viewSetting }) => viewSettingsAdapter.upsertOne({ ...viewSetting }, state))
);
