import { createAction, props } from '@ngrx/store';
import { FieldsNameDescriptionI18n } from 'src/app/models/fields_name_description_i18n.interface';
import { NdpiL7Dictionary } from 'src/app/models/ndpil7-dictionary.interface';

export enum DictionaryActionsType {
    LOAD_FIELDS_DICO_REQUEST = '[Fields Dico Api] Load Request',
    LOAD_FIELDS_DICO_SUCCESS = '[Fields Dico Api] Load Success',
    LOAD_FIELDS_DICO_FAILURE = '[Fields Dico Api] Load Failure',

    LOAD_NDPIL7_DICO_REQUEST = '[NDPI L7 Dico Api] Load Request',
    LOAD_NDPIL7_DICO_SUCCESS = '[NDPI L7 Dico Api] Load Success',
    LOAD_NDPIL7_DICO_FAILURE = '[NDPI L7 Dico Api] Load Failure',
}

//#region FI Dico
export const loadFieldsNameDescriptionI18nRequest = createAction(DictionaryActionsType.LOAD_FIELDS_DICO_REQUEST);
export const loadFieldsNameDescriptionI18nSuccess = createAction(
    DictionaryActionsType.LOAD_FIELDS_DICO_SUCCESS,
    props<{ fields_name: FieldsNameDescriptionI18n[] }>()
);
export const loadFieldsNameDescriptionI18nFailure = createAction(DictionaryActionsType.LOAD_FIELDS_DICO_FAILURE, props<{ error: any }>());
//#endregion

//#region NDPI L7
export const loadNdpiL7DictionaryRequest = createAction(DictionaryActionsType.LOAD_NDPIL7_DICO_REQUEST);
export const loadNdpiL7DictionarySuccess = createAction(
    DictionaryActionsType.LOAD_NDPIL7_DICO_SUCCESS,
    props<{ ndpi_l7: NdpiL7Dictionary[] }>()
);
export const loadNdpiL7DictionaryFailure = createAction(DictionaryActionsType.LOAD_NDPIL7_DICO_FAILURE, props<{ error: any }>());
//#endregion
