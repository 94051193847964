import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { filter, take, tap } from 'rxjs/operators';
import { FiltersActionTypes } from '../actions/filters.actions';
import { requestDataRefresh } from '../actions/refresh.actions';
import { FiltersState } from '../state/filters.state';
import { selectActiveFiltersAll } from '../selectors/filters.selectors';
import { AuthActionTypes } from '../actions/auth.actions';
import { UtilsService } from '../../utils/utils.service';

@Injectable()
export class FiltersEffects {
    timeSelectionFilterInit: boolean = false;

    constructor(private readonly actions$: Actions, private readonly store: Store<FiltersState>, private readonly utils: UtilsService) {
        this.timeSelectionFilterInit = false;
    }

    init$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AuthActionTypes.USER_AUTO_LOGGED_IN, AuthActionTypes.LOGIN_SUCCESS),
                filter(() => !this.timeSelectionFilterInit),
                tap(() => {
                    this.timeSelectionFilterInit = true;
                    this.utils.initTemporalFilter();
                })
            ),
        { dispatch: false }
    );

    refreshAfterUpdate$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(
                    FiltersActionTypes.ADD_ACTIVE_FILTER,
                    FiltersActionTypes.REMOVE_ACTIVE_FILTER,
                    FiltersActionTypes.UPDATE_ACTIVE_FILTER,
                    FiltersActionTypes.REMOVE_ACTIVE_FILTER_BY_SCOPE,
                    FiltersActionTypes.RESET_ACTIVE_FILTER
                ),
                tap(action => {
                    this.store
                        .select(selectActiveFiltersAll)
                        .pipe(take(1))
                        .subscribe(f => {
                            this.store.dispatch(requestDataRefresh());
                        });
                })
            ),
        { dispatch: false }
    );
}
