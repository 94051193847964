<div class="overflow-hidden" #barChartContainer *ngIf="!!id">
    <ejs-chart
        #barChart
        id="barChart"
        style="display: block"
        [chartArea]="chartArea"
        [height]="chartHeight"
        [width]="chartWidth"
        id="chartcontainer"
        [primaryXAxis]="primaryXAxis"
        [primaryYAxis]="primaryYAxis"
        [title]="title"
        [tooltip]="tooltip"
        [annotations]="annotations"
        [zoomSettings]="zoomSettings"
        (tooltipRender)="tooltipRender($event)"
    >
        <e-series-collection>
            <e-series pointColorMapping="color" width="1" [dataSource]="data" type="Hilo" xName="x" high="high" low="low"> </e-series>
        </e-series-collection>
    </ejs-chart>
</div>
