import { createReducer, on } from '@ngrx/store';
import { assetsIpsAdapter, initialAssetsIpsState } from '../state/assets-ips.state';
import { loadAssetsIpsRequest, loadAssetsIpsSuccess, loadAssetsIpsFailure } from '../actions/assets-ips.action';

// //#region Ml Model
export const assetsIpsReducer = createReducer(
    initialAssetsIpsState,
    on(loadAssetsIpsRequest, state => ({ ...state, isLoading: true, error: null })),
    on(loadAssetsIpsSuccess, (state, { assetsIps }) => assetsIpsAdapter.setAll(assetsIps, { ...state, isLoading: false, error: null })),
    on(loadAssetsIpsFailure, (state, { error }) => ({ ...state, isLoading: false, error }))
);
// //#endregion
