import { Injectable } from '@angular/core';
import { ToastComponent, ToastUtility } from '@syncfusion/ej2-angular-notifications';

@Injectable({
    providedIn: 'root'
})
export class ToastMessageService {

    constructor() { }

    showErrorMessage(title: string, content: string) {
        this.showMessage(title, content, 'e-toast-danger');
    }

    showSuccessMessage(title: string, content: string) {
        this.showMessage(title, content, 'e-toast-success');
    }

    private showMessage(title: string, content: string, cssClass: string) {
        ToastUtility.show({
            title,
            content,
            timeOut: 5000,
            cssClass,
            position: { X: 'Right' },
            showCloseButton: true,
        }) as ToastComponent;
    }
}
