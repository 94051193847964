import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { HistoryData } from 'src/app/models/history-data.interface';

export const historyListAdapter = createEntityAdapter<HistoryData>({
    selectId: item => item.id,
});

export interface HistoryListState extends EntityState<HistoryData> {
    currentEntry?: string;
}

export const initialHistoryListState: HistoryListState = historyListAdapter.getInitialState();
