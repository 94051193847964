import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ApiResponse } from '../models/api-response.interface';
import { ClassificationTreeNode } from '../models/score-class-node.interface';

@Injectable({
    providedIn: 'root',
})
export class ClassifTreeScoreService {
    constructor(@Inject(LOCALE_ID) protected localeId: string, private readonly http: HttpClient) { }

    getClassifTreeScore() {
        return this.http
            .get<ApiResponse<any[]>>(environment.bckAPI.classifTreeScoreAPIs.classifTreeScoreUrl + '?lang=' + this.localeId)
            .pipe(
                map(response => response.result)
            );
    }
}
