import { createReducer, on } from '@ngrx/store';
import { cloneDeep } from 'lodash';
import {
    addHistoryEntry,
    deleteLastHistoryEntry,
    editCurrentHistoryEntry,
    editCurrentHistoryEntryData,
    editHistoryEntry,
    lockCurrentHistoryEntry,
    setCurrentHistoryEntry,
    unlockCurrentHistoryEntry,
} from '../actions/history-list.action';
import { historyListAdapter, initialHistoryListState } from '../state/history-list.state';

export const historyListReducer = createReducer(
    initialHistoryListState,
    on(addHistoryEntry, (state, { newEntry }) => historyListAdapter.addOne(newEntry, { ...state, currentEntry: newEntry.id })),
    on(editHistoryEntry, (state, { editedEntry }) => {
        if (state.currentEntry) return historyListAdapter.updateOne({ changes: editedEntry, id: editedEntry.id }, state);
        return state;
    }),
    on(editCurrentHistoryEntry, (state, { editedEntry }) => {
        if (state.currentEntry) return historyListAdapter.updateOne({ changes: editedEntry, id: state.currentEntry }, state);
        return state;
    }),
    on(editCurrentHistoryEntryData, (state, { newVal, keyName, dataName }) => {
        if (state.currentEntry) {
            const currentEntry = state.entities[state.currentEntry];
            if (currentEntry && !currentEntry.locked) {
                const newEntryData = cloneDeep(currentEntry.componentData);
                if (newEntryData[dataName]) newEntryData[dataName][keyName] = newVal;
                else newEntryData[dataName] = { [keyName]: newVal };
                return historyListAdapter.updateOne(
                    { changes: { componentData: newEntryData, date: Date.now() }, id: state.currentEntry },
                    state
                );
            }
        }
        return state;
    }),
    on(lockCurrentHistoryEntry, state => {
        if (state.currentEntry) return historyListAdapter.updateOne({ changes: { locked: true }, id: state.currentEntry }, state);
        return state;
    }),
    on(unlockCurrentHistoryEntry, state => {
        if (state.currentEntry) return historyListAdapter.updateOne({ changes: { locked: false }, id: state.currentEntry }, state);
        return state;
    }),
    on(setCurrentHistoryEntry, (state, { entryId }) => ({ ...state, currentEntry: entryId })),
    on(deleteLastHistoryEntry, state => historyListAdapter.removeOne(state.ids[0] as string, state))
);
