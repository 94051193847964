<div class="w-full h-full flex flex-col relative overflow-hidden p-1">
    <div class="flex items-center border-b border-primary-500 py-1">
        <div class="icon small bg-onsurface admin mx-2"></div>
        <h2 class="ml-2 text-header" i18n="@@admin_view-administration_panel">Administration panel</h2>
    </div>
    <div class="bg-surface rounded m-1 border-b border-primary-500 p-1">
        <h2 class="p-2" i18n="@@admin_view-user_management">User management</h2>
        <button
            class="p-1 rounded bg-secondary-500 hover:bg-secondary-600 text-onsecondary disabled:opacity-80 disabled:cursor-not-allowed flex items-center"
            (click)="createUser()"
        >
            <div class="icon small add-user bg-onsecondary mr-2"></div>
            <h6 class="font-bold text-onsecondary" i18n="@@admin_view-create_new_user">Create new user</h6>
        </button>
        <div class="my-2">
            <hypervision-users-list [users]="users" (reloadUsers)="loadUsers()"></hypervision-users-list>
        </div>
    </div>
</div>
