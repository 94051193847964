import { createAction, props } from '@ngrx/store';

export enum MitreTypes {
    LOAD_MITRE_TACTICS_REQUEST = '[Mitre Tactics Api] Load Request',
    LOAD_MITRE_TACTICS_SUCCESS = '[Mitre Tactics Api] Load Success',
    LOAD_MITRE_TACTICS_FAILURE = '[Mitre Tactics Api] Load Failure',

    LOAD_MITRE_TECHNIQUES_REQUEST = '[Mitre Techniques Api] Load Request',
    LOAD_MITRE_TECHNIQUES_SUCCESS = '[Mitre Techniques Api] Load Success',
    LOAD_MITRE_TECHNIQUES_FAILURE = '[Mitre Techniques Api] Load Failure',

    LOAD_MITRE_MITIGATIONS_REQUEST = '[Mitre Mitigations Api] Load Request',
    LOAD_MITRE_MITIGATIONS_SUCCESS = '[Mitre Mitigations Api] Load Success',
    LOAD_MITRE_MITIGATIONS_FAILURE = '[Mitre Mitigations Api] Load Failure',

    LOAD_MITRE_DATASOURCES_REQUEST = '[Mitre Datasources Api] Load Request',
    LOAD_MITRE_DATASOURCES_SUCCESS = '[Mitre Datasources Api] Load Success',
    LOAD_MITRE_DATASOURCES_FAILURE = '[Mitre Datasources Api] Load Failure',

    LOAD_MITRE_GROUPS_REQUEST = '[Mitre Groups Api] Load Request',
    LOAD_MITRE_GROUPS_SUCCESS = '[Mitre Groups Api] Load Success',
    LOAD_MITRE_GROUPS_FAILURE = '[Mitre Groups Api] Load Failure',
}

export const loadMitreTacticsRequest = createAction(MitreTypes.LOAD_MITRE_TACTICS_REQUEST);
export const loadMitreTacticsSuccess = createAction(MitreTypes.LOAD_MITRE_TACTICS_SUCCESS, props<{ mitres: any[] }>());
export const loadMitreTacticsFailure = createAction(MitreTypes.LOAD_MITRE_TACTICS_FAILURE, props<{ error: any }>());

export const loadMitreTechniquesRequest = createAction(MitreTypes.LOAD_MITRE_TECHNIQUES_REQUEST);
export const loadMitreTechniquesSuccess = createAction(MitreTypes.LOAD_MITRE_TECHNIQUES_SUCCESS, props<{ mitres: any[] }>());
export const loadMitreTechniquesFailure = createAction(MitreTypes.LOAD_MITRE_TECHNIQUES_FAILURE, props<{ error: any }>());

export const loadMitreMitigationsRequest = createAction(MitreTypes.LOAD_MITRE_MITIGATIONS_REQUEST);
export const loadMitreMitigationsSuccess = createAction(MitreTypes.LOAD_MITRE_MITIGATIONS_SUCCESS, props<{ mitigations: any[] }>());
export const loadMitreMitigationsFailure = createAction(MitreTypes.LOAD_MITRE_MITIGATIONS_FAILURE, props<{ error: any }>());

export const loadMitreDatasourcesRequest = createAction(MitreTypes.LOAD_MITRE_DATASOURCES_REQUEST);
export const loadMitreDatasourcesSuccess = createAction(MitreTypes.LOAD_MITRE_DATASOURCES_SUCCESS, props<{ datasources: any[] }>());
export const loadMitreDatasourcesFailure = createAction(MitreTypes.LOAD_MITRE_DATASOURCES_FAILURE, props<{ error: any }>());

export const loadMitreGroupsRequest = createAction(MitreTypes.LOAD_MITRE_GROUPS_REQUEST);
export const loadMitreGroupsSuccess = createAction(MitreTypes.LOAD_MITRE_GROUPS_SUCCESS, props<{ groups: any[] }>());
export const loadMitreGroupsFailure = createAction(MitreTypes.LOAD_MITRE_GROUPS_FAILURE, props<{ error: any }>());
