import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';

interface ApiConfig {
    BASE_API_URL: string;
    BASE_NOTICATIONS_API_URL: string;
    BASE_WS_URL: string;
}

export const APP_CONFIGURATION_DONE = 'APP_CONFIGURATION_DONE';

@Injectable({ providedIn: 'root' })
export class EnvConfigurationService {
    constructor(private readonly http: HttpClient) {}

    async load() {
        const conf = await lastValueFrom(this.http.get<ApiConfig>('/assets/config/url.json'));
        if (conf) {
            this.replaceProperties(environment.bckAPI, conf.BASE_API_URL);
            this.replaceProperties(environment.notifsAPI, conf.BASE_NOTICATIONS_API_URL);
        }
    }

    private replaceProperties(obj: object, prefix: string) {
        for (const key of Object.getOwnPropertyNames(obj)) {
            if (typeof obj[key] === 'string') {
                obj[key] = prefix + obj[key];
            } else if (key in obj) {
                // TODO: Trouver d'où vient le problème
                // Théo: Je ne comprends pas pourquoi il y a un unsafe any sur
                // le param obj[key]
                // eslint-disable-next-line
                this.replaceProperties(obj[key], prefix);
            }
        }
    }
}
