import { combineReducers, createReducer, on } from '@ngrx/store';
import {
    assetsColAddMany,
    assetsColAddOne,
    assetsColUpdateMany,
    assetsColUpdateOne,
    assetsColUpsertMany,
    loadAssetsTypeFailureAction,
    loadAssetsTypeRequestAction,
    loadAssetsTypeSuccessAction,
    loadCmdbSiteFailureAction,
    loadCmdbSiteRequestAction,
    loadCmdbSiteSuccessAction,
} from '../actions/assets.actions';
import {
    assetsColAdapter,
    assetsTypeAdapter,
    cmdbSiteAdapter,
    initialAssetsColState,
    initialAssetsStoreState,
    initialAssetsTypeState,
    initialCmdbSiteState,
} from '../state/assets.state';

export const assetsTypeReducer = createReducer(
    initialAssetsTypeState,
    on(loadAssetsTypeRequestAction, state => ({ ...state, isLoading: true, error: null })),
    on(loadAssetsTypeSuccessAction, (state, { types }) => assetsTypeAdapter.setAll(types, { ...state, isLoading: false })),
    on(loadAssetsTypeFailureAction, (state, { error }) => ({ ...state, isLoading: false, error }))
);

export const cmdbSiteReducer = createReducer(
    initialCmdbSiteState,
    on(loadCmdbSiteRequestAction, state => ({ ...state, isLoading: true, error: null })),
    on(loadCmdbSiteSuccessAction, (state, { sites }) => cmdbSiteAdapter.setAll(sites, { ...state, isLoading: false })),
    on(loadCmdbSiteFailureAction, (state, { error }) => ({ ...state, isLoading: false, error }))
);

export const assetsColReducer = createReducer(
    initialAssetsColState,
    on(assetsColUpsertMany, (state, { newCols }) => assetsColAdapter.upsertMany(newCols, state)),
    on(assetsColAddOne, (state, { newCol }) => assetsColAdapter.addOne(newCol, state)),
    on(assetsColAddMany, (state, { newCols }) => assetsColAdapter.addMany(newCols, state)),
    on(assetsColUpdateOne, (state, { updatedCol }) => assetsColAdapter.updateOne({ changes: updatedCol, id: updatedCol.field }, state)),
    on(assetsColUpdateMany, (state, { updatedCols }) =>
        assetsColAdapter.updateMany(
            updatedCols.map(col => ({ changes: col, id: col.field })),
            state
        )
    )
);

export const assetsStoreReducer = combineReducers(
    { sites: cmdbSiteReducer, types: assetsTypeReducer, cols: assetsColReducer },
    initialAssetsStoreState
);
