import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ApiResponse } from '../models/api-response.interface';

@Injectable({
    providedIn: 'root',
})
export class DictionaryService {
    constructor(@Inject(LOCALE_ID) protected localeId: string, private readonly http: HttpClient) { }

    getFeatureImportanceDico() {
        return this.http
            .get<ApiResponse<any[]>>(environment.bckAPI.dictionaryAPIs.fields_nameUrl + '?lang=' + this.localeId)
            .pipe(map(response => response.result));
    }

    getNdpiL7Dico() {
        return this.http
            .get<ApiResponse<any[]>>(environment.bckAPI.dictionaryAPIs.ndpi_l7 + '?lang=' + this.localeId)
            .pipe(map(response => response.result));
    }
}
