import { createReducer, on } from '@ngrx/store';
import { loadAnalyzerFailure, loadAnalyzerRequest, loadAnalyzerSuccess } from '../actions/analyzer.action';
import { analyzerAdapter, initialAnalyzerState } from '../state/analyzer.state';

// //#region Ml Model
export const analyzerReducer = createReducer(
    initialAnalyzerState,
    on(loadAnalyzerRequest, state => ({ ...state, isLoading: true, error: null })),
    on(loadAnalyzerSuccess, (state, { analyzers }) => analyzerAdapter.setAll(analyzers, { ...state, isLoading: false, error: null })),
    on(loadAnalyzerFailure, (state, { error }) => ({ ...state, isLoading: false, error }))
);
// //#endregion
