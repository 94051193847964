import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { AssetsIps } from 'src/app/models/cmdb.interface';

export const assetsIpsAdapter = createEntityAdapter<AssetsIps>({
    selectId: item => item.ip_address,
});

export interface AssetsIpsState extends EntityState<AssetsIps> {
    isLoading: boolean;
    error: any;
}

export const initialAssetsIpsState: AssetsIpsState = assetsIpsAdapter.getInitialState({
    isLoading: false,
    error: null,
});
