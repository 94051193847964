import { createAction, props } from '@ngrx/store';
import { AttackReason } from 'src/app/models/attack-reason.interface';

export enum AttackReasonTypes {
    LOAD_ATTACK_REASON_REQUEST = '[Attack Reason Api] Load Request',
    LOAD_ATTACK_REASON_SUCCESS = '[Attack Reason Api] Load Success',
    LOAD_ATTACK_REASON_FAILURE = '[Attack Reason Api] Load Failure',
}

export const loadAttackReasonRequest = createAction(AttackReasonTypes.LOAD_ATTACK_REASON_REQUEST);
export const loadAttackReasonSuccess = createAction(
    AttackReasonTypes.LOAD_ATTACK_REASON_SUCCESS,
    props<{ attackReason: AttackReason[] }>()
);
export const loadAttackReasonFailure = createAction(AttackReasonTypes.LOAD_ATTACK_REASON_FAILURE, props<{ error: any }>());
