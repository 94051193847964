import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, tap } from 'rxjs/operators';
import { StatusService } from '../../../services/status.service';
import { AuthActionTypes } from '../actions/auth.actions';
import { loadStatusFailure, loadStatusRequest, loadStatusSuccess, StatusActionTypes } from '../actions/status.actions';
import { StatusState } from '../state/status.state';

@Injectable()
export class StatusEffects {
    constructor(private readonly actions$: Actions, private readonly store: Store<StatusState>, private readonly status: StatusService) {}

    init$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AuthActionTypes.USER_AUTO_LOGGED_IN, AuthActionTypes.LOGIN_SUCCESS),
                tap(() => {
                    this.store.dispatch(loadStatusRequest());
                })
            ),
        { dispatch: false }
    );

    loadStatusRequest$ = createEffect(() =>
        this.actions$.pipe(
            ofType(StatusActionTypes.LOAD_STATUS_REQUEST),
            exhaustMap(() =>
                this.status.loadStatusList().pipe(
                    map(status => loadStatusSuccess({ status })),
                    catchError(error => of(loadStatusFailure({ error })))
                )
            )
        )
    );
}
