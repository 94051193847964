<span class="w-full text-center text-onsurface text-sm uppercase border-b border-nonecol">{{ title }}</span>
<div
    #radarContainer
    class="relative flex-auto overflow-hidden flex flex-col items-center justify-center"
    (resized)="onResized($event)"
    *ngIf="!!id"
>
    <ejs-chart
        #{{id}}
        class="flex-auto items-center justify-center flex bg-transparent relative w-full"
        [background]="'transparent'"
        [id]="id"
        [primaryXAxis]="primaryXAxis"
        [primaryYAxis]="primaryYAxis"
        [height]="chartHeight"
        [width]="chartWidth"
        [tooltip]="tooltipSettings"
        [zoomSettings]="zoomSettings"
        [legendSettings]="legendSettings"
    >
        <e-series-collection>
            <e-series
                *ngFor="let d of data"
                [marker]="d.marker"
                [dataSource]="d.data"
                [fill]="d.serieColor"
                [dashArray]="[1, 2]"
                type="Polar"
                xName="x"
                yName="y"
                drawType="Line"
            >
            </e-series>
        </e-series-collection>
    </ejs-chart>
</div>
