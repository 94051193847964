import { createReducer, on } from '@ngrx/store';
import { loadCountryFailure, loadCountryRequest, loadCountrySuccess } from '../actions/country.action';
import { countryAdapter, initialCountryState } from '../state/country.state';

// //#region Ml Model
export const countryReducer = createReducer(
    initialCountryState,
    on(loadCountryRequest, state => ({ ...state, isLoading: true, error: null })),
    on(loadCountrySuccess, (state, { countries }) => countryAdapter.setAll(countries, { ...state, isLoading: false, error: null })),
    on(loadCountryFailure, (state, { error }) => ({ ...state, isLoading: false, error }))
);
// //#endregion
