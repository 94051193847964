import { Component, Input } from '@angular/core';

@Component({
    selector: 'hypervision-date-display',
    templateUrl: './date-display.component.html',
    styleUrls: ['./date-display.component.scss']
})
export class DateDisplayComponent {
    @Input() date: string;
    @Input() tooltip: string;
    @Input() dateFormat: string = 'dd MMM YYYY, HH:mm:ss'
}
