import { FilterOperator } from './filter-operator.enum';
import { FilterType } from '../models/filter-type.enum';
import { TimeSelection } from './time-selection.interface';
import { FilterDashboardType } from './filter-dashboard-type.enum';

type FilterValueType<T> = T extends FilterType.DATE_RANGE
    ? TimeSelection : T extends FilterType.DASHBOARD_DATE_RANGE
    ? FilterDashboardType
    : T extends FilterType.NUMBER
    ? number
    : T extends FilterType.DATE
    ? Date
    : T extends FilterType.STRING
    ? string
    : T extends FilterType.CAT_TYPE
    ? any
    : T extends FilterType.COUNTRY
    ? any
    : T extends FilterType.ANALYZER
    ? any
    : T extends FilterType.QUERY_BUILDER
    ? any
    : T extends FilterType.ATTACK_STATUS
    ? any
    : unknown;

export enum FilterScope {
    global,
    view,
    widget,
}

export interface Filter<T = FilterType> {
    id: string;
    field: string;
    operator: FilterOperator;
    value: FilterValueType<T>;
    type: T;
    isDeletable: boolean;
    isEditable: boolean;
    scope: FilterScope;
    data?: any;
}
