<div class="flex items-center justify-center">
    <div class="flex items-center" *ngIf="ipIsPrivate === true">
        <button
            class="rounded hover:bg-secondary-500/50 e-info h-full border-none relative focus:outline-none font-medium text-sm p-1 text-center inline-flex items-center"
            (click)="goToAssetDashboard(ip)" title="Go to ASSET dashboard">
            <div class="icon small asset bg-secondary-500 m-1"></div>
            <div class="z-10 absolute icon arrow-right xsmall bg-secondary-400 top-5 right-0">
            </div>
        </button>
    </div>
    <div *ngIf="ipIsPrivate === false" class="text-onsurface/50">-</div>
</div>