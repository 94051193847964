import { createAction, props } from '@ngrx/store';
import { ObjectEventAnalyzerDescription } from 'src/app/models/object-event.interface';

export enum ObjectEventAnalyzerDescriptionTypes {
    LOAD_OBJECTEVENTANALYZERDESCRIPTION_REQUEST = '[Object Event Analyzer Description Api] Load Request',
    LOAD_OBJECTEVENTANALYZERDESCRIPTION_SUCCESS = '[Object Event Analyzer Description Api] Load Success',
    LOAD_OBJECTEVENTANALYZERDESCRIPTION_FAILURE = '[Object Event Analyzer Description Api] Load Failure',
}

export const loadObjectEventAnalyzerDescriptionRequest = createAction(ObjectEventAnalyzerDescriptionTypes.LOAD_OBJECTEVENTANALYZERDESCRIPTION_REQUEST);
export const loadObjectEventAnalyzerDescriptionSuccess = createAction(ObjectEventAnalyzerDescriptionTypes.LOAD_OBJECTEVENTANALYZERDESCRIPTION_SUCCESS, props<{ objectEventAnalyzerDescription: ObjectEventAnalyzerDescription[] }>());
export const loadObjectEventAnalyzerDescriptionFailure = createAction(ObjectEventAnalyzerDescriptionTypes.LOAD_OBJECTEVENTANALYZERDESCRIPTION_FAILURE, props<{ error: any }>());
