import { createAction, props } from '@ngrx/store';
import { User } from 'src/app/models/user.interface';

export enum UserActionTypes {
    LOAD_USER_REQUEST = '[User Api] Load Request',
    LOAD_USER_SUCCESS = '[User Api] Load Success',
    LOAD_USER_FAILURE = '[User Api] Load Failure',
}

export const loadUserRequest = createAction(UserActionTypes.LOAD_USER_REQUEST);
export const loadUserSuccess = createAction(UserActionTypes.LOAD_USER_SUCCESS, props<{ user: User[] }>());
export const loadUserFailure = createAction(UserActionTypes.LOAD_USER_FAILURE, props<{ error: any }>());
