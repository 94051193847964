import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ApiResponse } from '../models/api-response.interface';
import { Country } from '../models/country.interface';

@Injectable({
    providedIn: 'root',
})
export class CountryService {
    constructor(@Inject(LOCALE_ID) protected localeId: string, private readonly http: HttpClient) {}

    getCountryList() {
        return this.http
            .get<ApiResponse<Country[]>>(environment.bckAPI.countryAPIs.country_code + '?lang=' + this.localeId)
            .pipe(map(response => response.result));
    }
}
