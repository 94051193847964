import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, tap } from 'rxjs/operators';
import { AuthActionTypes } from '../actions/auth.actions';
import { StatusState } from '../state/status.state';
import { AttacksDataService } from 'src/app/services/attacks-data.service';
import {
    AttackReasonTypes,
    loadAttackReasonFailure,
    loadAttackReasonRequest,
    loadAttackReasonSuccess,
} from '../actions/attack-reason.action';

@Injectable()
export class AttackReasonEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly store: Store<StatusState>,
        private readonly attackService: AttacksDataService
    ) {}

    init$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AuthActionTypes.USER_AUTO_LOGGED_IN, AuthActionTypes.LOGIN_SUCCESS),
                tap(() => {
                    this.store.dispatch(loadAttackReasonRequest());
                })
            ),
        { dispatch: false }
    );

    attackReasonRequest$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AttackReasonTypes.LOAD_ATTACK_REASON_REQUEST),
            exhaustMap(() =>
                this.attackService.getAttackReasonList().pipe(
                    map(attackReason => loadAttackReasonSuccess({ attackReason })),
                    catchError(error => of(loadAttackReasonFailure({ error })))
                )
            )
        )
    );
}
