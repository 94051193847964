import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { DynamicAttributesModule, DynamicIoModule, DynamicModule } from 'ng-dynamic-component';
import { KnubeComponent } from './knube.component';

@NgModule({
    declarations: [KnubeComponent],
    exports: [KnubeComponent],
    imports: [CommonModule, DynamicModule, DynamicIoModule, FormsModule, RouterModule, DynamicAttributesModule],
})
export class KnubeModule {}
