// error-handler.service.ts
import { Injectable } from '@angular/core';
import { ToastComponent, ToastUtility } from '@syncfusion/ej2-angular-notifications';

@Injectable({
    providedIn: 'root'
})
export class ErrorHandlerService {

    constructor() { }

    handleGlobalError(error: any) {
        console.error("Global Error Handler", error);

        const template = `
        <div class="flex flex-col">
            <span class="text-base font-semibold py-1 border-b border-nonecol uppercase">Error</span>
            <div class="flex flex-col p-1">
                <span class="text-sm pb-2">${(error?.error?.error || error?.error?.message || error.message)}</span>
                <span class="text-xs">${error?.url}</span>
            </div>
        </div>
        `

        ToastUtility.show({
            title: 'Error',
            template,
            timeOut: 5000,
            cssClass: 'e-toast-danger',
            position: { X: 'Right' },
            showCloseButton: true,
        }) as ToastComponent;
    }
}
