import { Component, Input } from '@angular/core';

@Component({
    selector: 'hypervision-loading',
    templateUrl: './loading.component.html',
    styleUrls: ['./loading.component.scss'],
})
export class LoadingComponent {
    @Input() diameter = 50;
    @Input() spinClass = '';

    constructor() {}
}
