import {
    ConnectedPosition,
    FlexibleConnectedPositionStrategy,
    GlobalPositionStrategy,
    Overlay,
    OverlayConfig,
    OverlayPositionBuilder,
    OverlayRef,
} from '@angular/cdk/overlay';
import { ComponentPortal, ComponentType } from '@angular/cdk/portal';
import { ElementRef, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { InputsType } from 'ng-dynamic-component';
import { BehaviorSubject } from 'rxjs';
import { setViewBackdrop } from '../shared/store/actions/display-settings.actions';
import { DisplaySettingsState } from '../shared/store/state/display-settings.state';

type ConnectedPositions = 'top' | 'left' | 'bottom' | 'right' | 'bottom-left';
type AvailableConnections = ConnectedPositions | 'center';

@Injectable({
    providedIn: 'root',
})
export class OverlayManagerService {
    private overlayRef: OverlayRef | null = null;

    overlayStatus$ = new BehaviorSubject<boolean>(false);

    constructor(
        private readonly overlay: Overlay,
        private readonly overlayPositionBuilder: OverlayPositionBuilder,
        private readonly store: Store<DisplaySettingsState>
    ) {}

    openOverlay<T = unknown>(
        overlayComponent: ComponentType<T>,
        position: 'center',
        inputs?: InputsType,
        connectedTo?: undefined,
        backdrop?: boolean,
        screenSized?: boolean
    ): T;
    openOverlay<T = unknown>(
        overlayComponent: ComponentType<T>,
        position: ConnectedPositions,
        inputs?: InputsType,
        connectedTo?: ElementRef,
        backdrop?: boolean,
        screenSized?: boolean
    ): T;
    openOverlay<T = unknown>(
        overlayComponent: ComponentType<T>,
        position: AvailableConnections,
        inputs?: InputsType,
        connectedTo?: ElementRef,
        backdrop: boolean = true,
        screenSized: boolean = true
    ): T {
        let overlayConfig = this.getOverlayConfig('center', undefined, backdrop);

        if (position !== 'center' && connectedTo) {
            overlayConfig = this.getOverlayConfig(position, connectedTo, backdrop);
        }

        if (this.overlayRef) {
            this.overlayRef.detach();
        } else {
            this.overlayRef = this.overlay.create(overlayConfig);
        }

        if (screenSized) {
            this.overlayRef.addPanelClass(['w-screen', 'h-screen']);
        }

        const componentPortal = new ComponentPortal(overlayComponent);
        // Attach ComponentPortal to PortalHost
        const ref = this.overlayRef.attach(componentPortal);
        if (inputs) {
            for (const [key, value] of Object.entries(inputs)) {
                (ref.instance as any)[key] = value;
            }
        }
        this.overlayRef.backdropClick().subscribe(_ => this.closeOverlay());

        this.overlayStatus$.next(true);

        return ref.instance;
    }

    closeOverlay() {
        if (this.overlayRef) {
            this.overlayRef.detach();
            this.overlayRef.dispose();
        }
        this.overlayRef = null;
        this.store.dispatch(setViewBackdrop({ backdropState: false }));
        this.overlayStatus$.next(false);
    }

    private getPositionStrategy(position: ConnectedPositions): ConnectedPosition {
        switch (position) {
            case 'top':
                return {
                    originX: 'center',
                    originY: 'top',
                    overlayX: 'center',
                    overlayY: 'bottom',
                };
            case 'left':
                return {
                    originX: 'start',
                    originY: 'center',
                    overlayX: 'end',
                    overlayY: 'center',
                };
            case 'bottom':
                return {
                    originX: 'center',
                    originY: 'bottom',
                    overlayX: 'center',
                    overlayY: 'top',
                };
            case 'bottom-left':
                return {
                    originX: 'start',
                    originY: 'bottom',
                    overlayX: 'start',
                    overlayY: 'top',
                };
            case 'right':
            default:
                return {
                    originX: 'end',
                    originY: 'center',
                    overlayX: 'start',
                    overlayY: 'center',
                };
        }
    }

    private getOverlayConfig(position: 'center', connectedTo?: undefined, backdrop?: boolean): OverlayConfig;
    private getOverlayConfig(position: ConnectedPositions, connectedTo: ElementRef, backdrop?: boolean): OverlayConfig;
    private getOverlayConfig(position: AvailableConnections, connectedTo?: ElementRef, backdrop: boolean = true): OverlayConfig {
        let positionStrategy: GlobalPositionStrategy | FlexibleConnectedPositionStrategy = this.overlay
            .position()
            .global()
            .centerHorizontally()
            .centerVertically();

        if (position !== 'center' && connectedTo == undefined) {
            throw new Error("'connectedTo' argument required when using positions 'top' | 'left' | 'bottom | 'right'");
        }

        if (connectedTo && position !== 'center') {
            positionStrategy = this.overlayPositionBuilder
                .flexibleConnectedTo(connectedTo)
                .withPositions([this.getPositionStrategy(position)]);
        }

        return new OverlayConfig({
            hasBackdrop: backdrop,
            backdropClass: backdrop ? 'bg-black/50' : '',
            scrollStrategy: this.overlay.scrollStrategies.block(),
            positionStrategy,
        });
    }
}
