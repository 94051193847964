import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, tap } from 'rxjs/operators';
import { AdminService } from 'src/app/admin/services/admin.service';
import { AuthActionTypes } from '../actions/auth.actions';
import { loadUserFailure, loadUserRequest, loadUserSuccess, UserActionTypes } from '../actions/user.actions';
import { UserState } from '../state/user.state';

@Injectable()
export class UserEffects {
    constructor(private readonly actions$: Actions, private readonly store: Store<UserState>, private readonly adminService: AdminService) { }

    init$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AuthActionTypes.USER_AUTO_LOGGED_IN, AuthActionTypes.LOGIN_SUCCESS),
                tap(() => {
                    this.store.dispatch(loadUserRequest());
                })
            ),
        { dispatch: false }
    );

    loadUserRequest$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserActionTypes.LOAD_USER_REQUEST),
            exhaustMap(() =>
                this.adminService.getUsersList().pipe(
                    map(user => loadUserSuccess({ user })),
                    catchError(error => of(loadUserFailure({ error })))
                )
            )
        )
    );
}
