

export enum CmdbTypeEnum {
    AGGREGATE = 0,
    ROUTER = 1,
    PC = 2,
    INTERFACE = 3,
    ROOT = 4,
    UNKNOWN_NETWORKS = 5,
    CMDB = 6,
}

export interface CmdbAsset {
    id: string;
    name: string;
    id_site: string;
    id_asset_type: number;
    interfaces: CmdbInterface[];
    site: CmdbSite;
    last_seen: string;
}

export interface CmdbInterface {
    id: string;
    id_asset?: string;
    ip_address: string;
    mac: string;
    name: string;
    network: string;
    ip_gateway: string;
    ip_mask: string;
    last_seen?: string;
    is_public?: boolean;
    use_dhcp?: boolean;
    asset_behavior_cluster?: number;
}

export interface CmdbSite {
    id: string;
    site_long_name: string;
    address: string;
    latitude: number;
    longitude: number;
    id_country_code?: number;
    city_name: string;
    is_default?: boolean;
}

export interface CmdbType {
    id: number;
    is_locked?: boolean;
}

export interface CmdbTypeI18n {
    id: number;
    is_locked?: boolean;
    text: string;
    lang: string;
}

export interface NetworkNode {
    id: string;
    type: CmdbTypeEnum;
    parent_id?: string;
    name: string;
    id_site?: string;
    ip_address: string;
    ip_gateway: string;
    ip_mask: string;
    network: string;
    mac: string;
    ips?: string[];
    depth?: number;
}

export interface NetworkForceGraphNode extends NetworkNode {
    expanded: boolean;
    collapsed: boolean;
    childLinks: NetworkForceGraphNodeLink[];
    parentLink: NetworkForceGraphNodeLink;
}

export interface NetworkNodeTreegrid extends NetworkNode {
    Children?: NetworkNode[];
    childRecords?: any[];
    parentItem?: NetworkNodeTreegrid;
}

export interface NetworkForceGraphNodeLink {
    source: string;
    target: string;
    curvature: number;
    color: string;
    width: number;
    particles_count: number;
}

export interface SrcDstCounter {
    as_src: { [key: string]: number };
    as_dst: { [key: string]: number };
}

export interface SuricataStat {
    classif_tree_score_event_id: number;
    analyzer_details_id: number;
    classif_score: number;
    msg: string;
    count: number;
}

export interface SuricataSrcDst {
    as_src: SuricataStat[];
    as_dst: SuricataStat[];
}

export interface AssetsIps {
    asset_name: string;
    ip_address: string;
    asset_id: string;
    asset_type: number;
}
