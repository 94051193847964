import { CdkAccordionModule } from '@angular/cdk/accordion';
import { OverlayModule } from '@angular/cdk/overlay';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule, CheckBoxModule, RadioButtonModule, SwitchModule } from '@syncfusion/ej2-angular-buttons';
import { ChartAllModule } from '@syncfusion/ej2-angular-charts';
import { CircularGaugeModule } from '@syncfusion/ej2-angular-circulargauge';
import { ComboBoxModule, DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';
import { GridModule } from '@syncfusion/ej2-angular-grids';
import { ColorPickerModule, TextBoxModule } from '@syncfusion/ej2-angular-inputs';
import { SplitterModule } from '@syncfusion/ej2-angular-layouts';
import { AccordionModule, TabModule, ToolbarModule, TreeViewModule } from '@syncfusion/ej2-angular-navigations';
import { ToastModule } from '@syncfusion/ej2-angular-notifications';
import { TooltipModule } from '@syncfusion/ej2-angular-popups';
import { ProgressBarModule } from '@syncfusion/ej2-angular-progressbar';
import { TreeGridModule } from '@syncfusion/ej2-angular-treegrid';
import { VirtualScrollerModule } from 'ngx-virtual-scroller';
import { BarChartComponent } from './components/charts/bar-chart/bar-chart.component';
import { RadarChartComponent } from './components/charts/radar-chart/radar-chart.component';
import { ScatterChartComponent } from './components/charts/scatter-chart/scatter-chart.component';
import { ScatterPlotComponent } from './components/charts/scatter-plot/scatter-plot.component';
import { LoadingComponent } from './components/loading/loading.component';
import { ClassificationDisplayComponent } from './components/table-display/classification-display/classification-display.component';
import { DateDisplayComponent } from './components/table-display/date-display/date-display.component';
import { IpTableDisplayComponent } from './components/table-display/ip-table-display/ip-table-display.component';
import { IsPrivateIpTableDisplayComponent } from './components/table-display/is-private-ip-table-display/is-private-ip-table-display.component';
import { MitreTacticDisplayComponent } from './components/table-display/mitre-tactic-display/mitre-tactic-display.component';
import { MultipleAnalyzersDisplayComponent } from './components/table-display/multiple-analyzers-display/multiple-analyzers-display.component';
import { ScoreDisplayComponent } from './components/table-display/score-display/score-display.component';
import { StatusDisplayComponent } from './components/table-display/status-display/status-display.component';
import { AutoFocusDirective } from './directives/auto-focus.directive';
import { ResizeObserverDirective } from './directives/resize-observer.directive';
import { KnubeModule } from './modules/knube/knube.module';
import { ClonePipe } from './pipes/clone.pipe';
import { NoSanitizePipe } from './pipes/no-sanitize.pipe';

const importExportComponents = [
    ResizeObserverDirective,
    AutoFocusDirective,
    ClonePipe,
    NoSanitizePipe,
    LoadingComponent,
    RadarChartComponent,
    ScatterPlotComponent,
    BarChartComponent,
    IpTableDisplayComponent,
    IsPrivateIpTableDisplayComponent,
    StatusDisplayComponent,
    MultipleAnalyzersDisplayComponent,
    ScoreDisplayComponent,
    MitreTacticDisplayComponent,
    ClassificationDisplayComponent,
    DateDisplayComponent,
    ScatterChartComponent
];
const importExportModules = [
    CommonModule,
    AccordionModule,
    CdkAccordionModule,
    DropDownListModule,
    FormsModule,
    ReactiveFormsModule,
    TooltipModule,
    ToolbarModule,
    TabModule,
    SplitterModule,
    ChartAllModule,
    CircularGaugeModule,
    ScrollingModule,
    CheckBoxModule,
    GridModule,
    TreeGridModule,
    TreeViewModule,
    KnubeModule,
    ToastModule,
    SwitchModule,
    ButtonModule,
    RadioButtonModule,
    OverlayModule,
    ColorPickerModule,
    ComboBoxModule,
    TextBoxModule,
    VirtualScrollerModule
];

@NgModule({
    declarations: [...importExportComponents],
    imports: [...importExportModules, ProgressBarModule],
    exports: [...importExportModules, ...importExportComponents],
})
export class SharedModule { }
