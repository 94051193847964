import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { Country } from 'src/app/models/country.interface';

export const countryAdapter = createEntityAdapter<Country>({
    selectId: item => item.id,
});

export interface CountryState extends EntityState<Country> {
    isLoading: boolean;
    error: any;
}

export const initialCountryState: CountryState = countryAdapter.getInitialState({
    isLoading: false,
    error: null,
});
