import { inject } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { Dictionary } from '@ngrx/entity';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, Subscription, filter, first, lastValueFrom, startWith, take } from 'rxjs';
import { selectActiveFiltersAll } from 'src/app/shared/store/selectors/filters.selectors';
import { Analyzer } from '../models/analyzer.interface';
import { Country } from '../models/country.interface';
import { Criticity } from '../models/criticity.interface';
import { Filter } from '../models/filter.interface';
import { NdpiL7Dictionary } from '../models/ndpil7-dictionary.interface';
import { ClassificationTreeNode } from '../models/score-class-node.interface';
import { User } from '../models/user.interface';
import { ToastMessageService } from '../services/toast-message.service';
import { RefreshActionTypes, requestDataRefresh } from '../shared/store/actions/refresh.actions';
import { selectAnalyzerEntities } from '../shared/store/selectors/analyzer.selectors';
import { selectAuthUser } from '../shared/store/selectors/auth.selectors';
import { selectCountryEntities } from '../shared/store/selectors/country.selectors';
import { selectNdpiL7DictionaryEntities } from '../shared/store/selectors/dictionary.selectors';
import { selectClassificationTreeEntities } from '../shared/store/selectors/scoring.selectors';
import { selectCriticity } from '../shared/store/selectors/settings.selectors';
import { AppState } from '../shared/store/state/app.state';
import { UtilsService } from '../shared/utils/utils.service';
import { ObjectEventAnalyzerDescription } from '../models/object-event.interface';
import { selectObjectEventAnalyzerDescriptionStateEntities } from '../shared/store/selectors/object-event-analyzer-description.selectors';
import { selectUserEntities } from '../shared/store/selectors/user.selectors';

export abstract class HypervisionAbstractBase {
    private filters$: Observable<Filter[]>;
    filters: Filter[];
    private classificationTree$: Observable<Dictionary<ClassificationTreeNode>>;
    classificationTree: Dictionary<ClassificationTreeNode>;
    private analyzers$: Observable<Dictionary<Analyzer>>;
    analyzers: Dictionary<Analyzer>;
    private criticity$: Observable<Criticity>;
    criticity: Criticity;
    private currentUser$: Observable<User | null>;
    currentUser: User | null;
    private countries$: Observable<Dictionary<Country>>;
    countries: Dictionary<Country>;
    private ndpiL7$: Observable<Dictionary<NdpiL7Dictionary>>;
    ndpiL7: Dictionary<NdpiL7Dictionary>;
    private analyzerDescription$: Observable<Dictionary<ObjectEventAnalyzerDescription>>;
    analyzerDescription: Dictionary<ObjectEventAnalyzerDescription>;

    protected abstract onDataRefresh(): void;

    protected store = inject(Store<AppState>);
    protected actions$ = inject(Actions);
    public readonly utils = inject(UtilsService);
    public readonly toastMessageService = inject(ToastMessageService);

    protected readonly subscriptions: Subscription[] = [];

    isInitDone = new BehaviorSubject(false);

    protected async onInit() {
        this.filters$ = this.store.select(selectActiveFiltersAll).pipe(
            filter(v => !!v && v.length > 0),
            first()
        );
        this.classificationTree$ = this.store.select(selectClassificationTreeEntities).pipe(
            filter(v => !!v && Object.keys(v).length > 0),
            first()
        );
        this.analyzers$ = this.store.select(selectAnalyzerEntities).pipe(
            filter(v => !!v && Object.keys(v).length > 0),
            first()
        );
        this.criticity$ = this.store.select(selectCriticity).pipe(
            filter(v => !!v && Object.keys(v).length > 0),
            first()
        );
        this.currentUser$ = this.store.select(selectAuthUser).pipe(
            filter(v => !!v),
            first()
        );
        this.countries$ = this.store.select(selectCountryEntities).pipe(
            filter(v => !!v),
            first()
        );
        this.ndpiL7$ = this.store.select(selectNdpiL7DictionaryEntities).pipe(
            filter(v => !!v),
            first()
        );
        this.analyzerDescription$ = this.store.select(selectObjectEventAnalyzerDescriptionStateEntities).pipe(
            filter(v => !!v),
            first()
        );

        this.filters = await lastValueFrom(this.filters$);
        this.classificationTree = await lastValueFrom(this.classificationTree$);
        this.analyzers = await lastValueFrom(this.analyzers$);
        this.criticity = await lastValueFrom(this.criticity$);
        this.currentUser = await lastValueFrom(this.currentUser$);
        this.countries = await lastValueFrom(this.countries$);
        this.ndpiL7 = await lastValueFrom(this.ndpiL7$);
        this.analyzerDescription = await lastValueFrom(this.analyzerDescription$);
    }

    protected onDestroy(): void {
        for (const sub of this.subscriptions) {
            sub.unsubscribe();
        }
        this.isInitDone.complete();
    }

    protected async getNewStoreValues() {
        this.filters = await lastValueFrom(this.filters$);
    }

    protected bindDataRefresh() {
        this.subscriptions.push(
            this.actions$
                .pipe(
                    startWith(requestDataRefresh),
                    ofType(RefreshActionTypes.REQUEST_DATA_REFRESH),
                    filter(action => typeof action === 'object')
                )
                .subscribe(async () => {
                    await this.getNewStoreValues();

                    this.onDataRefresh();
                })
        );
    }
}
