import { createAction, props } from '@ngrx/store';
import { AvailableFilter } from 'src/app/models/available-filter.interface';
import { Filter, FilterScope } from '../../../models/filter.interface';

export enum FiltersActionTypes {
    // Active Filters
    ADD_ACTIVE_FILTER = '[Active Filter] Add Filter',
    REMOVE_ACTIVE_FILTER = '[Active Filter] Remove Filter',
    REMOVE_ACTIVE_FILTER_BY_SCOPE = '[Active Filter] Remove Filters by scope',
    REMOVE_ACTIVE_FILTER_BY_SCOPE_NAV = '[Active Filter] Remove Filters by scope for navigation',
    UPDATE_ACTIVE_FILTER = '[Active Filter] Update Filter',
    RESET_ACTIVE_FILTER = '[Active Filter] Reset Filters',
    SET_ACTIVE_FILTER_EDITION_ID = '[Active Filter] Set Edition ID',

    // Available Filters
    SET_AVAILABLE_FILTERS = '[Available Filter] Set Available Filters',
}

//#region Active Filters
export const addActiveFilter = createAction(FiltersActionTypes.ADD_ACTIVE_FILTER, props<{ newFilter: Filter }>());
export const removeActiveFilter = createAction(FiltersActionTypes.REMOVE_ACTIVE_FILTER, props<{ id: string }>());
export const removeActiveFilterByScope = createAction(FiltersActionTypes.REMOVE_ACTIVE_FILTER_BY_SCOPE, props<{ scope: FilterScope }>());
export const removeActiveFilterByScopeNav = createAction(
    FiltersActionTypes.REMOVE_ACTIVE_FILTER_BY_SCOPE_NAV,
    props<{ scope: FilterScope }>()
);
export const updateActiveFilter = createAction(FiltersActionTypes.UPDATE_ACTIVE_FILTER, props<{ updatedFilter: Filter }>());
export const resetActiveFilter = createAction(FiltersActionTypes.RESET_ACTIVE_FILTER);
export const setActiveFilterEditionId = createAction(
    FiltersActionTypes.SET_ACTIVE_FILTER_EDITION_ID,
    props<{ editionId: string | null }>()
);
//#endregion

//#region Available Filters
export const setAvailableFilters = createAction(FiltersActionTypes.SET_AVAILABLE_FILTERS, props<{ availableFilters: AvailableFilter[] }>());
//#endregion
