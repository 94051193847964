import { createReducer, on } from '@ngrx/store';
import { loadAttackReasonFailure, loadAttackReasonRequest, loadAttackReasonSuccess } from '../actions/attack-reason.action';
import { attackReasonAdapter, initialAttackReasonState } from '../state/attack-reason.state';

// //#region Ml Model
export const attackReasonReducer = createReducer(
    initialAttackReasonState,
    on(loadAttackReasonRequest, state => ({ ...state, isLoading: true, error: null })),
    on(loadAttackReasonSuccess, (state, { attackReason }) =>
        attackReasonAdapter.setAll(attackReason, { ...state, isLoading: false, error: null })
    ),
    on(loadAttackReasonFailure, (state, { error }) => ({ ...state, isLoading: false, error }))
);
// //#endregion
