import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { FieldsNameDescriptionI18n } from 'src/app/models/fields_name_description_i18n.interface';
import { NdpiL7Dictionary } from 'src/app/models/ndpil7-dictionary.interface';


//#region Feature Importance Dictionary

export const fieldsNameDescriptionI18nAdapter = createEntityAdapter<FieldsNameDescriptionI18n>({
    selectId: item => item.key,
});
export interface FieldsNameDescriptionI18nState extends EntityState<FieldsNameDescriptionI18n> {
    isLoading: boolean;
    error: any;
}
export const initialFieldsNameDescriptionI18nState: FieldsNameDescriptionI18nState = fieldsNameDescriptionI18nAdapter.getInitialState(
    {
        isLoading: false,
        error: null,
    }
);

//#endregion

//#region NDPI L7 Dictionary

export const ndpiL7DictionaryAdapter = createEntityAdapter<NdpiL7Dictionary>({
    selectId: item => item.id,
});
export interface NdpiL7DictionaryState extends EntityState<NdpiL7Dictionary> {
    isLoading: boolean;
    error: any;
}
export const initialNdpiL7DictionaryState: NdpiL7DictionaryState = ndpiL7DictionaryAdapter.getInitialState({
    isLoading: false,
    error: null,
});

//#endregion

export interface DictionaryState {
    fields_name: FieldsNameDescriptionI18nState;
    ndpi_l7: NdpiL7DictionaryState;
}

export const initialDictionaryState: DictionaryState = {
    fields_name: initialFieldsNameDescriptionI18nState,
    ndpi_l7: initialNdpiL7DictionaryState,
};
