import { combineReducers, createReducer, on } from '@ngrx/store';
import { Filter } from 'src/app/models/filter.interface';
import {
    addActiveFilter,
    removeActiveFilter,
    removeActiveFilterByScope,
    removeActiveFilterByScopeNav,
    resetActiveFilter,
    setActiveFilterEditionId,
    setAvailableFilters,
    updateActiveFilter,
} from '../actions/filters.actions';
import {
    activeFiltersAdapter,
    availableFiltersAdapter,
    initialActiveFiltersState,
    initialAvailableFiltersState,
    initialFiltersState,
} from '../state/filters.state';

//#region Active Filters
export const activeFiltersReducer = createReducer(
    initialActiveFiltersState,
    on(addActiveFilter, (state, { newFilter }) => activeFiltersAdapter.addOne(newFilter, state)),
    on(removeActiveFilter, (state, { id }) => activeFiltersAdapter.removeOne(id, state)),
    on(removeActiveFilterByScope, (state, { scope }) => {
        if (state.entities) {
            const filterIdsToRemove = Object.values(state.entities)
                .filter((f): f is Filter => f != undefined && f.scope === scope)
                .map(f => f.id);
            return activeFiltersAdapter.removeMany(filterIdsToRemove, state);
        }

        return { ...state };
    }),
    on(removeActiveFilterByScopeNav, (state, { scope }) => {
        if (state.entities) {
            const filterIdsToRemove = Object.values(state.entities)
                .filter((f): f is Filter => f != undefined && f.scope === scope)
                .map(f => f.id);
            return activeFiltersAdapter.removeMany(filterIdsToRemove, state);
        }

        return { ...state };
    }),
    on(updateActiveFilter, (state, { updatedFilter }) =>
        activeFiltersAdapter.updateOne({ id: updatedFilter.id, changes: { ...updatedFilter } }, state)
    ),
    on(resetActiveFilter, state => initialActiveFiltersState),
    on(setActiveFilterEditionId, (state, { editionId }) => ({ ...state, editionId }))
);
//#endregion

//#region Available Filters
export const availableFiltersReducer = createReducer(
    initialAvailableFiltersState,
    on(setAvailableFilters, (state, { availableFilters }) => availableFiltersAdapter.setAll(availableFilters, state))
);
//#endregion

export const filtersReducer = combineReducers(
    {
        activeFilters: activeFiltersReducer,
        availableFilters: availableFiltersReducer,
    },
    initialFiltersState
);
