import { combineReducers, createReducer, on } from '@ngrx/store';
import {
    loadMitreMitigationsFailure,
    loadMitreMitigationsRequest,
    loadMitreMitigationsSuccess,
    loadMitreDatasourcesFailure,
    loadMitreDatasourcesRequest,
    loadMitreDatasourcesSuccess,
    loadMitreGroupsFailure,
    loadMitreGroupsRequest,
    loadMitreGroupsSuccess,
    loadMitreTacticsRequest,
    loadMitreTacticsSuccess,
    loadMitreTacticsFailure,
    loadMitreTechniquesRequest,
    loadMitreTechniquesSuccess,
    loadMitreTechniquesFailure
} from '../actions/mitre.action';
import {
    initialMitreDatasourcesState,
    initialMitreTacticsState, initialMitreTechniquesState, mitreTechniquesAdapter,
    initialMitreMitigationsState,
    initialMitreState,
    mitreTacticsAdapter,
    mitreMitigationsAdapter,
    mitreDatasourcesAdapter,
    initialMitreGroupsState,
    mitreGroupsAdapter,
} from '../state/mitre.state';

//----------------------------------------------------------------------------------------------------------
export const mitreTacticsReducer = createReducer(
    initialMitreTacticsState,
    on(loadMitreTacticsRequest, state => ({ ...state, isLoading: true, error: null })),
    on(loadMitreTacticsSuccess, (state, { mitres }) => mitreTacticsAdapter.setAll(mitres, { ...state, isLoading: false, error: null })),
    on(loadMitreTacticsFailure, (state, { error }) => ({ ...state, isLoading: false, error }))
);

//----------------------------------------------------------------------------------------------------------
export const mitreTechniquesReducer = createReducer(
    initialMitreTechniquesState,
    on(loadMitreTechniquesRequest, state => ({ ...state, isLoading: true, error: null })),
    on(loadMitreTechniquesSuccess, (state, { mitres }) => mitreTechniquesAdapter.setAll(mitres, { ...state, isLoading: false, error: null })),
    on(loadMitreTechniquesFailure, (state, { error }) => ({ ...state, isLoading: false, error }))
);

//----------------------------------------------------------------------------------------------------------
export const mitreMitigationsReducer = createReducer(
    initialMitreMitigationsState,
    on(loadMitreMitigationsRequest, state => ({ ...state, isLoading: true, error: null })),
    on(loadMitreMitigationsSuccess, (state, { mitigations }) =>
        mitreMitigationsAdapter.setAll(mitigations, { ...state, isLoading: false, error: null })
    ),
    on(loadMitreMitigationsFailure, (state, { error }) => ({ ...state, isLoading: false, error }))
);

//----------------------------------------------------------------------------------------------------------
export const mitreDatasourcesReducer = createReducer(
    initialMitreDatasourcesState,
    on(loadMitreDatasourcesRequest, state => ({ ...state, isLoading: true, error: null })),
    on(loadMitreDatasourcesSuccess, (state, { datasources }) =>
        mitreDatasourcesAdapter.setAll(datasources, { ...state, isLoading: false, error: null })
    ),
    on(loadMitreDatasourcesFailure, (state, { error }) => ({ ...state, isLoading: false, error }))
);

//----------------------------------------------------------------------------------------------------------
export const mitreGroupsReducer = createReducer(
    initialMitreGroupsState,
    on(loadMitreGroupsRequest, state => ({ ...state, isLoading: true, error: null })),
    on(loadMitreGroupsSuccess, (state, { groups }) => mitreGroupsAdapter.setAll(groups, { ...state, isLoading: false, error: null })),
    on(loadMitreGroupsFailure, (state, { error }) => ({ ...state, isLoading: false, error }))
);

export const mitreReducer = combineReducers(
    { tactics: mitreTacticsReducer, techniques: mitreTechniquesReducer, mitigations: mitreMitigationsReducer, datasources: mitreDatasourcesReducer, groups: mitreGroupsReducer },
    initialMitreState
);
