import { createAction, props } from '@ngrx/store';
import { Analyzer } from 'src/app/models/analyzer.interface';

export enum AnalyzerTypes {
    LOAD_ANALYZER_REQUEST = '[Analyzer Api] Load Request',
    LOAD_ANALYZER_SUCCESS = '[Analyzer Api] Load Success',
    LOAD_ANALYZER_FAILURE = '[Analyzer Api] Load Failure',
}

export const loadAnalyzerRequest = createAction(AnalyzerTypes.LOAD_ANALYZER_REQUEST);
export const loadAnalyzerSuccess = createAction(AnalyzerTypes.LOAD_ANALYZER_SUCCESS, props<{ analyzers: Analyzer[] }>());
export const loadAnalyzerFailure = createAction(AnalyzerTypes.LOAD_ANALYZER_FAILURE, props<{ error: any }>());
