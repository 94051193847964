import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { Status } from '../../../models/status.interface';

export const statusAdapter = createEntityAdapter<Status>({
    selectId: item => item.id,
});

export interface StatusState extends EntityState<Status> {
    isLoading: boolean;
    error: any;
}

export const initialStatusState: StatusState = statusAdapter.getInitialState({
    isLoading: false,
    error: null,
});
