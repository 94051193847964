import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { RouterNavigatedAction, ROUTER_NAVIGATED } from '@ngrx/router-store';
import { Store } from '@ngrx/store';
import { map, tap } from 'rxjs/operators';
import { BreadcrumbItem } from 'src/app/models/breadcrumb-item.interface';
import { setBreadcrumb } from '../actions/breadcrumb.actions';
import { AppState } from '../state/app.state';

@Injectable()
export class RouterEffects {
    constructor(private readonly actions$: Actions, private readonly store: Store<AppState>, private readonly router: Router) { }

    routerNavigate$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(ROUTER_NAVIGATED),
                map((action: RouterNavigatedAction) => action.payload),
                tap(payload => {
                    const breads = this.getBreadsFromUrl(payload.event.urlAfterRedirects);

                    this.store.dispatch(setBreadcrumb({ breads }));
                })
            ),
        { dispatch: false }
    );

    private getBreadsFromUrl(url: string) {
        const urlSplit = url.split('/').filter(v => !!v);
        const breads: BreadcrumbItem[] = Array.from(urlSplit, (v, k) => ({
            id: k + '',
            text: this.titleCase(v),
            url: url,
        }));

        return breads;
    }
    // private getBreadsFromUrl(url: string) {
    //     const urlSplit = url.split('/').filter(v => !!v);
    //     const breads: BreadcrumbItem[] = Array.from(urlSplit, (v, k) => ({
    //         id: k + '',
    //         text: decodeURI(v),
    //         url: url,
    //     }));

    //     console.log("getBreadsFromUrl", url);
    //     console.log("breads", breads);

    //     return breads;
    // }

    private titleCase(str: string): string {
        return str
            .toLowerCase()
            .split(' ')
            .map(word => {
                return word.charAt(0).toUpperCase() + word.slice(1);
            })
            .join(' ');
    }
}
