import { createReducer, on } from '@ngrx/store';
import { setFreshLogin, loginFailure, loginRequest, loginSuccess, logoutFailure, logoutRequest, logoutSuccess, tokenRefreshRequest, tokenRefreshSuccess } from '../actions/auth.actions';
import { initialAuthState } from '../state/auth.state';
import { tokenRefreshFailure } from './../actions/auth.actions';

export const authReducer = createReducer(
    initialAuthState,
    on(loginRequest, state => ({ ...state, pending: true, error: null })),
    on(loginSuccess, (state, { type, ...tokensAndUser }) => ({ ...initialAuthState, ...tokensAndUser })),
    on(loginFailure, (state, { error }) => ({ ...state, error, pending: false })),
    on(logoutRequest, state => ({ ...state, pending: true })),
    on(logoutSuccess, state => ({ ...initialAuthState })),
    on(logoutFailure, (state, { error }) => ({ ...state, error, pending: false })),
    on(tokenRefreshRequest, state => ({ ...state, pending: true })),
    on(tokenRefreshSuccess, (state, { type, ...tokens }) => ({ ...state, ...tokens, pending: false })),
    on(tokenRefreshFailure, (state, { error }) => ({ ...initialAuthState, error, pending: false })),
    on(setFreshLogin, (state, { freshLogin }) => ({ ...state, freshLogin }))
);
