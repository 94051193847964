import { createAction, props } from '@ngrx/store';
import { ClassificationTreeNode } from '../../../models/score-class-node.interface';

export enum ScoringActionTypes {
    LOAD_CLASSIFICATION_TREE_REQUEST = '[Classification Tree Api] Load Request',
    LOAD_CLASSIFICATION_TREE_SUCCESS = '[Classification Tree Api] Load Success',
    LOAD_CLASSIFICATION_TREE_FAILURE = '[Classification Tree Api] Load Failure',

    SET_MAX_BOOST_THREAT = '[Scoring] Set max boost threat',
    SET_MAX_BOOST_ALERT = '[Scoring] Set max boost alert',
    SET_MAX_INIT_THREAT = '[Scoring] Set max init threat',
    SET_MAX_INIT_ALERT = '[Scoring] Set max init alert',
}

//#region Classification Tree
export const loadClassificationTreeRequest = createAction(ScoringActionTypes.LOAD_CLASSIFICATION_TREE_REQUEST);
export const loadClassificationTreeSuccess = createAction(
    ScoringActionTypes.LOAD_CLASSIFICATION_TREE_SUCCESS,
    props<{ scoreClassTree: ClassificationTreeNode[] }>()
);
export const loadClassificationTreeFailure = createAction(ScoringActionTypes.LOAD_CLASSIFICATION_TREE_FAILURE, props<{ error: any }>());
//#endregion

//#region Max Boost and Init
export const setMaxBoostThreat = createAction(ScoringActionTypes.SET_MAX_BOOST_THREAT, props<{ newValue: number }>());
export const setMaxBoostAlert = createAction(ScoringActionTypes.SET_MAX_BOOST_ALERT, props<{ newValue: number }>());
export const setMaxInitThreat = createAction(ScoringActionTypes.SET_MAX_INIT_THREAT, props<{ newValue: number }>());
export const setMaxInitAlert = createAction(ScoringActionTypes.SET_MAX_INIT_ALERT, props<{ newValue: number }>());
//#endregion
