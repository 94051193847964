export const apiUrls = {
    bckAPI: {
        baseUrl: '',
        versionApi: 'version/',
        authAPIs: {
            loginUrl: 'auth/login',
            logoutUrl: 'auth/logout',
            refreshUrl: 'auth/refresh',
        },
        adminAPIs: {
            usersUrl: 'admin/users/',
            userUrl: 'admin/user/',
        },
        userAPIs: {
            dataUrl: 'user/data/',
            passwordUrl: 'user/password/',
        },
        whoisAPIs: {
            whoisUrl: 'whois/',
        },
        cmdbAPIs: {
            cmdbUrl: 'cmdb/',
            cmdbSubgraphUrl: 'cmdb/subgraph/',
            cmdbTypeUrl: 'cmdb/type/',
            cmdbSiteUrl: 'cmdb/site/',
            filterFieldsUrl: 'cmdb/filter_fields/',
        },
        cmdbAssetAPIs: {
            cmdbAssetUrl: 'cmdb/asset/',
            cmdbAssetStatsUrl: 'cmdb/asset/stats/',
            cmdbAssetSuricataUrl: 'cmdb/asset/suricata/',
            cmdbAssetIpUrl: 'cmdb/asset/ip/',
            cmdbAssetProtoL7Url: 'cmdb/asset/proto_l7/',
            cmdbAssetIpsUrl: 'cmdb/asset/ips/',
        },
        cmdbInterfaceAPIs: {
            cmdbInterfaceUrl: 'cmdb/interface/',
            cmdbInterfaceSetCriticityUrl: 'cmdb/interface/set_criticity/',
            cmdbInterfaceCriticalUrl: 'cmdb/interface/critical/',
            cmdbInterfaceCountUrl: 'cmdb/interface/count/',
            cmdbInterfacesFromNetworkUrl: 'cmdb/interface/from_network/',
            cmdbIsInterfaceFreeUrl: 'cmdb/interface/is_free/',
        },
        classifTreeScoreAPIs: {
            classifTreeScoreUrl: 'classif_tree_score/',
        },
        suricataAPIs: {
            suricataRuleUrl: 'suricata/',
        },
        threatAPIs: {
            threatUrl: 'object_threat/',
            threatsFromIPsUrl: 'object_threat/ips/',
            threatHighestAlertUrl: 'object_threat/highest_object_alert/',
            threatAlertsUrl: 'object_threat/object_alerts/',
            threatEventGroupsUrl: 'object_threat/event_groups/',
            statusUrl: 'object_threat/status/',
            filterFieldsUrl: 'object_threat/filter_fields/',
            timelineUrl: 'object_threat/timeline/',
        },
        attackAPIs: {
            attackUrl: 'object_attack/',
            attacksFromIPsUrl: 'object_attack/ips/',
            attackStatusUrl: 'object_attack/status/',
            attackClosedUrl: 'object_attack/closed/',
            attackThreatsUrl: 'object_attack/object_threats/',
            attackReasonUrl: 'object_attack/reason/',
            attacksStatusCounter: 'object_attack/status/counter/',
            attackCountFromThreasholdUrl: 'object_attack/count_from_threshold/',
            socWorkloadUrl: 'object_attack/soc_workload/',
            attackMttrUrl: 'object_attack/mttr/',
        },
        alertAPIs: {
            alertUrl: 'object_alert/',
            alertHighestEventUrl: 'object_alert/highest_object_event/',
            alertObjectThreatsUrl: 'object_alert/object_threats/',
        },
        eventAPIs: {
            eventUrl: 'object_event/',
            objectEventAnalyzerDescriptionUrl: 'object_event/object_event_analyzer_description/',
            getThreatFromEventUrl: 'object_event/object_threat/',
            filterFieldsUrl: 'object_event/filter_fields/',
            scoreByIpsUrl: 'object_event/ip/score',
        },
        mitre_attackAPIs: {
            mitreTacticsUrl: 'mitre_attack/tactics/',
            mitreTechniquesUrl: 'mitre_attack/techniques/',
            mitigationsUrl: 'mitre_attack/mitigations/',
            datasourcesUrl: 'mitre_attack/datasources/',
            groupsUrl: 'mitre_attack/groups/',
        },
        countryAPIs: {
            country_code: 'country_code/',
        },
        dictionaryAPIs: {
            fields_nameUrl: 'dictionary/fields_name/',
            ndpi_l7: 'dictionary/ndpi_l7/',
        },
        analyzerAPIs: {
            analyzerUrl: 'analyzer/',
        },
        flowAPIs: {
            flowUrl: 'flow/',
            flowInUrl: 'flow/in/',
            flowOutUrl: 'flow/out/',
        },
        statsAPIs: {
            topEventTypesUrl: 'stats/top_event_types/',
            topEventCategoriesUrl: 'stats/top_event_categories/',
            topThreatCategoriesUrl: 'stats/top_threat_categories/',
            nbAssetsUrl: 'stats/nb_assets/',
            hightestThreatUrl: 'stats/highest_threat/',
            hightestEventUrl: 'stats/highest_event/',
            nbEventsThreatsAttacksUrl: 'stats/nb_events_threats_attacks/',
            lvl7Url: 'stats/lvl7/',
            statsByAssetUrl: 'stats/stats_by_asset/',
            statsByIpUrl: 'stats/stats_by_ip/',
            flowEventCount: 'stats/flow_event_count/',
            flowEventCountTimeline: 'stats/flow_event_count/timeline/',
            ipsFromEventCategoriesUrl: 'stats/ips_from_event_categories/',
            ipsFromEachEventCategoriesUrl: 'stats/ips_from_each_event_categories/',
            privateIpsThreatenedUrl: 'stats/private_ips_threatened/',
            mitreThreatsProjectionUrl: 'stats/mitre_threats_projection/',
            attacksClosureReasonUrl: 'stats/attacks_closure_reason/',
            detectionPerimeterUrl: 'stats/detection_perimeter/',
        },
        timeSeriesAPIs: {
            timeSeriesNetworkFlowUrl: 'time_series/network_flow/',
            timeSeriesThreatsClassUrl: 'time_series/threats/class/',
            timeSeriesThreatsClassificationUrl: 'time_series/threats/classification/',
            timeSeriesAttacksUrl: 'time_series/attacks/',
            timeSeriesThreatsUrl: 'time_series/threats/',
            timeSeriesAlertsUrl: 'time_series/alerts/',
        },
        exportAPIs: {
            exportAssets: 'export/assets/',
            exportRouterXlsUrl: 'export/router_xls/',
            exportRouterCsvUrl: 'export/router_csv/',
            exportLocalizationCsvToS3Url: 'export/export_localisation_csv_to_s3/',
        },
        whitelist_ruleAPIs: {
            whitelist_ruleUrl: 'whitelist_rule/',
            is_whitelistedUrl: 'whitelist_rule/is_whitelisted/',
        },
        responseAPIs: {
            firewallBlockedIpUrl: 'firewall_blocked_ip/',
        },
    },
    notifsAPI: {
        baseUrl: '',
        notificationsAPIs: {
            notificationsUrl: 'notifications/',
            hooksUrl: 'notifications/hooks',
            streamUrl: 'notifications/stream',
        },
        rulesAPIs: {
            rulesUrl: 'rules/',
        },
        subscriptionsAPIs: {
            subscriptionsUrl: 'subscriptions/',
            updateEmailsUrl: 'subscriptions/update_emails',
        },
    },
};
