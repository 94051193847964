import { Directive, ElementRef, EventEmitter, Output, OnDestroy } from '@angular/core';

const entriesMap = new WeakMap();

const ro = new ResizeObserver(entries => {
    for (const entry of entries) {
        if (entriesMap.has(entry.target)) {
            const comp = entriesMap.get(entry.target);
            comp._resizeCallback(entry);
        }
    }
});

// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: '[resized]' })
export class ResizeObserverDirective implements OnDestroy {
    @Output()
    resized = new EventEmitter<ResizeObserverEntry>();

    constructor(private el: ElementRef) {
        const target = this.el.nativeElement;
        entriesMap.set(target, this);
        ro.observe(target);
    }

    _resizeCallback(entry: ResizeObserverEntry) {
        this.resized.emit(entry);
    }

    ngOnDestroy() {
        const target = this.el.nativeElement;
        ro.unobserve(target);
        entriesMap.delete(target);
    }
}
