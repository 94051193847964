import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AuthState } from '../state/auth.state';

export const selectAuthState = createFeatureSelector<AuthState>('auth');

export const selectAuthUser = createSelector(selectAuthState, state => state.user);
export const selectAuthTokens = createSelector(selectAuthState, state => state.tokens);

export const selectAuthIsLoggedIn = createSelector(selectAuthUser, user => !!user);
export const selectAuthPending = createSelector(selectAuthState, state => state.pending);
export const selectAuthError = createSelector(selectAuthState, state => state.error);

export const selectAuthFreshLogin = createSelector(selectAuthState, state => state.freshLogin);
