import { createFeatureSelector, createSelector, select } from '@ngrx/store';
import { THEMES, THEME_LUMINOSITY } from '../../utils/theme-list';
import { SettingsState } from '../state/settings.state';

export const selectSettingsState = createFeatureSelector<SettingsState>('settings');

export const selectCriticity = createSelector(selectSettingsState, (settings: SettingsState) => settings.criticity);

export const selectThemeParams = createSelector(selectSettingsState, (settings: SettingsState) => THEMES[settings.theme]);

export const selectTheme = createSelector(selectSettingsState, (settings: SettingsState) => settings.theme);

export const selectDynamicTheme = createSelector(selectSettingsState, (settings: SettingsState) => settings.dynamicTheme);

export const selectThemeLuminosity = createSelector(selectSettingsState, (settings: SettingsState) => settings.themeLuminosity);

export const selectNavbarExtended = createSelector(selectSettingsState, settings => settings.navbarExtended);

export const selectThemeLuminosityParams = createSelector(
    selectSettingsState,
    (settings: SettingsState) => THEME_LUMINOSITY[settings.themeLuminosity]
);

export const selectDefaultTimeSelection = createSelector(selectSettingsState, settings => settings.defaultTimeSelection);

export const selectDefaultEmail = createSelector(selectSettingsState, settings => settings.defaultEmail);
