import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, tap } from 'rxjs/operators';
import { EventsDataService } from 'src/app/events/services/events-data.service';
import { AuthActionTypes } from '../actions/auth.actions';
import { loadObjectEventAnalyzerDescriptionFailure, loadObjectEventAnalyzerDescriptionRequest, loadObjectEventAnalyzerDescriptionSuccess, ObjectEventAnalyzerDescriptionTypes } from '../actions/object-event-analyzer-description.actions';
import { ObjectEventAnalyzerDescriptionState } from '../state/object-event-analyzer-description.state';

@Injectable()
export class ObjectEventAnalyzerDescriptionEffects {
    constructor(private readonly actions$: Actions, private readonly store: Store<ObjectEventAnalyzerDescriptionState>, private readonly eventDataService: EventsDataService) { }

    init$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AuthActionTypes.USER_AUTO_LOGGED_IN, AuthActionTypes.LOGIN_SUCCESS),
                tap(() => {
                    this.store.dispatch(loadObjectEventAnalyzerDescriptionRequest());
                })
            ),
        { dispatch: false }
    );

    loadObjectEventAnalyzerDescriptionRequest$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ObjectEventAnalyzerDescriptionTypes.LOAD_OBJECTEVENTANALYZERDESCRIPTION_REQUEST),
            exhaustMap(() =>
                this.eventDataService.getObjectEventAnalyzerDescription().pipe(
                    map(objectEventAnalyzerDescription => loadObjectEventAnalyzerDescriptionSuccess({ objectEventAnalyzerDescription })),
                    catchError(error => of(loadObjectEventAnalyzerDescriptionFailure({ error })))
                )
            )
        )
    );
}
