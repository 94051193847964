import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ApiResponse } from '../models/api-response.interface';

@Injectable({
    providedIn: 'root',
})
export class MitreAttackService {
    constructor(private readonly http: HttpClient) { }

    getMitreTactics() {
        return this.http.get<ApiResponse<any[]>>(environment.bckAPI.mitre_attackAPIs.mitreTacticsUrl).pipe(map(r => r.result));
    }

    getMitreTechniques() {
        return this.http.get<ApiResponse<any[]>>(environment.bckAPI.mitre_attackAPIs.mitreTechniquesUrl).pipe(map(r => r.result));
    }

    getMitigations() {
        return this.http.get<ApiResponse<any[]>>(environment.bckAPI.mitre_attackAPIs.mitigationsUrl).pipe(map(r => r.result));
    }

    getDatasources() {
        return this.http.get<ApiResponse<any[]>>(environment.bckAPI.mitre_attackAPIs.datasourcesUrl).pipe(map(r => r.result));
    }

    getGroups() {
        return this.http.get<ApiResponse<any[]>>(environment.bckAPI.mitre_attackAPIs.groupsUrl).pipe(map(r => r.result));
    }
}
