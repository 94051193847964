import { HttpClient } from '@angular/common/http';
import { Injectable, TemplateRef } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { filter, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment.prod';
import { ApiResponse } from '../models/api-response.interface';
import { ViewSetting } from '../models/view-setting.interface';

@Injectable({
    providedIn: 'root',
})
export class UserViewSettingsService {
    templates: TemplateRef<any>[] = [];

    constructor(private router: Router, private readonly http: HttpClient) {
        this.router.events
            .pipe(
                filter(
                    (navEvent): navEvent is NavigationStart | NavigationEnd =>
                        navEvent instanceof NavigationStart || navEvent instanceof NavigationEnd
                )
            )
            .subscribe(navEvent => {
                this.templates = [];
            });
    }

    loadViewSettings(): Observable<ViewSetting[]> {
        return this.http.get<ApiResponse<{ settings: string }>>(environment.bckAPI.userAPIs.dataUrl).pipe(
            filter(r => !!r.result),
            map(res => JSON.parse(res?.result?.settings) as ViewSetting[])
        );
    }

    saveViewSettings(viewSettings: ViewSetting[]) {
        return this.http.post(environment.bckAPI.userAPIs.dataUrl, { settings: JSON.stringify(viewSettings) }).subscribe();
    }
}
