import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ApiResponse } from '../models/api-response.interface';
import { ObjectAttack } from '../models/object-attack.interface';
import { TreeNodeAttack } from '../models/tree-node-attacks.interface';
import { AttackReason } from '../models/attack-reason.interface';
import { ReportWidget } from '../report/models/report-widget.interface';
import { ObjectThreat } from '../models/object-threat.interface';
import { utc } from 'moment';

@Injectable({
    providedIn: 'root',
})
export class AttacksDataService {
    constructor(@Inject(LOCALE_ID) protected localeId: string, private readonly http: HttpClient) { }

    getAttacks(fromDate: string, toDate: string): Observable<ObjectAttack[]> {
        return this.http
            .post<ApiResponse<ObjectAttack[]>>(environment.bckAPI.attackAPIs.attackUrl, { from_date: fromDate, to_date: toDate })
            .pipe(map(r => r.result));
    }

    getAttacksClosed(fromDate: string, toDate: string): Observable<ObjectAttack[]> {
        const params = '?from_date=' + utc(fromDate).toISOString() + '&to_date=' + utc(toDate).toISOString();
        return this.http
            .get<ApiResponse<ObjectAttack[]>>(environment.bckAPI.attackAPIs.attackClosedUrl + params)
            .pipe(map(response => response.result));
    }

    getWidgetAttacksClosed(fromDate: string, toDate: string, widget: ReportWidget) {
        const params = '?from_date=' + utc(fromDate).toISOString() + '&to_date=' + utc(toDate).toISOString();
        return this.http
            .get<ApiResponse<any>>(environment.bckAPI.attackAPIs.attackClosedUrl + params)
            .pipe(map(response => ({ ...widget, data: response.result })));
    }

    getAttacksStatusCounter(fromDate: string, toDate: string) {
        const params = '?from_date=' + utc(fromDate).toISOString() + '&to_date=' + utc(toDate).toISOString();
        return this.http
            .get<ApiResponse<any>>(environment.bckAPI.attackAPIs.attacksStatusCounter + params)
            .pipe(map(response => response.result));
    }

    getWidgetAttacksStatusCounter(fromDate: string, toDate: string, widget: ReportWidget) {
        const params = '?from_date=' + utc(fromDate).toISOString() + '&to_date=' + utc(toDate).toISOString();
        return this.http
            .get<ApiResponse<any>>(environment.bckAPI.attackAPIs.attacksStatusCounter + params)
            .pipe(map(response => ({ ...widget, data: response.result })));
    }

    attackCountFromThreashold(
        fromDate: string,
        toDate: string,
        low: number,
        medium: number,
        high: number,
        critical: number
    ): Observable<any> {
        const data = {
            from_date: utc(fromDate).toISOString(),
            to_date: utc(toDate).toISOString(),
            low,
            medium,
            high,
            critical
        };

        return this.http
            .post<ApiResponse<any>>(environment.bckAPI.attackAPIs.attackCountFromThreasholdUrl, data)
            .pipe(map(r => r.result));
    }

    attackCountFromThreasholdWidget(
        widget: ReportWidget,
        fromDate: string,
        toDate: string,
        low: number,
        medium: number,
        high: number,
        critical: number
    ): Observable<any> {
        const data = {
            from_date: utc(fromDate).toISOString(),
            to_date: utc(toDate).toISOString(),
            low,
            medium,
            high,
            critical,
        };

        return this.http
            .post<ApiResponse<any>>(environment.bckAPI.attackAPIs.attackCountFromThreasholdUrl, data)
            .pipe(map(r => ({ ...widget, data: r.result })));
    }

    getAttack(attackId: string): Observable<any> {
        return this.http.get<ApiResponse<any>>(environment.bckAPI.attackAPIs.attackUrl + attackId).pipe(map(r => r.result));
    }

    getAttackThreats(attackId: string, fromDate: string): Observable<ObjectThreat[]> {
        const params = new HttpParams().set('from_date', fromDate);

        return this.http
            .get<ApiResponse<any>>(environment.bckAPI.attackAPIs.attackThreatsUrl + attackId, { params })
            .pipe(map(r => r.result));
    }

    changeAttackStatus(attackId: string, newStatus: number, reason: number, remarks: any) {
        const data = {
            new_status: newStatus,
            reason_id: reason,
            reason_details: remarks,
        };
        return this.http.patch(environment.bckAPI.attackAPIs.attackStatusUrl + attackId, data);
    }

    getAttackReasonList() {
        return this.http
            .get<ApiResponse<AttackReason[]>>(environment.bckAPI.attackAPIs.attackReasonUrl + '?lang=' + this.localeId)
            .pipe(map(response => response.result));
    }

    getSOCWorkload(fromDate: string, toDate: string) {
        const params = '?from_date=' + utc(fromDate).toISOString() + '&to_date=' + utc(toDate).toISOString();
        return this.http
            .get<ApiResponse<any>>(environment.bckAPI.attackAPIs.socWorkloadUrl + params)
            .pipe(map(response => response.result));
    }

    getAttacksMttr(fromDate: string, toDate: string) {
        const params = '?from_date=' + utc(fromDate).toISOString() + '&to_date=' + utc(toDate).toISOString();
        return this.http
            .get<ApiResponse<any>>(environment.bckAPI.attackAPIs.attackMttrUrl + params)
            .pipe(map(response => response.result));
    }

    getAttacksFromIPs(fromDate: string, toDate: string, ips: string[]): Observable<ObjectAttack[]> {
        return this.http
            .post<ApiResponse<ObjectAttack[]>>(environment.bckAPI.attackAPIs.attacksFromIPsUrl, { from_date: fromDate, to_date: toDate, ip_list: ips })
            .pipe(map(r => r.result));
    }
}
