import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { ObjectEventAnalyzerDescription } from 'src/app/models/object-event.interface';

export const objectEventAnalyzerDescriptionAdapter = createEntityAdapter<ObjectEventAnalyzerDescription>({
    selectId: item => item.id,
});

export interface ObjectEventAnalyzerDescriptionState extends EntityState<ObjectEventAnalyzerDescription> {
    isLoading: boolean;
    error: any;
}

export const initialObjectEventAnalyzerDescriptionState: ObjectEventAnalyzerDescriptionState = objectEventAnalyzerDescriptionAdapter.getInitialState({
    isLoading: false,
    error: null,
});
