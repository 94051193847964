import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { Dictionary } from '@ngrx/entity';
import { Store } from '@ngrx/store';
import { Observable, lastValueFrom } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { ApiResponse } from 'src/app/models/api-response.interface';
import { Asset } from 'src/app/models/asset.interface';
import { selectAssetsTypeAll, selectAssetsTypeEntities } from 'src/app/shared/store/selectors/assets.selectors';
import { environment } from 'src/environments/environment';
import { AssetType } from '../models/asset-type.interface';
import { AvailableFilter } from '../models/available-filter.interface';
import { AssetsIps, CmdbAsset, CmdbInterface, CmdbSite, NetworkNode, SrcDstCounter, SuricataSrcDst } from '../models/cmdb.interface';
import { CountResponse } from '../models/count-response.interface';
import { setAvailableFilters } from '../shared/store/actions/filters.actions';
import { AssetsStoreState } from '../shared/store/state/assets.state';
import { UtilsService } from '../shared/utils/utils.service';

@Injectable({
    providedIn: 'root',
})
export class CmdbService {
    assetsType: Dictionary<AssetType>;

    constructor(
        @Inject(LOCALE_ID) protected localeId: string,
        private readonly http: HttpClient,
        private readonly store: Store<AssetsStoreState>,
        private readonly utils: UtilsService
    ) {
        this.store.select(selectAssetsTypeEntities).subscribe(v => (this.assetsType = v));
    }

    getAssetStats(fromDate: string, toDate: string, id: string): Observable<Asset> {
        let params = '?';
        if (fromDate) {
            params += 'from_date=' + fromDate.replace('Z', '');
        }
        if (toDate) {
            params += '&to_date=' + toDate.replace('Z', '');
        }

        return this.http
            .get<ApiResponse<Asset>>(environment.bckAPI.cmdbAssetAPIs.cmdbAssetUrl + id + '/stats/' + params)
            .pipe(map(response => response.result));
    }

    getCmdbAssetStats(id: string, fromDate: string, toDate: string): Observable<any> {
        let params = '?';
        if (fromDate) {
            params += 'from_date=' + fromDate.replace('Z', '');
        }
        if (toDate) {
            params += '&to_date=' + toDate.replace('Z', '');
        }

        return this.http
            .get<ApiResponse<any>>(environment.bckAPI.cmdbAssetAPIs.cmdbAssetStatsUrl + id + params)
            .pipe(map(response => response.result));
    }

    getAssetEvents(fromDate: string, toDate: string, id: string): Observable<any> {
        let params = '?';
        if (fromDate) {
            params += 'from_date=' + fromDate.replace('Z', '');
        }
        if (toDate) {
            params += '&to_date=' + toDate.replace('Z', '');
        }

        return this.http
            .get<ApiResponse<any>>(environment.bckAPI.cmdbAssetAPIs.cmdbAssetUrl + id + '/events/' + params)
            .pipe(map(response => response.result));
    }

    getCmdbAssetFromId(id: string): Observable<CmdbAsset> {
        return this.http.get<ApiResponse<CmdbAsset>>(environment.bckAPI.cmdbAssetAPIs.cmdbAssetUrl + id).pipe(map(response => response.result));
    }

    getCmdbInfoFromIP(ip: string): Observable<Asset> {
        return this.http.get<ApiResponse<Asset>>(environment.bckAPI.cmdbAssetAPIs.cmdbAssetIpUrl + ip).pipe(map(response => response.result));
    }

    getCmdbProtoL7FromAsset(id: string, fromDate: string, toDate: string): Observable<SrcDstCounter> {
        let params = '?from_date=' + fromDate.replace('Z', '') + '&to_date=' + toDate.replace('Z', '');
        return this.http.get<ApiResponse<SrcDstCounter>>(environment.bckAPI.cmdbAssetAPIs.cmdbAssetProtoL7Url + id + params).pipe(map(response => response.result));
    }

    getCmdbAnalyzerStatsFromAsset(id: string, analyzerId: number, fromDate: string, toDate: string): Observable<SrcDstCounter> {
        let params = '?from_date=' + fromDate.replace('Z', '') + '&to_date=' + toDate.replace('Z', '');
        return this.http.get<ApiResponse<SrcDstCounter>>(environment.bckAPI.cmdbAssetAPIs.cmdbAssetUrl + id + '/' + analyzerId + params).pipe(map(response => response.result));
    }

    getCmdbSuricataStatsFromAsset(id: string, fromDate: string, toDate: string): Observable<SuricataSrcDst> {
        let params = '?from_date=' + fromDate.replace('Z', '') + '&to_date=' + toDate.replace('Z', '');
        return this.http.get<ApiResponse<SuricataSrcDst>>(environment.bckAPI.cmdbAssetAPIs.cmdbAssetSuricataUrl + id + params).pipe(map(response => response.result));
    }

    getGraphAssets(): Observable<NetworkNode[]> {
        return this.http
            .get<ApiResponse<NetworkNode[]>>(environment.bckAPI.cmdbAPIs.cmdbUrl)
            .pipe(map(response => response.result));
    }

    getGraphAsset(assetId: string): Observable<NetworkNode[]> {
        return this.http
            .get<ApiResponse<NetworkNode[]>>(environment.bckAPI.cmdbAPIs.cmdbSubgraphUrl + assetId)
            .pipe(map(response => response.result));
    }

    patchAsset(asseId, assetName, assetSiteId) {
        const formData = new FormData();
        if (!!assetName) {
            formData.append('name', assetName);
        }
        if (!!assetSiteId) {
            formData.append('id_site', assetSiteId);
        }

        return this.http
            .patch<ApiResponse<any>>(environment.bckAPI.cmdbAssetAPIs.cmdbAssetUrl + asseId, formData)
            .pipe(map(response => response.result));
    }

    postInterface(newInterface: NetworkNode): Observable<any> {
        const body = {
            id_asset: newInterface.id,
            type_asset: newInterface.type,
            name: newInterface.name,
            ip_address: newInterface.ip_address,
            mac_address: newInterface.mac || null, // Set to null if empty
            network: newInterface.network,
            ip_gateway: newInterface.ip_gateway,
            ip_mask: newInterface.ip_mask
        };

        return this.http.post<ApiResponse<any>>(environment.bckAPI.cmdbInterfaceAPIs.cmdbInterfaceUrl, body).pipe(
            map(response => response.result)
        );
    }

    patchInterface(interfaceToUpdate: NetworkNode): Observable<any> {
        const formData = new FormData();
        if (!!interfaceToUpdate) {
            if (!!interfaceToUpdate.name) formData.append('name', interfaceToUpdate.name);
            if (!!interfaceToUpdate.ip_address) formData.append('ip_address', interfaceToUpdate.ip_address);
            if (!!interfaceToUpdate.mac) formData.append('mac', interfaceToUpdate.mac);
            if (!!interfaceToUpdate.network) formData.append('network', interfaceToUpdate.network);
            if (!!interfaceToUpdate.ip_gateway) formData.append('ip_gateway', interfaceToUpdate.ip_gateway);
            if (!!interfaceToUpdate.ip_mask) formData.append('ip_mask', interfaceToUpdate.ip_mask);
        }

        return this.http
            .patch<ApiResponse<any>>(environment.bckAPI.cmdbInterfaceAPIs.cmdbInterfaceUrl + interfaceToUpdate.id, formData)
            .pipe(map(response => response.result));
    }

    deleteInterface(interfaceId: any): Observable<any> {
        return this.http
            .delete<ApiResponse<any>>(environment.bckAPI.cmdbInterfaceAPIs.cmdbInterfaceUrl + interfaceId)
            .pipe(map(response => response.result));
    }

    getCmdbInterfaceCount() {
        return this.http.get<ApiResponse<CountResponse>>(environment.bckAPI.cmdbInterfaceAPIs.cmdbInterfaceCountUrl).pipe(map(r => r.result?.count));
    }

    getCmdbInterfacesFromNetwork(network: string) {
        let params = '?';
        if (!!network) {
            params += 'network=' + network;
        }
        return this.http.get<ApiResponse<any>>(environment.bckAPI.cmdbInterfaceAPIs.cmdbInterfacesFromNetworkUrl + params).pipe(map(r => r.result));
    }

    getIsFreeInterface(ip: string) {
        return this.http.get<ApiResponse<any>>(environment.bckAPI.cmdbInterfaceAPIs.cmdbIsInterfaceFreeUrl + ip).pipe(map(r => r.result));
    }

    getCmdbTypes(): Observable<AssetType[]> {
        return this.http
            .get<ApiResponse<AssetType[]>>(environment.bckAPI.cmdbAPIs.cmdbTypeUrl + '?lang=' + this.localeId)
            .pipe(map(response => response.result));
    }

    getCmdbSites(): Observable<CmdbSite[]> {
        return this.http
            .get<ApiResponse<CmdbSite[]>>(environment.bckAPI.cmdbAPIs.cmdbSiteUrl)
            .pipe(map(response => response.result));
    }

    getCmdbColumns() {
        return this.http.get<ApiResponse<any>>(environment.bckAPI.cmdbAPIs.filterFieldsUrl).pipe(map(r => r.result));
    }

    getDictAssetsIps(): Observable<AssetsIps[]> {
        return this.http.get<ApiResponse<AssetsIps[]>>(environment.bckAPI.cmdbAssetAPIs.cmdbAssetIpsUrl).pipe(map(r => r.result));
    }

    setInterfaceCriticity(interface_id: string, is_critical: boolean) {
        let params = '?';
        params += 'is_critical=' + is_critical;
        return this.http.get<ApiResponse<any>>(environment.bckAPI.cmdbInterfaceAPIs.cmdbInterfaceSetCriticityUrl + interface_id + params).pipe(map(r => r.result));
    }

    getCriticalInterfaces(): Observable<CmdbInterface[]> {
        return this.http.get<ApiResponse<CmdbInterface[]>>(environment.bckAPI.cmdbInterfaceAPIs.cmdbInterfaceCriticalUrl).pipe(map(r => r.result));
    }

    async getAssetsFilterFieldsForQueryBuilder() {
        // // Récupération des analyzers
        const assetType = await lastValueFrom(this.store.select(selectAssetsTypeAll).pipe(take(1)));
        if (!assetType) return;

        this.getCmdbColumns().subscribe(c => {
            const availableFilters: AvailableFilter[] = this.utils.getAssetsFilterFields(c, assetType);
            availableFilters.sort((a, b) => (a.label > b.label ? 1 : -1));
            this.store.dispatch(setAvailableFilters({ availableFilters }));
        });
    }
}
