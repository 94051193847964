import { createAction, props } from '@ngrx/store';
import { Status } from '../../../models/status.interface';

export enum StatusActionTypes {
    LOAD_STATUS_REQUEST = '[Status Api] Load Request',
    LOAD_STATUS_SUCCESS = '[Status Api] Load Success',
    LOAD_STATUS_FAILURE = '[Status Api] Load Failure',
}

export const loadStatusRequest = createAction(StatusActionTypes.LOAD_STATUS_REQUEST);
export const loadStatusSuccess = createAction(StatusActionTypes.LOAD_STATUS_SUCCESS, props<{ status: Status[] }>());
export const loadStatusFailure = createAction(StatusActionTypes.LOAD_STATUS_FAILURE, props<{ error: any }>());
