import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import * as echarts from 'echarts/core';
import { GridComponent } from 'echarts/components';
import { ScatterChart } from 'echarts/charts';
import { UniversalTransition } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';
import { HypervisionAbstractBase } from 'src/app/abstract/hypervision-abstract-base';

@Component({
    selector: 'hypervision-scatter-chart',
    templateUrl: './scatter-chart.component.html',
    styleUrls: ['./scatter-chart.component.scss']
})
export class ScatterChartComponent extends HypervisionAbstractBase implements OnInit, OnDestroy {
    protected override onDataRefresh(): void {
        throw new Error('Method not implemented.');
    }
    @ViewChild('scatterChart') chartContainer: ElementRef<HTMLDivElement>;
    chart: echarts.ECharts;

    isLoading: boolean = false;

    constructor() { super(); }

    async ngOnInit() {
        await super.onInit();
        echarts.use([GridComponent, ScatterChart, CanvasRenderer, UniversalTransition]);
        this.loadChart();
    }

    ngOnDestroy(): void {
        super.onDestroy();
    }

    //   ngAfterViewInit(): void {
    //     // On met un tiemout sinon la clientWith du container vaut 0
    //     setTimeout(() => {
    //         this.chart = echarts.init(this.chartContainer.nativeElement);
    //         this.loadData();
    //     }, 1000);
    // }


    onChartContainerResize() {
        if (this.chart) this.chart.resize();
    }

    private loadChart() {
        if (!!this.chartContainer?.nativeElement) {
            if (!this.chart) {
                this.chart = echarts.init(this.chartContainer.nativeElement);
            }
        }

        if (!!this.chart) {
            const option = {
                grid: { top: 20, left: 20, right: 20, bottom: 20 },
                xAxis: {},
                yAxis: {},
                series: [
                    {
                        symbolSize: 20,
                        data: [
                            [10.0, 8.04],
                            [8.07, 6.95],
                            [13.0, 7.58],
                            [9.05, 8.81],
                            [11.0, 8.33],
                            [14.0, 7.66],
                            [13.4, 6.81],
                            [10.0, 6.33],
                            [14.0, 8.96],
                            [12.5, 6.82],
                            [9.15, 7.2],
                            [11.5, 7.2],
                            [3.03, 4.23],
                            [12.2, 7.83],
                            [2.02, 4.47],
                            [1.05, 3.33],
                            [4.05, 4.96],
                            [6.03, 7.24],
                            [12.0, 6.26],
                            [12.0, 8.84],
                            [7.08, 5.82],
                            [5.02, 5.68]
                        ],
                        type: 'scatter'
                    }
                ]
            };

            this.chart.setOption(option);
        }
    }

}
