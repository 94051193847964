import { Component, Input, OnInit } from '@angular/core';
import { Dictionary } from '@ngrx/entity';
import { ClassificationTreeNode } from 'src/app/models/score-class-node.interface';

@Component({
    selector: 'hypervision-classification-display',
    templateUrl: './classification-display.component.html',
    styleUrls: ['./classification-display.component.scss']
})
export class ClassificationDisplayComponent {
    @Input() classif_tree_score_id: number;
    @Input() classificationTree: Dictionary<ClassificationTreeNode>;
}
