import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Analyzer } from '../models/analyzer.interface';
import { ApiResponse } from '../models/api-response.interface';

@Injectable({
    providedIn: 'root',
})
export class AnalyzerService {
    constructor(@Inject(LOCALE_ID) protected localeId: string, private readonly http: HttpClient) {}

    getAnalyzerList() {
        return this.http
            .get<ApiResponse<Analyzer[]>>(environment.bckAPI.analyzerAPIs.analyzerUrl + '?lang=' + this.localeId)
            .pipe(map(response => response.result));
    }
}
