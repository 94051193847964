import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { Analyzer } from 'src/app/models/analyzer.interface';

export const analyzerAdapter = createEntityAdapter<Analyzer>({
    selectId: item => item.id,
});

export interface AnalyzerState extends EntityState<Analyzer> {
    isLoading: boolean;
    error: any;
}

export const initialAnalyzerState: AnalyzerState = analyzerAdapter.getInitialState({
    isLoading: false,
    error: null,
});
