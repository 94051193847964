import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { registerLicense } from '@syncfusion/ej2-base';
import { config } from 'rxjs';
import { AppModule } from './app/app.module';
import { ErrorHandlerService } from './app/services/error-handler.service';
import { environment } from './environments/environment';

registerLicense('ORg4AjUWIQA/Gnt2VFhiQlJPd11dXmJWd1p/THNYflR1fV9DaUwxOX1dQl9gSXtRdURjWH1acXBdRGg=');

if (environment.production) {
    enableProdMode();
}

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .then(moduleRef => {
        const errorHandlerService = moduleRef.injector.get(ErrorHandlerService);
        config.onUnhandledError = (err: any) => errorHandlerService.handleGlobalError(err);
    })
    .catch(err => console.log(err));
